import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { supabase } from "../supabaseClient";

const buttonClasses =
  "text-white px-4 py-2 rounded-xl text-sm font-semibold transition duration-300 ease-in-out whitespace-nowrap border-2 backdrop-blur-sm transform hover:scale-105";

const hollowButtonClasses =
  "px-4 py-2 rounded-xl text-sm font-semibold transition duration-300 ease-in-out whitespace-nowrap border-2 backdrop-blur-sm transform hover:scale-105 bg-transparent";

const mobileButtonClasses =
  "text-white px-4 py-2 rounded-xl text-sm font-semibold transition duration-300 ease-in-out whitespace-nowrap w-full text-center block my-3 backdrop-blur-sm border-2 shadow-[0_0_15px_rgba(124,58,237,0.35)] bg-gradient-to-br hover:scale-105";

const mobileHollowButtonClasses =
  "px-4 py-2 rounded-xl text-sm font-semibold transition duration-300 ease-in-out whitespace-nowrap w-full text-center block my-3 backdrop-blur-sm border-2 hover:scale-105 bg-transparent";

const Header = ({ onChangePasswordClick }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [session, setSession] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isHomePage = location.pathname === "/";
  const isCurriculumPage = location.pathname === "/curriculum";
  const menuRef = useRef(null);
  const hamburgerRef = useRef(null);

  // Close menu when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        isMenuOpen &&
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        hamburgerRef.current &&
        !hamburgerRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  // Disable body scroll when menu is open
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isMenuOpen]);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  // Close menu when route changes
  useEffect(() => {
    setIsMenuOpen(false);
  }, [location.pathname]);

  const handleSignOut = async () => {
    try {
      await supabase.auth.signOut();
      navigate("/");
      setIsMenuOpen(false);
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleExtensionDownload = () => {
    window.open(
      "https://chromewebstore.google.com/detail/hereticschool-chrome-exte/odlfeaecpimnkgmeigjokbhemhbppage",
      "_blank"
    );
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navigationItems = [
    {
      label: "Dashboard",
      to: "/home",
      className: `${hollowButtonClasses} ${
        location.pathname === "/home"
          ? "border-purple-500 text-white shadow-[0_0_20px_rgba(168,85,247,0.8)] hover:shadow-[0_0_25px_rgba(168,85,247,0.9)] animate-pulse-slow bg-purple-500/10 hover:bg-purple-500/20"
          : "border-purple-500 text-white shadow-[0_0_15px_rgba(168,85,247,0.6)] hover:shadow-[0_0_22px_rgba(168,85,247,0.8)] hover:bg-purple-500/10"
      } text-shadow-purple`,
      mobileClassName: `${mobileHollowButtonClasses} ${
        location.pathname === "/home"
          ? "border-purple-500 text-white shadow-[0_0_20px_rgba(168,85,247,0.8)] bg-purple-500/10"
          : "border-purple-500 text-white shadow-[0_0_15px_rgba(168,85,247,0.6)]"
      } text-shadow-purple`,
      condition: true,
    },
    {
      label: "Blog",
      to: "/blog",
      className: `${buttonClasses} border-green-500 text-green-300 shadow-[0_0_15px_rgba(16,185,129,0.35)] from-green-900/20 to-green-700/20 hover:from-green-900/30 hover:to-green-700/30 hover:bg-green-900/40`,
      mobileClassName: `${mobileButtonClasses} border-green-500 text-green-300 shadow-[0_0_15px_rgba(16,185,129,0.35)] from-green-900/20 to-green-700/20`,
      condition: true,
    },
    {
      label: "Download Extension",
      onClick: handleExtensionDownload,
      className: `${buttonClasses} border-blue-500 text-blue-300 shadow-[0_0_15px_rgba(59,130,246,0.35)] from-blue-900/20 to-blue-700/20 hover:from-blue-900/30 hover:to-blue-700/30 hover:bg-blue-900/40`,
      mobileClassName: `${mobileButtonClasses} border-blue-500 text-blue-300 shadow-[0_0_15px_rgba(59,130,246,0.35)] from-blue-900/20 to-blue-700/20`,
      condition: true,
    },
  ];

  const authItems = session
    ? [
        {
          label: "Sign Out",
          onClick: handleSignOut,
          className: `${buttonClasses} border-indigo-500 text-indigo-300 shadow-[0_0_15px_rgba(99,102,241,0.35)] from-indigo-900/20 to-indigo-700/20 hover:from-indigo-900/30 hover:to-indigo-700/30 hover:bg-indigo-900/40`,
          mobileClassName: `${mobileButtonClasses} border-indigo-500 text-indigo-300 shadow-[0_0_15px_rgba(99,102,241,0.35)] from-indigo-900/20 to-indigo-700/20`,
        },
        {
          label: "Pricing",
          to: "#pricing",
          className: `${buttonClasses} border-yellow-500 text-yellow-300 shadow-[0_0_15px_rgba(250,204,21,0.35)] from-yellow-900/20 to-yellow-700/20 hover:from-yellow-900/30 hover:to-yellow-700/30 hover:bg-yellow-900/40`,
          mobileClassName: `${mobileButtonClasses} border-yellow-500 text-yellow-300 shadow-[0_0_15px_rgba(250,204,21,0.35)] from-yellow-900/20 to-yellow-700/20`,
        },
      ]
    : [
        {
          label: "Sign In",
          to: "/login",
          className: `${buttonClasses} border-slate-500 text-slate-300 shadow-[0_0_15px_rgba(71,85,105,0.35)] from-slate-900/20 to-slate-700/20 hover:from-slate-900/30 hover:to-slate-700/30 hover:bg-slate-900/40`,
          mobileClassName: `${mobileButtonClasses} border-slate-500 text-slate-300 shadow-[0_0_15px_rgba(71,85,105,0.35)] from-slate-900/20 to-slate-700/20`,
        },
        {
          label: "Sign Up",
          to: "/login",
          className: `${buttonClasses} border-indigo-500 text-indigo-300 shadow-[0_0_15px_rgba(99,102,241,0.35)] from-indigo-900/20 to-indigo-700/20 hover:from-indigo-900/30 hover:to-indigo-700/30 hover:bg-indigo-900/40`,
          mobileClassName: `${mobileButtonClasses} border-indigo-500 text-indigo-300 shadow-[0_0_15px_rgba(99,102,241,0.35)] from-indigo-900/20 to-indigo-700/20`,
        },
        {
          label: "Pricing",
          to: "#pricing",
          className: `${buttonClasses} border-yellow-500 text-yellow-300 shadow-[0_0_15px_rgba(250,204,21,0.35)] from-yellow-900/20 to-yellow-700/20 hover:from-yellow-900/30 hover:to-yellow-700/30 hover:bg-yellow-900/40`,
          mobileClassName: `${mobileButtonClasses} border-yellow-500 text-yellow-300 shadow-[0_0_15px_rgba(250,204,21,0.35)] from-yellow-900/20 to-yellow-700/20`,
        },
      ];
  const renderNavigationItem = (item, isMobile = false) => {
    if (!item.condition && item.condition !== undefined) return null;

    const className = isMobile ? item.mobileClassName : item.className;

    if (item.isExternal) {
      return (
        <a
          key={item.label}
          href={item.href}
          target="_blank"
          rel="noopener noreferrer"
          className={className}
          onClick={() => isMobile && setIsMenuOpen(false)}
        >
          {item.label}
        </a>
      );
    }

    if (item.to) {
      return (
        <Link
          key={item.label}
          to={item.to}
          className={className}
          onClick={() => isMobile && setIsMenuOpen(false)}
        >
          {item.label}
        </Link>
      );
    }

    return (
      <button
        key={item.label}
        onClick={(e) => {
          e.stopPropagation();
          if (item.onClick) item.onClick();
          if (isMobile) setIsMenuOpen(false);
        }}
        className={className}
      >
        {item.label}
      </button>
    );
  };

  return (
    <header className="bg-gray-900/90 backdrop-blur-lg shadow-lg sticky top-0 z-50">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          <Link to="/" className="flex items-center space-x-2">
            <img
              src="/heretic_school_logo.png"
              alt="Heretic.School"
              className="h-8 w-auto"
            />
            <span className="text-xl font-bold text-white">Heretic.School</span>
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden lg:flex items-center space-x-3">
            {navigationItems
              .filter((item) =>
                item.condition !== undefined ? item.condition : true
              )
              .map((item) => renderNavigationItem(item))}
            {authItems.map((item) => renderNavigationItem(item))}
          </div>

          {/* Hamburger Menu Button */}
          <button
            ref={hamburgerRef}
            className="lg:hidden flex items-center justify-center w-10 h-10 text-white bg-gray-900/60 border-2 border-purple-500/70 rounded-lg focus:outline-none backdrop-blur-sm shadow-[0_0_10px_rgba(168,85,247,0.25)]"
            onClick={toggleMenu}
            aria-label="Toggle menu"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isMenuOpen ? (
                <path d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path d="M4 6h16M4 12h16M4 18h16" />
              )}
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Navigation Menu - Overlay */}
      {isMenuOpen && (
        <div
          className="lg:hidden fixed inset-0 bg-black/50 backdrop-blur-[2px] z-40"
          onClick={() => setIsMenuOpen(false)}
        />
      )}

      {/* Mobile Navigation Menu - Sidebar */}
      <div
        ref={menuRef}
        className={`lg:hidden fixed top-16 right-0 bottom-0 w-64 bg-gray-900/80 backdrop-blur-lg border-l border-purple-500/30 shadow-[0_0_15px_rgba(168,85,247,0.2)] z-50 transform transition-transform duration-300 ease-in-out ${
          isMenuOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="p-4 h-full bg-gradient-to-b from-gray-900/60 to-gray-800/60">
          {navigationItems
            .filter((item) =>
              item.condition !== undefined ? item.condition : true
            )
            .map((item, index) => (
              <div key={index} className="py-2">
                {renderNavigationItem(item, true)}
              </div>
            ))}

          <div className="h-px w-full bg-purple-500/20 my-3"></div>

          {authItems.map((item, index) => (
            <div key={index} className="py-2">
              {renderNavigationItem(item, true)}
            </div>
          ))}

          <div className="pt-4 mt-4 border-t border-purple-500/20">
            <p className="text-gray-400 text-sm">© 2024 Heretic.School</p>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
