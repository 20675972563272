import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "./Header";
import QuickProjectGenerator from "./QuickProjectGenerator";
import { Link, useNavigate } from "react-router-dom";
import posthog from "posthog-js";

// Add back the Gradient component
const Gradient = ({ children }) => (
  <span className="text-transparent bg-gradient-to-br from-purple-500 to-indigo-500 bg-clip-text">
    {children}
  </span>
);

const LandingPage = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    posthog.capture("curriculum_landing_page_viewed");
    
    // Scroll to top on page load
    window.scrollTo(0, 0);

    // Check if user is already logged in and has completed setup
    // If so, redirect to dashboard
    const isSetupComplete = localStorage.getItem("setupComplete") === "true";
    const storedUserId = localStorage.getItem("userId");
    
    if (storedUserId) {
      setUserId(storedUserId);
      setIsLoggedIn(true);
      
      if (isSetupComplete) {
        navigate("/home");
      }
    }
  }, [navigate]);

  // Structured data for SEO
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": "Homeschool Project Generator",
    "description": "Generate personalized homeschool learning projects for teens based on their interests and age. Free educational resource for homeschooling families.",
    "applicationCategory": "EducationalApplication",
    "operatingSystem": "Any",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    }
  };

  return (
    <div className="min-h-screen bg-gray-950 text-white">
      <Helmet>
        <title>Homeschool Project Generator | Create Personalized Learning Projects</title>
        <meta name="description" content="Free homeschool project generator that creates personalized learning content and projects tailored to your teen's interests, age, and learning style." />
        <meta name="keywords" content="homeschool project generator, homeschool projects, personalized learning, project-based learning, homeschool resources, free homeschool tools" />
        <link rel="canonical" href="https://heretic.school" />
        <meta property="og:title" content="Homeschool Project Generator | Heretic.School" />
        <meta property="og:description" content="Generate custom learning projects for homeschooled teens based on their interests and learning goals." />
        <meta property="og:url" content="https://heretic.school" />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      
      <Header user={{ id: userId, isLoggedIn }} />
      <main>
        <Hero />
        <PersonalizedApproach />
        <TailoredLearning />
        <HowItWorks />
        <Benefits />
        <AboutUsShort />
      </main>
    </div>
  );
};

const Hero = () => {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  
  useEffect(() => {
    const userId = localStorage.getItem("userId");
    setUserLoggedIn(!!userId);
  }, []);

  return (
    <section className="relative min-h-[65vh] max-h-screen flex items-center overflow-hidden">
      {/* Background image with overlay */}
      <div
        className="absolute inset-0 bg-cover bg-center z-0"
        style={{
          backgroundImage: "url('/augmented.png')",
          backgroundPosition: "center 30%",
        }}
      />
      <div className="absolute inset-0 bg-black/80 backdrop-blur-sm z-0" />

      <div className="container mx-auto px-4 relative z-10 py-5 overflow-visible mb-20">
        <div className="max-w-5xl mx-auto">
          {/* Title section */}
          <div className="flex flex-col items-center justify-center mb-6">
            <h1 className="text-4xl md:text-6xl font-bold mb-3 text-center">
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 via-pink-500 to-indigo-500">
                Boredom-proof homeschooling for exceptional teens.
              </span>
          </h1>
            <p className="text-lg md:text-xl text-gray-300 max-w-3xl mx-auto mb-6 text-center">
              Try our project generator to create personalized projects that give your teen an opportunity to practice doing what they love.
            </p>
            
            {/* Add dashboard button for logged in users */}
            {userLoggedIn && (
              <button
                onClick={() => window.location.href = "/home"}
                className="px-6 py-3 mb-6 bg-gradient-to-r from-green-600 to-teal-600 hover:from-green-700 hover:to-teal-700 text-white font-bold rounded-lg transition-all duration-300 hover:scale-[1.02] hover:shadow-lg"
              >
                <span className="inline-flex items-center">
                  <span className="mr-2">📊</span> Go to Dashboard
                </span>
              </button>
            )}
          </div>

          {/* Project generator form */}
          <div className="max-w-md mx-auto">
            <div className="bg-gray-950/60 backdrop-blur-md border border-indigo-500/20 rounded-xl shadow-xl overflow-hidden transform hover:scale-[1.01] transition-all duration-300 hover:shadow-indigo-500/10 hover:shadow-2xl">
              <div className="p-5">
                <QuickProjectFormEnhanced />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Full-width scrolling project tiles at bottom of hero - single row */}
      <div className="absolute left-0 right-0 bottom-0 overflow-hidden">
        <div className="relative w-full py-2 bg-black/40 backdrop-blur-sm">
          <div className="absolute left-0 right-0 top-0 h-6 bg-gradient-to-b from-black/80 to-transparent z-10"></div>
          <div className="overflow-hidden">
            <ul className="flex animate-scroll py-2">
              <ProjectTile emoji="💻" title="Coding Project" />
              <ProjectTile emoji="🔬" title="Science Experiment" />
              <ProjectTile emoji="📖" title="Research Paper" />
              <ProjectTile emoji="🎨" title="Art Installation" />
              <ProjectTile emoji="🧪" title="Chemistry Lab" />
              <ProjectTile emoji="🌱" title="Environmental Study" />
              <ProjectTile emoji="🎭" title="Drama Performance" />
              <ProjectTile emoji="🧮" title="Math Investigation" />
              <ProjectTile emoji="🏛️" title="History Documentary" />
              <ProjectTile emoji="📱" title="App Design" />
              <ProjectTile emoji="🌐" title="Web Development" />
              <ProjectTile emoji="🧠" title="Psychology Study" />
              <ProjectTile emoji="🦠" title="Biology Research" />
              <ProjectTile emoji="🌌" title="Astronomy Model" />
              <ProjectTile emoji="🏗️" title="Engineering Challenge" />
              <ProjectTile emoji="📊" title="Data Analysis" />
              <ProjectTile emoji="🔭" title="Scientific Research" />
              <ProjectTile emoji="🎬" title="Video Production" />
              {/* Repeat some tiles to ensure continuous scrolling */}
              <ProjectTile emoji="💻" title="Coding Project" />
              <ProjectTile emoji="🔬" title="Science Experiment" />
              <ProjectTile emoji="📖" title="Research Paper" />
              <ProjectTile emoji="🎨" title="Art Installation" />
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

const ProjectTile = ({ emoji, title }) => (
  <li className="flex-shrink-0 mx-2 px-4 py-2 bg-gray-800/50 backdrop-blur-sm border border-gray-700/50 rounded-lg shadow-lg">
    <div className="flex items-center space-x-2">
      <span className="text-xl">{emoji}</span>
      <span className="font-medium text-gray-200">{title}</span>
    </div>
  </li>
);


const QuickProjectFormEnhanced = () => {
  const [formData, setFormData] = useState({
    age: "",
    interest: "",
    specific_topic: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isProjectGenerated, setIsProjectGenerated] = useState(false);
  const [projectData, setProjectData] = useState(null);

  // Check for URL parameters
  useEffect(() => {
    if (window.location.pathname === '/project-generation') {
      const params = new URLSearchParams(window.location.search);
      const age = params.get('age');
      const interest = params.get('interest');
      const topic = params.get('topic');
      
      if (age || interest || topic) {
        const newFormData = {
          age: age || '',
          interest: interest || '',
          specific_topic: topic || '',
        };
        setFormData(newFormData);
        
        // Auto-generate project if all fields are present
        if (age && interest && topic) {
          handleSubmit(null, newFormData);
        }
      }
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e, customFormData = null) => {
    if (e) e.preventDefault();
    
    const dataToSubmit = customFormData || formData;
    
    setIsLoading(true);
    setError(null);

    try {
      // Track the event
      posthog.capture("quick_project_generated", {
        age: dataToSubmit.age,
        interest: dataToSubmit.interest,
        specific_topic: dataToSubmit.specific_topic,
        source: "landing_page",
        is_lead: true
      });

      // If we're on the landing page, redirect to the project generation page
      if (window.location.pathname !== '/project-generation') {
        window.location.href = `/project-generation?age=${dataToSubmit.age}&interest=${encodeURIComponent(dataToSubmit.interest)}&topic=${encodeURIComponent(dataToSubmit.specific_topic)}`;
        return;
      }

      // Otherwise, fetch the project data
      const apiData = {
        age: dataToSubmit.age,
        interest: dataToSubmit.interest,
        specific_topic: dataToSubmit.specific_topic
      };

      const response = await fetch('http://localhost:5001/api/generate_quick_project', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(apiData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to generate project');
      }

      const data = await response.json();
      setProjectData(data);
      setIsProjectGenerated(true);
    } catch (err) {
      console.error('Error generating project:', err);
      setError('Failed to generate project. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // If we're showing a generated project, render it
  if (isProjectGenerated && projectData) {
    return (
      <div className="bg-gray-950/60 backdrop-blur-md rounded-lg p-5">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-indigo-400">
            Your Project
          </h3>
          <button
            onClick={() => setIsProjectGenerated(false)}
            className="text-sm text-gray-400 hover:text-white px-2 py-1 rounded border border-gray-700 hover:border-indigo-500/50 transition-colors"
          >
            Back to Form
          </button>
        </div>
        
        {/* Render project content with scrollable container */}
        <div className="border border-indigo-500/30 rounded-lg overflow-hidden">
          <div className="bg-indigo-900/30 p-3">
            <h3 className="text-lg font-semibold text-white">{projectData.project.title}</h3>
          </div>
          <div className="p-4 bg-gray-950/50 max-h-[70vh] overflow-y-auto">
            <div className="prose prose-invert max-w-none prose-sm" dangerouslySetInnerHTML={{ __html: projectData.project.description }}></div>

            {projectData.project.instructions && (
              <div className="mt-6">
                <h4 className="font-medium mb-2 text-white">Instructions:</h4>
                <div className="prose prose-invert max-w-none prose-sm" dangerouslySetInnerHTML={{ __html: projectData.project.instructions }}></div>
              </div>
            )}
          </div>
        </div>
        
        <div className="mt-6 flex justify-center">
          <button
            className="px-5 py-2 bg-purple-600 hover:bg-purple-700 text-white font-bold rounded-lg focus:outline-none focus:shadow-outline transform transition-all duration-300 hover:scale-[1.02] hover:shadow-lg"
            onClick={() => {
              // Go to the full curriculum view
              window.location.href = `/home`;
            }}
          >
            <span className="inline-flex items-center">
              <span className="mr-2">🚀</span> Explore Full Curriculum
            </span>
          </button>
        </div>
      </div>
    );
  }

  // Otherwise show the form
  return (
    <div>
      <h3 className="text-xl font-semibold mb-4 text-center text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-indigo-400">
        Topic to complete project in seconds
      </h3>

      <form onSubmit={handleSubmit} className="space-y-3">
        <div>
          <input
            id="age"
            name="age"
            type="number"
            min="5"
            max="18"
            value={formData.age}
            onChange={handleChange}
            className="shadow-inner shadow-indigo-900/20 appearance-none border border-gray-700 bg-gray-900/80 rounded-lg w-full py-2 px-3 text-gray-200 leading-tight focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-transparent"
            required
            placeholder="Student's age (5-18)"
          />
        </div>

        <div>
          <input
            id="interest"
            name="interest"
            type="text"
            value={formData.interest}
            onChange={handleChange}
            className="shadow-inner shadow-indigo-900/20 appearance-none border border-gray-700 bg-gray-900/80 rounded-lg w-full py-2 px-3 text-gray-200 leading-tight focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-transparent"
            required
            placeholder="Interest (e.g., Game Development)"
          />
        </div>

        <div>
          <input
            id="specific_topic"
            name="specific_topic"
            type="text"
            value={formData.specific_topic}
            onChange={handleChange}
            className="shadow-inner shadow-indigo-900/20 appearance-none border border-gray-700 bg-gray-900/80 rounded-lg w-full py-2 px-3 text-gray-200 leading-tight focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-transparent"
            required
            placeholder="Topic (e.g., 3D Modeling, Black Holes)"
          />
        </div>

        <button
          type="submit"
          className={`w-full mt-1 relative bg-purple-600 hover:bg-purple-700 text-white font-medium py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline transition-all duration-200 ${
            isLoading ? "opacity-80 cursor-wait" : ""
          }`}
          disabled={isLoading}
        >
          <span className={`inline-flex items-center justify-center ${isLoading ? 'opacity-0' : ''}`}>
            <span className="mr-2">✨</span> 
            Generate Project & Resources
          </span>
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center">
              <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            </div>
          )}
        </button>
      </form>

      {error && (
        <div className="bg-red-900/50 border border-red-500 text-red-200 p-3 rounded-lg mt-3 text-sm">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

const Benefits = () => {
  return (
    <section className="py-8 bg-gray-950">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-6">
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 via-pink-500 to-indigo-400">
            Transforming homeschool education
          </span>
        </h2>
        
        <div className="grid md:grid-cols-3 gap-6 max-w-6xl mx-auto">
          <BenefitCard 
            icon="🔍"
            title="Personalized Learning"
            description="Projects tailored specifically to your teen's unique interests and future goals make learning naturally engaging"
          />
          
          <BenefitCard 
            icon="💡"
            title="Instant Curriculum"
            description="No more spending hours searching for relevant projects - get comprehensive learning activities in seconds"
          />
          
          <BenefitCard 
            icon="🔄"
            title="Cross-Discipline Skills"
            description="Build critical thinking, research skills, and real-world problem solving through integrated project-based learning"
          />
        </div>
      </div>
    </section>
  );
};

const BenefitCard = ({ icon, title, description }) => (
  <div className="bg-gray-900/50 backdrop-blur-sm border border-indigo-500/10 rounded-xl p-5 shadow-xl transform transition-all duration-300 hover:scale-105 hover:border-indigo-500/30 hover:shadow-indigo-500/10">
    <div className="h-12 w-12 rounded-full bg-gradient-to-br from-purple-600/30 to-indigo-600/30 border border-purple-500/30 flex items-center justify-center mb-3 text-xl">
      {icon}
    </div>
    <h3 className="text-lg font-bold mb-2 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-indigo-400">
      {title}
    </h3>
    <p className="text-gray-300 text-sm leading-relaxed">
      {description}
    </p>
  </div>
);

const AboutUsShort = () => {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  
  useEffect(() => {
    const userId = localStorage.getItem("userId");
    setUserLoggedIn(!!userId);
  }, []);

  return (
    <section className="py-8 bg-gray-950">
      <div className="container mx-auto px-6">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-2xl font-bold mb-4">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-indigo-400">
              Empowering homeschool families
            </span>
          </h2>
          <p className="text-base text-gray-300 leading-relaxed mb-6">
            We're on a mission to transform how teens learn by connecting education
            to their unique interests and providing tools that make learning
            naturally engaging, accessible, and effective.
          </p>
          
          <div className="flex flex-wrap justify-center gap-4 mt-4">
            <Link
              to="/about"
              className="px-4 py-2 bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white font-bold rounded-lg transition duration-300"
              onClick={() => posthog.capture("about_link_clicked", { location: "footer" })}
            >
              Learn About Our Story
            </Link>
            {userLoggedIn ? (
              <Link
                to="/home" 
                className="px-4 py-2 border-2 border-green-500 text-green-300 hover:bg-green-900/20 font-bold rounded-lg transition duration-300"
                onClick={() => posthog.capture("dashboard_link_clicked", { location: "footer" })}
              >
                Go to Dashboard
              </Link>
            ) : (
              <Link
                to="/home" 
                className="px-4 py-2 border-2 border-indigo-500 text-indigo-300 hover:bg-indigo-900/20 font-bold rounded-lg transition duration-300"
                onClick={() => posthog.capture("curriculum_link_clicked", { location: "footer" })}
              >
                Explore Full Curriculum
              </Link>
            )}
          </div>
        </div>
      </div>

      <div className="mt-8 py-6 bg-gray-950 border-t border-gray-800">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div>
              <h3 className="text-lg font-bold mb-1">Heretic.School</h3>
              <p className="text-sm text-gray-400">Learn Wherever You Are</p>
            </div>

            <div className="mt-4 md:mt-0 grid grid-cols-2 gap-x-8 gap-y-2">
              <div>
                <h4 className="text-base font-semibold mb-2 text-purple-400">Resources</h4>
                <ul className="space-y-1">
                  <li>
                    <Link to="/blog" className="text-sm text-gray-400 hover:text-white transition duration-200">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <a
                      href="https://chromewebstore.google.com/detail/hereticschool-chrome-exte/odlfeaecpimnkgmeigjokbhemhbppage"
                      className="text-sm text-gray-400 hover:text-white transition duration-200"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Chrome Extension
                    </a>
                  </li>
                </ul>
              </div>

              <div>
                <h4 className="text-base font-semibold mb-2 text-indigo-400">Company</h4>
                <ul className="space-y-1">
                  <li>
                    <Link to="/about" className="text-sm text-gray-400 hover:text-white transition duration-200">
                      About Us
                    </Link>
                  </li>
                  <li>
                    {userLoggedIn ? (
                      <Link
                        to="/home"
                        className="text-sm text-gray-400 hover:text-white transition duration-200"
                      >
                        Dashboard
                      </Link>
                    ) : (
                      <Link
                        to="/auth"
                        className="text-sm text-gray-400 hover:text-white transition duration-200"
                      >
                        Sign Up
                      </Link>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="text-center mt-6 text-gray-500 text-xs">
            © {new Date().getFullYear()} Heretic.School. All rights reserved.
          </div>
        </div>
      </div>
    </section>
  );
};

// Changing PersonalizedApproach background to bg-gray-950
const PersonalizedApproach = () => (
  <section className="py-12 bg-gray-950">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold text-center mb-6">
        A <Gradient>Personalized</Gradient> Approach to Learning
      </h2>

      <p className="text-lg text-center text-gray-300 mb-8 max-w-3xl mx-auto">
        Our platform adapts to your teen's interests and learning style, with
        powerful tools that make learning engaging and effective.
      </p>

      <div className="grid md:grid-cols-2 gap-8 max-w-6xl mx-auto">
        {/* Personalized Dashboard Card */}
        <div className="bg-gray-950/50 rounded-2xl overflow-hidden border border-gray-800 shadow-xl flex flex-col">
          <div className="p-6 pb-0">
            <h3 className="text-2xl font-bold mb-3">
              <Gradient>Personalized Dashboard</Gradient>
            </h3>
            <p className="text-gray-300 mb-5">
              A customized dashboard recommends content and projects based on
              your teen's interests, dream job, and learning progress.
            </p>
          </div>

          <div className="px-6 flex-grow">
            <div className="bg-gray-900/80 rounded-t-xl p-6 pt-5 border-t border-l border-r border-gray-800 h-[450px] flex items-center justify-center">
              <img
                src="/dashboard_demo.png"
                alt="Personalized dashboard"
                className="max-h-full w-auto rounded shadow-lg border border-gray-800"
              />
            </div>
          </div>

          <div className="p-6 pt-5 bg-gray-900/30">
            <ul className="text-gray-300 grid grid-cols-2 gap-3">
              <li className="flex items-start">
                <span className="text-green-500 mr-2 mt-0.5">✓</span>
                <span>Interest-based recommendations</span>
              </li>
              <li className="flex items-start">
                <span className="text-green-500 mr-2 mt-0.5">✓</span>
                <span>Progress tracking</span>
              </li>
              <li className="flex items-start">
                <span className="text-green-500 mr-2 mt-0.5">✓</span>
                <span>AI-generated content</span>
              </li>
              <li className="flex items-start">
                <span className="text-green-500 mr-2 mt-0.5">✓</span>
                <span>Personalized goals</span>
              </li>
            </ul>
          </div>
        </div>

        {/* Smart Learning Sidebar Card */}
        <div className="bg-gray-950/50 rounded-2xl overflow-hidden border border-gray-800 shadow-xl flex flex-col">
          <div className="p-6 pb-0">
            <h3 className="text-2xl font-bold mb-3">
              <Gradient>Smart Learning Sidebar</Gradient>
            </h3>
            <p className="text-gray-300 mb-5">
              Our browser extension adds a sidebar to any website, transforming
              casual browsing into active learning opportunities.
            </p>
          </div>

          <div className="px-6 flex-grow">
            <div className="bg-gray-900/80 rounded-t-xl p-6 pt-5 border-t border-l border-r border-gray-800 h-[450px] flex items-center justify-center">
              <img
                src="/sidebar_demo.png"
                alt="Smart learning sidebar"
                className="max-h-full w-auto rounded shadow-lg border border-gray-800"
              />
            </div>
          </div>

          <div className="p-6 pt-5 bg-gray-900/30">
            <ul className="text-gray-300 grid grid-cols-2 gap-3">
              <li className="flex items-start">
                <span className="text-green-500 mr-2 mt-0.5">✓</span>
                <span>Learning resources</span>
              </li>
              <li className="flex items-start">
                <span className="text-green-500 mr-2 mt-0.5">✓</span>
                <span>AI-generated questions</span>
              </li>
              <li className="flex items-start">
                <span className="text-green-500 mr-2 mt-0.5">✓</span>
                <span>Voice-based learning</span>
              </li>
              <li className="flex items-start">
                <span className="text-green-500 mr-2 mt-0.5">✓</span>
                <span>Real-time feedback</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Add CTA button for the full curriculum */}
      <div className="mt-10 text-center">
        <Link
          to="/home"
          className="inline-block px-6 py-3 bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white font-bold rounded-lg transition-all duration-300 hover:scale-[1.02] hover:shadow-lg"
          onClick={() => posthog.capture("curriculum_link_clicked", { from: "personalized_approach_section" })}
        >
          <span className="inline-flex items-center">
            <span className="mr-2">✨</span> Explore Full Curriculum
          </span>
        </Link>
      </div>
    </div>
  </section>
);

// Changing TailoredLearning background to bg-gray-950
const TailoredLearning = () => (
  <section className="py-12 bg-gray-950">
    <div className="container mx-auto px-4">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold mb-4">
          Learning Tailored to <Gradient>Their Interests</Gradient>
        </h2>
        <p className="text-lg text-gray-300 max-w-3xl mx-auto">
          We connect your teen's learning to their interests, making education
          more engaging and relevant to their future
        </p>
      </div>

      <div className="grid md:grid-cols-3 gap-6 max-w-5xl mx-auto">
        <div className="bg-gray-800 rounded-xl p-5 border border-gray-700 hover:border-purple-500/30 transition-all">
          <div className="h-12 w-12 rounded-full bg-purple-900/50 flex items-center justify-center mb-4">
            <span className="text-xl">🎮</span>
          </div>
          <h3 className="text-xl font-bold mb-3">Gaming Enthusiast</h3>
          <p className="text-gray-300 mb-4">
            Learn about market dynamics and statistical analysis through game
            economies and interactive challenges
          </p>
          <div className="text-purple-400 text-sm font-semibold">
            Economics • Statistics • Programming
          </div>
        </div>

        <div className="bg-gray-800 rounded-xl p-5 border border-gray-700 hover:border-purple-500/30 transition-all">
          <div className="h-12 w-12 rounded-full bg-purple-900/50 flex items-center justify-center mb-4">
            <span className="text-xl">🎨</span>
          </div>
          <h3 className="text-xl font-bold mb-3">Art & Design</h3>
          <p className="text-gray-300 mb-4">
            Explore cultural contexts and textile science through historical
            design and creative expression
          </p>
          <div className="text-purple-400 text-sm font-semibold">
            History • Art • Social Studies
          </div>
        </div>

        <div className="bg-gray-800 rounded-xl p-5 border border-gray-700 hover:border-purple-500/30 transition-all">
          <div className="h-12 w-12 rounded-full bg-purple-900/50 flex items-center justify-center mb-4">
            <span className="text-xl">🧬</span>
          </div>
          <h3 className="text-xl font-bold mb-3">Science Enthusiast</h3>
          <p className="text-gray-300 mb-4">
            Develop critical thinking through environmental science and
            real-world observations of natural phenomena
          </p>
          <div className="text-purple-400 text-sm font-semibold">
            Biology • Chemistry • Environmental Science
          </div>
        </div>
      </div>
    </div>
  </section>
);

// Changing HowItWorks background to bg-gray-950
const HowItWorks = () => (
  <section className="py-12 bg-gray-950">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold text-center mb-6">
        <Gradient>How It Works</Gradient>
      </h2>
      <div className="flex flex-row justify-center max-w-5xl mx-auto">
        <div className="flex flex-col items-center w-1/3 px-4 mb-6">
          <div className="w-12 h-12 flex-shrink-0 bg-purple-600 rounded-full flex items-center justify-center text-xl font-bold mb-4">
            1
          </div>
          <div className="text-center">
            <h3 className="text-xl font-semibold mb-2">Explore Topics</h3>
            <p className="text-gray-300">
              Browse recommended content based on your teen's interests and
              learning style
            </p>
          </div>
        </div>

        <div className="flex flex-col items-center w-1/3 px-4 mb-6">
          <div className="w-12 h-12 flex-shrink-0 bg-purple-600 rounded-full flex items-center justify-center text-xl font-bold mb-4">
            2
          </div>
          <div className="text-center">
            <h3 className="text-xl font-semibold mb-2">Active Learning</h3>
            <p className="text-gray-300">
              Our smart sidebar prompts with questions to engage more deeply
              with online content
            </p>
          </div>
        </div>

        <div className="flex flex-col items-center w-1/3 px-4 mb-6">
          <div className="w-12 h-12 flex-shrink-0 bg-purple-600 rounded-full flex items-center justify-center text-xl font-bold mb-4">
            3
          </div>
          <div className="text-center">
            <h3 className="text-xl font-semibold mb-2">Track Progress</h3>
            <p className="text-gray-300">
              Get personalized feedback and see your teen's growth across
              subjects and skills
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

// Add animation styles to the head
const styleElement = document.createElement('style');
styleElement.innerHTML = `
@keyframes scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
}
@keyframes scroll2 {
  0% { transform: translateX(-50%); }
  100% { transform: translateX(0); }
}
.animate-scroll {
  animation: scroll 30s linear infinite;
}
.animate-scroll2 {
  animation: scroll2 30s linear infinite;
}

/* Project content styling */
.prose-sm a {
  color: #818cf8;
  text-decoration: underline;
}
.prose-sm h1, .prose-sm h2, .prose-sm h3 {
  color: white;
  margin-top: 1.5em;
  margin-bottom: 0.75em;
}
.prose-sm ol, .prose-sm ul {
  padding-left: 1.5em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.prose-sm li {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}
.prose-sm p {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
`;
document.head.appendChild(styleElement);

export default LandingPage;
