import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { posts } from "../posts";
import Header from "./Header";

const Gradient = ({ children }) => (
  <span className="text-transparent bg-gradient-to-br from-purple-500 to-indigo-500 bg-clip-text">
    {children}
  </span>
);

const BlogList = () => {
  // Sort posts by date modified, most recent first
  const sortedPosts = [...posts].sort((a, b) => {
    const dateA = a.meta?.dateModified || "1970-01-01";
    const dateB = b.meta?.dateModified || "1970-01-01";
    return new Date(dateB) - new Date(dateA);
  });

  return (
    <div className="bg-gray-950 min-h-screen">
      <Header />
      <div className="container mx-auto px-4 py-12">
        <Helmet>
          <title>
            Heretic School Blog - Homeschooling Resources and Insights
          </title>
          <meta
            name="description"
            content="Explore our collection of articles on homeschooling, education, and learning strategies. Find valuable resources and insights for your homeschooling journey."
          />
          <meta
            property="og:title"
            content="Heretic School Blog - Homeschooling Resources and Insights"
          />
          <meta
            property="og:description"
            content="Explore our collection of articles on homeschooling, education, and learning strategies. Find valuable resources and insights for your homeschooling journey."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://hereticschool.com/blog" />
        </Helmet>

        <h1 className="text-5xl font-bold mb-12 text-center">
          <Gradient>Blog</Gradient>
        </h1>

        <div className="max-w-4xl mx-auto grid gap-8">
          {sortedPosts.map((post) => (
            <article
              key={post.slug}
              className="bg-gray-800 bg-opacity-80 backdrop-blur-xl rounded-2xl shadow-2xl p-8 hover:shadow-glow transition-all duration-300 transform hover:scale-[1.02]"
            >
              <Link to={`/blog/${post.slug}`} className="block">
                <h2 className="text-3xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-gray-400">
                  {post.title}
                </h2>
                {post.meta?.dateModified && (
                  <p className="text-gray-400 text-sm mb-2">
                    Last Updated:{" "}
                    {new Date(post.meta.dateModified).toLocaleDateString()}
                  </p>
                )}
                <p className="text-gray-300 mb-6 text-lg leading-relaxed">
                  {post.description}
                </p>
                <div className="flex items-center text-blue-400 hover:text-blue-300 transition-colors duration-200">
                  <span className="mr-2">Read more</span>
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 7l5 5m0 0l-5 5m5-5H6"
                    />
                  </svg>
                </div>
              </Link>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogList;
