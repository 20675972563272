import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import ErrorBoundary from "../ErrorBoundary";
import posthog from "posthog-js";
import { track } from "@vercel/analytics";
import styled from "styled-components";

console.log("backendurl", process.env.REACT_APP_BACKEND_URL);

// NextIndicator component to highlight the next item to work on
const NextIndicator = ({ isNext }) => {
  if (!isNext) return null;

  return (
    <div className="absolute -top-2 -right-2 z-10">
      <div className="bg-gradient-to-r from-yellow-500 to-orange-500 text-white px-3 py-1 rounded-md text-xs font-bold shadow-lg border border-yellow-300">
        NEXT
      </div>
    </div>
  );
};

const ContentBox = ({
  content,
  onReroll,
  user,
  defaultExpanded = false,
  isNext = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const [isRerollDialogOpen, setIsRerollDialogOpen] = useState(false);

  useEffect(() => {
    setIsExpanded(defaultExpanded);
  }, [defaultExpanded]);

  useEffect(() => {
    // Validate content structure
    const requiredFields = ["url", "title", "description", "category"];
    const missingFields = requiredFields.filter((field) => !content?.[field]);
    if (missingFields.length > 0) {
      console.error(
        `Invalid content structure, missing: ${missingFields.join(", ")}`,
        content
      );
      return;
    }
  }, [content]);

  const handleLinkClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!content?.url) return;

    const isYouTube =
      content.url.includes("youtube.com/watch") ||
      content.url.includes("youtu.be/");

    posthog.capture("content_opened", {
      contentId: content.id,
      contentType: isYouTube ? "video" : "article",
      url: content.url,
    });
    track("content_opened", {
      contentId: content.id,
      contentType: isYouTube ? "video" : "article",
      url: content.url,
    });
    window.postMessage(
      {
        type: "HERETIC_OPEN_URL",
        action: "openUrlInExtension",
        url: content.url,
        submissionId: content.id,
        userId: user?.id,
        contentType: isYouTube ? "video" : "article",
      },
      "*"
    );
  };

  const getContentTypeStyles = () => {
    const type = content.type?.toLowerCase() || '';
    if (type.includes('video')) {
      return {
        icon: (
          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        ),
        gradient: 'from-blue-500/20 via-blue-400/20 to-cyan-500/20',
        accent: 'text-blue-400',
        border: 'border-blue-500/20'
      };
    } else if (type.includes('article')) {
      return {
        icon: (
          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9.5a2.5 2.5 0 00-2.5-2.5H15" />
          </svg>
        ),
        gradient: 'from-purple-500/20 via-purple-400/20 to-pink-500/20',
        accent: 'text-purple-400',
        border: 'border-purple-500/20'
      };
    } else {
      return {
        icon: (
          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
          </svg>
        ),
        gradient: 'from-emerald-500/20 via-emerald-400/20 to-teal-500/20',
        accent: 'text-emerald-400',
        border: 'border-emerald-500/20'
      };
    }
  };

  const styles = getContentTypeStyles();

  return (
    <div className="relative p-[2px] mb-4 group">
      {/* Background glow effect */}
      <div className={`absolute -inset-[1px] bg-gradient-to-r ${
        isNext
          ? 'from-amber-500/30 via-amber-400/30 to-orange-500/30'
          : styles.gradient
      } rounded-xl blur transition duration-500 group-hover:opacity-100 ${
        isNext ? 'opacity-100 animate-pulse' : 'opacity-75'
      }`} />

      <div
        className={`relative bg-[#1C1C2E]/90 backdrop-blur-xl rounded-xl border ${
          isNext ? 'border-amber-500/30' : 'border-white/5'
        } ${isExpanded ? 'p-6' : 'p-4'}`}
        onClick={() => {
          if (!isExpanded) setIsExpanded(true);
        }}
      >
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-3">
            {/* Content type indicator */}
            <div className={`w-8 h-8 rounded-lg flex items-center justify-center ${
              isNext ? 'bg-amber-500/20' : `bg-white/5`
            } ${styles.accent}`}>
              {styles.icon}
            </div>
            
            <h3 className={`text-lg font-medium ${
              isNext
                ? 'text-amber-300'
                : 'text-white'
            }`}>
              {content.title}
            </h3>
            
            {/* Completion status */}
            {content.is_completed !== undefined && (
              <div className="ml-2">
                {content.is_completed ? (
                  <div className="w-5 h-5 rounded-full bg-emerald-500/90 flex items-center justify-center">
                    <svg className="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                ) : (
                  <div className="w-5 h-5 rounded-full bg-rose-500/90 flex items-center justify-center">
                    <svg className="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </div>
                )}
              </div>
            )}
          </div>

          <button
            onClick={(e) => {
              e.stopPropagation();
              setIsExpanded(!isExpanded);
            }}
            className={`text-white/50 hover:text-white/80 transition-colors p-2 rounded-lg hover:bg-white/5 ${
              isExpanded ? 'rotate-180' : ''
            }`}
          >
            <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        {isExpanded && (
          <div className="mt-4 space-y-4 animate-fadeIn">
            {/* Content preview */}
            <div className={`relative p-4 rounded-lg bg-white/5 border ${styles.border} group`}>
              <div className="flex items-center gap-2 mb-2">
                {styles.icon}
                <div className={styles.accent}>
                  Content Preview
                </div>
              </div>
              
              <a
                href={content.url}
                onClick={handleLinkClick}
                className="block text-lg text-white/90 hover:text-white transition-colors break-all group-hover:underline"
              >
                {content.url}
              </a>
              
              <div className="mt-2 flex items-center gap-1 text-xs text-white/50">
                <svg className="w-3.5 h-3.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
                Opens in new tab
              </div>
            </div>

            {/* Description */}
            <div className="text-white/80 leading-relaxed">
              {content.description}
            </div>

            {/* Action buttons */}
            <div className="flex gap-3">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setIsRerollDialogOpen(true);
                }}
                className={`flex-1 px-4 py-2.5 rounded-md font-medium text-sm transition-all duration-300 ${
                  isNext
                    ? 'bg-gradient-to-r from-amber-500/90 to-orange-500/90 hover:from-amber-600/90 hover:to-orange-600/90 text-white shadow-lg shadow-amber-500/20'
                    : 'bg-white/5 text-white/90 hover:bg-white/10 border border-white/10'
                }`}
              >
                Re-roll Content
              </button>
            </div>
          </div>
        )}

        {isRerollDialogOpen && (
          <RerollDialog
            onClose={() => setIsRerollDialogOpen(false)}
            onSubmit={(reason) => {
              onReroll(content.id, reason);
              setIsRerollDialogOpen(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

const RerollDialog = ({ onClose, onSubmit }) => {
  const [reason, setReason] = useState("");

  return (
    <div
      className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center z-50"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="relative w-full max-w-md p-[1px] rounded-xl">
        <div className="absolute inset-0 bg-gradient-to-r from-purple-500/20 via-blue-500/20 to-cyan-500/20 rounded-xl blur" />
        <div className="relative bg-[#1C1C2E]/95 backdrop-blur-xl rounded-xl p-6 border border-white/5">
          <h2 className="text-xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-white via-blue-100 to-white mb-4">
            Re-roll Content
          </h2>
          <textarea
            className="w-full p-3 bg-white/5 text-white/90 rounded-lg border border-white/10 focus:outline-none focus:ring-2 focus:ring-purple-500/50 focus:border-purple-500/50 transition-all placeholder-white/30"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            placeholder="Why would you like to re-roll this content?"
            rows={4}
          />
          <div className="flex justify-end gap-3 mt-4">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-white/5 text-white/90 rounded-md hover:bg-white/10 transition-all border border-white/10"
            >
              Cancel
            </button>
            <button
              onClick={() => onSubmit(reason)}
              className="px-4 py-2 bg-gradient-to-r from-purple-500/90 to-blue-500/90 hover:from-purple-600/90 hover:to-blue-600/90 text-white rounded-md transition-all shadow-lg shadow-purple-500/20"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const BorderGradient = styled.div`
  position: absolute;
  inset: 0;
  border-radius: 0.75rem;
  padding: 2px;
  background: ${(props) =>
    props.$isNext
      ? "linear-gradient(to right, rgba(217, 119, 6, 0.5), rgba(245, 158, 11, 0.5))"
      : props.$isShortAnswer
      ? "linear-gradient(to right, rgba(147, 51, 234, 0.4), rgba(192, 38, 211, 0.4))"
      : "linear-gradient(to right, rgba(168, 85, 247, 0.4), rgba(192, 38, 211, 0.4))"};
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  ${(props) => props.$isNext && "animation: pulse 2s infinite;"}

  @keyframes pulse {
    0% {
      opacity: 0.7;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.7;
    }
  }
`;

const VoiceChatInput = ({
  isRecording,
  startRecording,
  stopRecording,
  audioURL,
}) => {
  return (
    <div className="space-y-4">
      <div className="flex items-center gap-4">
        <button
          type="button"
          onClick={isRecording ? stopRecording : startRecording}
          className={`p-3 rounded-full ${
            isRecording
              ? "bg-red-500 hover:bg-red-600"
              : "bg-pink-500 hover:bg-pink-600"
          } transition-colors`}
        >
          <svg
            className="w-6 h-6 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            {isRecording ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            ) : (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
              />
            )}
          </svg>
        </button>
        {audioURL && (
          <audio controls className="flex-1">
            <source src={audioURL} type="audio/wav" />
            Your browser does not support audio playback
          </audio>
        )}
      </div>
      <p className="text-sm text-gray-400">
        {isRecording
          ? "Recording... Click to stop"
          : "Click microphone to start recording"}
      </p>
    </div>
  );
};

const MultipleChoiceQuestions = ({
  questions,
  mcAnswers,
  setMcAnswers,
  mcResults,
  handleMcSubmit,
  mcScore,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await handleMcSubmit();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="space-y-4">
      {questions.map((q, i) => (
        <div key={i} className="bg-gray-800/40 p-4 rounded-lg">
          <p className="font-medium text-gray-200 mb-2">{q.question}</p>
          <div className="space-y-2">
            {q.options.map((opt, j) => (
              <label key={j} className="flex items-center space-x-2">
                <input
                  type="radio"
                  name={`question-${i}`}
                  checked={mcAnswers[i] === j}
                  onChange={() => setMcAnswers((prev) => ({ ...prev, [i]: j }))}
                  className="form-radio text-purple-500"
                  disabled={mcResults !== null}
                />
                <span className="text-gray-300">{opt}</span>
              </label>
            ))}
          </div>
          {mcResults && mcResults[i] && (
            <div
              className={`mt-2 text-sm ${
                mcResults[i].correct ? "text-green-400" : "text-red-400"
              }`}
            >
              {q.explanation}
            </div>
          )}
        </div>
      ))}
      <button
        type="button"
        onClick={handleSubmit}
        disabled={isSubmitting}
        className={`w-full px-4 py-2 bg-gradient-to-r from-green-500 to-teal-500 
          text-white font-semibold rounded-lg hover:from-green-600 hover:to-teal-600 
          transition-all duration-300 transform hover:scale-105 relative
          ${isSubmitting ? "opacity-75 cursor-not-allowed" : ""}`}
      >
        <span className={isSubmitting ? "invisible" : ""}>Submit Answers</span>
        {isSubmitting && (
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
          </div>
        )}
      </button>
      {mcScore !== null && (
        <div className="mt-4 text-center">
          <h4 className="text-lg font-semibold text-purple-300">
            Your Score: {mcScore}%
          </h4>
        </div>
      )}
    </div>
  );
};

const ProjectBox = ({
  data,
  onReroll,
  defaultExpanded,
  className,
  user,
  activeCategory,
  onNewContent,
  refreshCache,
  nodes,
  edges,
  isNext = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const [isRerollDialogOpen, setIsRerollDialogOpen] = useState(false);
  const [submission, setSubmission] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState("");
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [submissionMode, setSubmissionMode] = useState("yap");
  const [mcAnswers, setMcAnswers] = useState({});
  const [mcResults, setMcResults] = useState(null);
  const [mcScore, setMcScore] = useState(null);
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [isPaywallFlashing, setIsPaywallFlashing] = useState(false);

  // One-time CSS injection for visual hierarchy
  useEffect(() => {
    // Create a simple style for visual hierarchy
    const styleId = "simple-hierarchy-styles";

    // Remove any existing style
    const existingStyle = document.getElementById(styleId);
    if (existingStyle) {
      existingStyle.remove();
    }

    // Add a simple style that targets specific content items
    const style = document.createElement("style");
    style.id = styleId;
    style.textContent = `
      /* Visual hierarchy styles for ProjectBox */
      .project-box {
        margin-left: 40px;
        width: calc(100% - 40px);
        max-width: 800px;
        margin-top: 1rem;
        position: relative;
      }

      .project-box::before {
        content: '';
        position: absolute;
        left: -40px;
        top: -16px;
        width: 40px;
        height: 32px;
        border-left: 3px solid rgba(147, 51, 234, 0.4);
        border-bottom: 3px solid rgba(147, 51, 234, 0.4);
        border-bottom-left-radius: 8px;
      }

      .project-box::after {
        content: '';
        position: absolute;
        left: -6px;
        top: 10px;
        width: 10px;
        height: 10px;
        border-right: 3px solid rgba(147, 51, 234, 0.4);
        border-bottom: 3px solid rgba(147, 51, 234, 0.4);
        transform: rotate(-45deg);
      }
    `;

    document.head.appendChild(style);

    return () => {
      const styleElement = document.getElementById(styleId);
      if (styleElement) {
        styleElement.remove();
      }
    };
  }, []);

  useEffect(() => {
    setIsExpanded(defaultExpanded);
  }, [defaultExpanded]);

  // Ensure type is properly identified
  const isShortAnswer = data?.type === "short_answer";

  // Check if the project has been completed based on has_project_submission
  const isCompleted = data?.project_submission || data?.has_project_submission;

  // Fetch user info for paywall check
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/check_existing_data?userId=${user.id}`
        );
        const data = await response.json();
        setUserInfo(data);
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };
    fetchUserInfo();
  }, [user.id]);

  // Add flashing effect for paywall
  useEffect(() => {
    if (!userInfo?.can_submit && !userInfo?.is_subscribed) {
      const interval = setInterval(() => {
        setIsPaywallFlashing((prev) => !prev);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [userInfo]);

  // Updated Voice Chat useEffect
  useEffect(() => {
    if (submissionMode === "yap") {
      let localRecorder = null;
      const requestMicrophone = () => {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            const recorder = new MediaRecorder(stream);
            setMediaRecorder(recorder);
            localRecorder = recorder;
            recorder.ondataavailable = (e) => {
              setAudioChunks((prev) => [...prev, e.data]);
            };
          })
          .catch((err) => {
            console.error("Error accessing microphone:", err);
            // Retry after a delay, staying in 'yap' mode
            setTimeout(() => {
              requestMicrophone();
            }, 1000);
          });
      };

      requestMicrophone();

      // Cleanup function to stop and release media stream
      return () => {
        if (localRecorder && localRecorder.state !== "inactive") {
          localRecorder.stop();
        }
        setAudioChunks([]);
        setAudioURL("");
      };
    }
  }, [submissionMode]);

  const startRecording = () => {
    setAudioChunks([]);
    mediaRecorder.start();
    setIsRecording(true);
  };

  const stopRecording = () => {
    mediaRecorder.stop();
    setIsRecording(false);
    const blob = new Blob(audioChunks, { type: "audio/wav" });
    setAudioURL(URL.createObjectURL(blob));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsSubmitting(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/submit_project`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            submissionId: data.id,
            submission: submission,
          }),
        }
      );

      if (!response.ok) throw new Error("Submission failed");

      setSubmission("");
      setAudioURL("");
      window.location.reload();
    } catch (error) {
      console.error("Submission error:", error);
      alert("Failed to submit answer. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRerollClick = (e) => {
    e.stopPropagation();
    setIsRerollDialogOpen(true);
  };

  const submitReroll = useCallback(
    (e) => {
      e.stopPropagation();
      if (isSubmitting) return;
      setIsSubmitting(true);

      if (onReroll) {
        onReroll(data.id, rejectReason);
        setIsRerollDialogOpen(false);
        setRejectReason("");
      }
      setIsSubmitting(false);
    },
    [data.id, rejectReason, isSubmitting, onReroll]
  );

  const handleMcSubmit = async () => {
    setIsSubmitting(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/submit_project`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            submissionId: data.id,
            mcAnswers: mcAnswers,
            isMcSubmission: true,
            userId: user.id,
          }),
        }
      );

      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.error || "Submission failed");
      }

      // Calculate results and update state
      const results = data.multiple_choice_questions.map((q, i) => ({
        correct: parseInt(q.correct_answer) === parseInt(mcAnswers[i]),
        explanation: q.explanation,
      }));

      setMcResults(results);
      setMcScore(result.score);
      data.feedback = result.feedback;

      if (result.newContent && result.newContent.length > 0) {
        await onNewContent(result.newContent);
        await refreshCache(); // Add refreshCache here
      }
    } catch (error) {
      console.error("MC submission error:", error);
      alert(`Failed to submit answers: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderSubmissionForm = () => {
    // If we have MC feedback or regular feedback, show that instead of the form
    if (data.feedback) {
      const mcFeedback = data.feedback.mc_score !== undefined;
      return (
        <div className="mt-4 bg-purple-900/30 rounded-lg p-3 border border-purple-500/20">
          <h4 className="text-base font-semibold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400 mb-2">
            Feedback:
          </h4>
          {mcFeedback ? (
            <>
              <p className="text-gray-300 mb-2 text-sm">
                Score: {data.feedback.mc_score}%
              </p>
              {data.feedback.incorrect_topics?.length > 0 && (
                <div className="text-yellow-400 text-sm">
                  <strong>Areas for improvement:</strong>
                  <ul className="list-disc ml-4 mt-1">
                    {data.feedback.incorrect_topics.map((topic, i) => (
                      <li key={i}>{topic}</li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          ) : (
            <>
              <p className="text-gray-300 mb-2 text-sm">
                {data.feedback.feedback}
              </p>
              <p className="text-green-400 mb-1.5 text-sm">
                <strong>Strength:</strong> {data.feedback.strength}
              </p>
              <p className="text-yellow-400 text-sm">
                <strong>Area for Improvement:</strong> {data.feedback.weakness}
              </p>
            </>
          )}
        </div>
      );
    }

    // Show paywall message if user has completed 3 projects and is not subscribed
    if (userInfo && !userInfo.can_submit && !userInfo.is_subscribed) {
      return (
        <div className="mt-4 space-y-4">
          <div
            className={`p-4 rounded-lg border-2 transition-colors duration-300 ${
              isPaywallFlashing
                ? "bg-purple-900/50 border-purple-400"
                : "bg-purple-900/30 border-purple-500/20"
            }`}
          >
            <h4 className="text-lg font-semibold text-purple-200 mb-2">
              🌟 Upgrade to Continue Your Learning Journey!
            </h4>
            <p className="text-gray-300 mb-4">
              You've completed {userInfo.completed_projects} projects! Upgrade
              to our Student Plan to unlock unlimited projects and continue your
              learning journey.
            </p>
            <button
              onClick={() => navigate("/plans")}
              className="w-full px-4 py-2 bg-gradient-to-r from-purple-500 to-pink-500 text-white font-semibold rounded-lg 
                hover:from-purple-600 hover:to-pink-600 transition-all duration-300 transform hover:scale-105 
                focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
            >
              Upgrade Now
            </button>
          </div>
        </div>
      );
    }

    // Show preview message if MC questions are available but not in MC mode
    if (
      data.type === "short_answer" &&
      data.multiple_choice_questions?.length > 0 &&
      !submissionMode
    ) {
      return (
        <div className="space-y-4">
          <h4 className="text-base font-semibold text-purple-200 mb-1.5">
            Multiple Choice Questions Available
          </h4>
          <p className="text-gray-300 text-sm">
            Click "Multiple Choice" above to answer these questions.
          </p>
        </div>
      );
    }

    return (
      <form onSubmit={handleSubmit} className="mt-4 space-y-3">
        {/* Submission type selector buttons */}
        <div className="flex gap-2 mb-4">
          {data.type === "short_answer" || !data.type ? ( // Allow if type is short_answer or undefined
            <>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setSubmissionMode("yap");
                }}
                className={`px-4 py-2 rounded-lg ${
                  submissionMode === "yap"
                    ? "bg-pink-500 text-white"
                    : "bg-gray-700 text-gray-300 hover:bg-gray-600"
                }`}
              >
                Voice Chat Answer
              </button>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setSubmissionMode("text");
                }}
                className={`px-4 py-2 rounded-lg ${
                  submissionMode === "text"
                    ? "bg-purple-500 text-white"
                    : "bg-gray-700 text-gray-300 hover:bg-gray-600"
                }`}
              >
                Text Answer
              </button>
              {data.multiple_choice_questions?.length > 0 && (
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setSubmissionMode("mc");
                  }}
                  className={`px-4 py-2 rounded-lg ${
                    submissionMode === "mc"
                      ? "bg-green-500 text-white"
                      : "bg-gray-700 text-gray-300 hover:bg-gray-600"
                  }`}
                >
                  Multiple Choice
                </button>
              )}
            </>
          ) : (
            <div className="text-gray-300">Text Submission Required</div>
          )}
        </div>

        {/* Render appropriate submission form based on mode */}
        {submissionMode === "text" && (
          <div className="space-y-3">
            <div className="flex items-center gap-2 mb-2 bg-gray-800/40 p-2 rounded-lg border border-purple-500/10">
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  const input = document.createElement("input");
                  input.type = "file";
                  input.accept = "image/*";
                  input.onchange = (e) => {
                    const file = e.target.files[0];
                    if (file) {
                      const reader = new FileReader();
                      reader.onload = (e) => {
                        // Store image data in state for later submission
                        setSubmission(
                          (prev) => prev + `\n![Image](${e.target.result})\n`
                        );
                      };
                      reader.readAsDataURL(file);
                    }
                  };
                  input.click();
                }}
                className="p-2 text-purple-300 hover:text-purple-200 transition-colors rounded-lg hover:bg-purple-500/20"
                title="Add Image"
              >
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
              </button>
              <div className="h-6 w-px bg-purple-500/20"></div>
              <button
                type="button"
                onClick={(e) => setSubmission((prev) => prev + "**Bold**")}
                className="p-2 text-purple-300 hover:text-purple-200 transition-colors rounded-lg hover:bg-purple-500/20 font-bold"
                title="Bold"
              >
                B
              </button>
              <button
                type="button"
                onClick={(e) => setSubmission((prev) => prev + "*Italic*")}
                className="p-2 text-purple-300 hover:text-purple-200 transition-colors rounded-lg hover:bg-purple-500/20 italic"
                title="Italic"
              >
                I
              </button>
              <button
                type="button"
                onClick={(e) =>
                  setSubmission((prev) => prev + "\n```\nCode Block\n```\n")
                }
                className="p-2 text-purple-300 hover:text-purple-200 transition-colors rounded-lg hover:bg-purple-500/20 font-mono"
                title="Code Block"
              >
                {"</>"}
              </button>
              <div className="h-6 w-px bg-purple-500/20"></div>
              <button
                type="button"
                onClick={(e) =>
                  setSubmission((prev) => prev + "\n- List item\n")
                }
                className="p-2 text-purple-300 hover:text-purple-200 transition-colors rounded-lg hover:bg-purple-500/20"
                title="Bullet List"
              >
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
              <button
                type="button"
                onClick={(e) =>
                  setSubmission((prev) => prev + "\n1. Numbered item\n")
                }
                className="p-2 text-purple-300 hover:text-purple-200 transition-colors rounded-lg hover:bg-purple-500/20"
                title="Numbered List"
              >
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M7 20h14M7 12h14M7 4h14M3 20h.01M3 12h.01M3 4h.01"
                  />
                </svg>
              </button>
            </div>
            <textarea
              value={submission}
              onChange={(e) => setSubmission(e.target.value)}
              className="w-full h-40 px-3 py-2 bg-purple-900/30 text-white rounded-lg border border-purple-500/30 focus:border-purple-500 focus:ring-2 focus:ring-purple-500/50 transition-colors resize-none text-sm"
              placeholder="Write your answer here... You can use Markdown formatting!"
              required
            />
          </div>
        )}

        {submissionMode === "yap" && (
          <VoiceChatInput
            isRecording={isRecording}
            startRecording={startRecording}
            stopRecording={stopRecording}
            audioURL={audioURL}
          />
        )}

        {submissionMode === "mc" && data.multiple_choice_questions && (
          <MultipleChoiceQuestions
            questions={data.multiple_choice_questions}
            mcAnswers={mcAnswers}
            setMcAnswers={setMcAnswers}
            mcResults={mcResults}
            handleMcSubmit={handleMcSubmit}
            mcScore={mcScore}
          />
        )}

        {/* Updated submit button with loading state */}
        {(submissionMode === "text" || submissionMode === "yap") && (
          <button
            type="submit"
            disabled={isSubmitting}
            className={`w-full px-4 py-2 bg-gradient-to-r from-purple-500 to-pink-500 text-white font-semibold rounded-lg 
              hover:from-purple-600 hover:to-pink-600 transition-all duration-300 transform hover:scale-105 
              focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 text-sm
              relative ${isSubmitting ? "opacity-75 cursor-not-allowed" : ""}`}
          >
            <span className={isSubmitting ? "invisible" : ""}>
              Submit Answer
            </span>
            {isSubmitting && (
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
              </div>
            )}
          </button>
        )}
      </form>
    );
  };

  // Get project type-specific styles
  const getProjectStyles = () => {
    const isShortAnswer = data?.type === "short_answer";
    
    if (isShortAnswer) {
      return {
        gradient: 'from-purple-500/20 via-purple-400/20 to-pink-500/20',
        accent: 'text-purple-400',
        border: 'border-purple-500/20',
        icon: (
          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
          </svg>
        )
      };
    }
    
    return {
      gradient: 'from-emerald-500/20 via-emerald-400/20 to-teal-500/20',
      accent: 'text-emerald-400',
      border: 'border-emerald-500/20',
      icon: (
        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
        </svg>
      )
    };
  };

  const styles = getProjectStyles();

  return (
    <div className={`relative p-[2px] mb-4 group project-box ${className}`}>
      {/* Background glow effect */}
      <div className={`absolute -inset-[1px] bg-gradient-to-r ${
        isNext
          ? 'from-amber-500/30 via-amber-400/30 to-orange-500/30'
          : styles.gradient
      } rounded-xl blur transition duration-500 group-hover:opacity-100 ${
        isNext ? 'opacity-100 animate-pulse' : 'opacity-75'
      }`} />

      <div
        className={`relative bg-[#1C1C2E]/90 backdrop-blur-xl rounded-xl border ${
          isNext ? 'border-amber-500/30' : 'border-white/5'
        } ${isExpanded ? 'p-6' : 'p-4'}`}
        onClick={() => {
          if (!isExpanded) setIsExpanded(true);
        }}
      >
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-3">
            {/* Project type indicator */}
            <div className={`w-8 h-8 rounded-lg flex items-center justify-center ${
              isNext ? 'bg-amber-500/20' : `bg-white/5`
            } ${styles.accent}`}>
              {styles.icon}
            </div>
            
            <h3 className={`text-lg font-medium ${
              isNext
                ? 'text-amber-300'
                : 'text-white'
            }`}>
              {typeof data.title === "string" ? data.title : "Project"}
            </h3>
            
            {/* Completion status */}
            <div className="ml-2">
              {isCompleted ? (
                <div className="w-5 h-5 rounded-full bg-emerald-500/90 flex items-center justify-center">
                  <svg className="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </div>
              ) : (
                <div className="w-5 h-5 rounded-full bg-rose-500/90 flex items-center justify-center">
                  <svg className="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </div>
              )}
            </div>
          </div>

          <div className="flex gap-2">
            <button
              onClick={handleRerollClick}
              className="text-white/50 hover:text-white/80 transition-colors p-2 rounded-lg hover:bg-white/5"
            >
              <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(!isExpanded);
              }}
              className={`text-white/50 hover:text-white/80 transition-colors p-2 rounded-lg hover:bg-white/5 ${
                isExpanded ? 'rotate-180' : ''
              }`}
            >
              <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>

        {isExpanded && (
          <div className="mt-4 space-y-4 animate-fadeIn">
            {/* Project description */}
            <div className={`relative p-4 rounded-lg bg-white/5 border ${styles.border}`}>
              <p className="text-white/80 text-sm">{data.description}</p>
            </div>

            {/* Render rubric if it exists */}
            {data.rubric && typeof data.rubric === "object" && (
              <div className="relative p-4 rounded-lg bg-white/5 border border-blue-500/20">
                <div className="flex items-center gap-2 mb-3">
                  <svg className="w-4 h-4 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                  </svg>
                  <h4 className="text-base font-medium text-blue-400">
                    Grading Rubric
                  </h4>
                </div>

                {data.rubric.criteria.map((criterion, index) => (
                  <div key={index} className="mb-4 last:mb-0">
                    <div className="text-sm font-medium text-white/90 mb-1">
                      {criterion.name} ({criterion.weight}%): {criterion.description}
                    </div>
                    <ul className="ml-4 space-y-1">
                      <li className="text-sm">
                        <span className="text-emerald-400">Excellent:</span>{" "}
                        <span className="text-white/70">{criterion.levels.excellent}</span>
                      </li>
                      <li className="text-sm">
                        <span className="text-blue-400">Good:</span>{" "}
                        <span className="text-white/70">{criterion.levels.good}</span>
                      </li>
                      <li className="text-sm">
                        <span className="text-amber-400">Fair:</span>{" "}
                        <span className="text-white/70">{criterion.levels.fair}</span>
                      </li>
                      <li className="text-sm">
                        <span className="text-rose-400">Needs Improvement:</span>{" "}
                        <span className="text-white/70">{criterion.levels.needs_improvement}</span>
                      </li>
                    </ul>
                  </div>
                ))}

                <div className="mt-3 pt-3 border-t border-blue-500/20 space-y-1">
                  <div className="text-sm text-white/90">
                    <span className="font-medium">Total Points:</span> {data.rubric.total_points}
                  </div>
                  <div className="text-sm text-white/90">
                    <span className="font-medium">Passing Score:</span> {data.rubric.passing_score}
                  </div>
                </div>
              </div>
            )}

            {/* Submission form or feedback */}
            {renderSubmissionForm()}
          </div>
        )}

        {isRerollDialogOpen && (
          <div
            className="fixed inset-0 bg-black/60 backdrop-blur-sm flex justify-center items-center z-50"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="relative w-full max-w-md p-[1px] rounded-xl">
              <div className="absolute inset-0 bg-gradient-to-r from-purple-500/20 via-blue-500/20 to-cyan-500/20 rounded-xl blur" />
              <div className="relative bg-[#1C1C2E]/95 backdrop-blur-xl rounded-xl p-6 border border-white/5">
                <h2 className="text-xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-white via-blue-100 to-white mb-4">
                  Re-roll Project
                </h2>
                <p className="mb-4 text-white/70">
                  Please provide a reason for re-rolling this project:
                </p>
                <textarea
                  className="w-full p-3 bg-white/5 text-white/90 rounded-lg border border-white/10 focus:outline-none focus:ring-2 focus:ring-purple-500/50 focus:border-purple-500/50 transition-all placeholder-white/30"
                  value={rejectReason}
                  onChange={(e) => setRejectReason(e.target.value)}
                  rows={4}
                  onClick={(e) => e.stopPropagation()}
                />
                <div className="mt-4 flex justify-end gap-2">
                  <button
                    className="px-4 py-2 bg-white/5 text-white/90 rounded-md hover:bg-white/10 transition-all border border-white/10"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsRerollDialogOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="px-4 py-2 bg-gradient-to-r from-purple-500/90 to-blue-500/90 hover:from-purple-600/90 hover:to-blue-600/90 text-white rounded-md transition-all shadow-lg shadow-purple-500/20"
                    onClick={submitReroll}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ProfileInfo = ({ dreamJob, biggestInterest, onUpdateClick }) => {
  return (
    <div className="bg-gray-900 rounded-xl p-6 mb-6 border border-gray-800 shadow-[0_0_15px_rgba(255,255,255,0.1)]">
      <div className="flex justify-between items-start">
        <div>
          <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-300 mb-4">
            Your Learning Profile
          </h2>
          <div className="space-y-2">
            <div>
              <span className="text-gray-400">Dream Job:</span>{" "}
              <span className="text-white">{dreamJob}</span>
            </div>
            <div>
              <span className="text-gray-400">Biggest Interest:</span>{" "}
              <span className="text-white">{biggestInterest}</span>
            </div>
          </div>
        </div>
        <button
          onClick={onUpdateClick}
          className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition duration-300 shadow-lg"
        >
          Update Profile
        </button>
      </div>
    </div>
  );
};

const UpdateProfileDialog = ({
  onClose,
  currentDreamJob,
  currentPersonalInterest,
  onUpdate,
}) => {
  const [dreamJob, setDreamJob] = useState(currentDreamJob);
  const [personalInterest, setPersonalInterest] = useState(
    currentPersonalInterest
  );

  const handleTextareaInput = (e) => {
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  };

  useEffect(() => {
    // Initialize textarea heights
    const textareas = document.querySelectorAll(".auto-resize");
    textareas.forEach((textarea) => {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    });
  }, [dreamJob, personalInterest]);

  return (
    <div
      className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-gray-800 p-8 rounded-xl shadow-2xl border border-purple-500/20 w-full max-w-md"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-blue-400 mb-6">
          Update Your Profile
        </h2>
        <div className="space-y-4">
          <div>
            <label className="block text-gray-300 mb-2 font-semibold">
              Dream Job
            </label>
            <textarea
              value={dreamJob}
              onChange={(e) => setDreamJob(e.target.value)}
              onInput={handleTextareaInput}
              className="auto-resize w-full p-3 bg-gray-700 text-white rounded-lg border border-gray-600 focus:border-purple-500 focus:ring-1 focus:ring-purple-500 transition-colors resize-none overflow-hidden min-h-[48px]"
              rows={1}
            />
          </div>
          <div>
            <label className="block text-gray-300 mb-2 font-semibold">
              Biggest Interest
            </label>
            <textarea
              value={personalInterest}
              onChange={(e) => setPersonalInterest(e.target.value)}
              onInput={handleTextareaInput}
              className="auto-resize w-full p-3 bg-gray-700 text-white rounded-lg border border-gray-600 focus:border-purple-500 focus:ring-1 focus:ring-purple-500 transition-colors resize-none overflow-hidden min-h-[48px]"
              rows={1}
            />
          </div>
        </div>
        <div className="flex justify-end gap-3 mt-6">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={() => onUpdate(dreamJob, personalInterest)}
            className="px-4 py-2 bg-gradient-to-r from-purple-500 to-blue-500 text-white rounded-lg hover:from-purple-600 hover:to-blue-600 transition-colors shadow-lg"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

const ExtensionPrompt = ({ onDismiss }) => {
  return (
    <div className="mb-4 p-4 rounded-lg bg-gradient-to-r from-red-500/20 to-orange-500/20 border border-red-500/30">
      <div className="flex justify-between items-start">
        <div className="flex-1">
          <h3 className="text-lg font-semibold text-red-400 mb-2">
            Install Chrome Extension
          </h3>
          <p className="text-gray-300">
            Get the most out of Heretic School by installing our Chrome
            extension. Track your progress and get personalized recommendations!
          </p>
          <div className="mt-3">
            <a
              href="https://chromewebstore.google.com/detail/hereticschool-chrome-exte/odlfeaecpimnkgmeigjokbhemhbppage?hl=en-US"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block px-4 py-2 bg-gradient-to-r from-red-500 to-orange-500 text-white rounded-lg hover:from-red-600 hover:to-orange-600 transition-colors shadow-lg"
            >
              Install Extension
            </a>
          </div>
        </div>
        <button
          onClick={onDismiss}
          className="ml-4 text-gray-400 hover:text-gray-300"
        >
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

const FutureContent = () => (
  <div className="relative mt-8">
    <div className="absolute inset-0 bg-gradient-to-b from-transparent via-gray-900/50 to-gray-900 pointer-events-none" />
    <div className="space-y-4 opacity-30 blur-[2px] pointer-events-none">
      {[1, 2, 3].map((i) => (
        <div
          key={i}
          className="bg-gray-800/50 rounded-xl p-6 border border-gray-700/30"
        >
          <div className="h-4 w-2/3 bg-gray-700/50 rounded mb-4" />
          <div className="h-3 w-1/2 bg-gray-700/50 rounded" />
        </div>
      ))}
    </div>
    <div className="absolute inset-x-0 bottom-0 flex justify-center pb-8">
      <div className="text-gray-400/70 text-sm font-medium px-4 py-2 rounded-full bg-gray-800/50 border border-gray-700/30">
        Complete current projects to unlock more content
      </div>
    </div>
  </div>
);

const CategorySelectionModal = ({ onClose, onSelect, categories }) => {
  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-gray-900 p-8 rounded-xl shadow-2xl border border-purple-500/20 w-full max-w-4xl max-h-[80vh] overflow-hidden">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400">
            Select Category for Collaboration
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-300 p-2 rounded-lg hover:bg-gray-800/50 transition-colors"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 overflow-y-auto max-h-[60vh] pr-2 custom-scrollbar">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => onSelect(category)}
              className="p-4 bg-gray-800/80 backdrop-blur-sm rounded-lg border border-purple-500/20 
                hover:border-purple-500/40 hover:bg-gray-700/80 transition-all duration-300 
                text-left group hover:scale-[1.02] transform"
            >
              <h3
                className="text-lg font-semibold text-transparent bg-clip-text bg-gradient-to-r 
                from-purple-400 to-pink-400 mb-2 line-clamp-1"
              >
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </h3>
              <p className="text-sm text-gray-400 group-hover:text-gray-300 line-clamp-2">
                Start a collaborative learning journey in {category}
              </p>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

const FriendsModal = ({ onClose, onInvite, friends, user }) => {
  const [friendEmail, setFriendEmail] = useState("");
  const [friendRequests, setFriendRequests] = useState({
    incoming: [],
    outgoing: [],
    accepted: [],
  });
  const [showCategorySelection, setShowCategorySelection] = useState(false);
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const categories = [
    "entrepreneurship",
    "education",
    "history",
    "astronomy",
    "political science",
    "economics",
    "geography",
    "earth sciences",
    "mathematics",
    "literature",
    "computer science",
    "law",
    "physics",
    "engineering",
    "medicine",
    "communications",
    "chemistry",
    "cultural studies",
    "arts",
    "finance",
    "philosophy",
    "psychology",
    "biology",
    "urban planning",
    "architecture",
    "physical fitness",
  ];

  useEffect(() => {
    fetchFriendRequests();
  }, []);

  const fetchFriendRequests = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/get_friend_requests?user_id=${user.id}`
      );
      if (response.ok) {
        const data = await response.json();
        setFriendRequests(data);
      }
    } catch (error) {
      console.error("Error fetching friend requests:", error);
    }
  };

  const handleSendRequest = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/send_friend_request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: user.id,
            friend_email: friendEmail,
          }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || "Failed to send friend request");
      }

      setFriendEmail("");
      await fetchFriendRequests();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFriendRequest = async (requestId, action) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/handle_friend_request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            request_id: requestId,
            action: action,
          }),
        }
      );

      if (response.ok) {
        await fetchFriendRequests();
      }
    } catch (error) {
      console.error("Error handling friend request:", error);
    }
  };

  const handleCategorySelect = (category) => {
    onInvite(selectedFriends, category);
    setShowCategorySelection(false);
    onClose();
  };

  const handleInviteClick = (friendIds) => {
    setSelectedFriends(friendIds);
    setShowCategorySelection(true);
  };

  return (
    <>
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
        <div className="bg-gray-900 p-6 rounded-xl shadow-2xl border border-blue-500/20 w-full max-w-md">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold text-white">
              Friends & Collaboration
            </h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-300"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <form onSubmit={handleSendRequest} className="mb-6">
            <div className="flex gap-2">
              <input
                type="email"
                value={friendEmail}
                onChange={(e) => setFriendEmail(e.target.value)}
                placeholder="Friend's email"
                className="flex-1 px-4 py-2 bg-gray-800 text-white rounded-lg border border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              />
              <button
                type="submit"
                disabled={isLoading}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50"
              >
                {isLoading ? "Sending..." : "Add Friend"}
              </button>
            </div>
            {error && <p className="text-red-400 mt-2 text-sm">{error}</p>}
          </form>

          {friendRequests.incoming.length > 0 && (
            <div className="mb-6">
              <h3 className="text-lg font-semibold text-white mb-3">
                Incoming Requests
              </h3>
              <div className="space-y-2">
                {friendRequests.incoming.map((request) => (
                  <div
                    key={request.id}
                    className="flex items-center justify-between bg-gray-800 p-3 rounded-lg"
                  >
                    <span className="text-gray-300">{request.user_email}</span>
                    <div className="flex gap-2">
                      <button
                        onClick={() =>
                          handleFriendRequest(request.id, "accept")
                        }
                        className="px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600"
                      >
                        Accept
                      </button>
                      <button
                        onClick={() =>
                          handleFriendRequest(request.id, "reject")
                        }
                        className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {friendRequests.outgoing.length > 0 && (
            <div className="mb-6">
              <h3 className="text-lg font-semibold text-white mb-3">
                Pending Requests
              </h3>
              <div className="space-y-2">
                {friendRequests.outgoing.map((request) => (
                  <div
                    key={request.id}
                    className="flex items-center justify-between bg-gray-800 p-3 rounded-lg"
                  >
                    <span className="text-gray-300">
                      {request.friend_email}
                    </span>
                    <span className="text-yellow-500">Pending</span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {friendRequests.accepted.length > 0 && (
            <div>
              <h3 className="text-lg font-semibold text-white mb-3">Friends</h3>
              <div className="space-y-2">
                {friendRequests.accepted.map((friend) => {
                  const displayEmail =
                    friend.user_id === user.id
                      ? friend.friend_email
                      : friend.user_email;

                  const friendUserId =
                    friend.user_id === user.id
                      ? friend.friend_id
                      : friend.user_id;

                  return (
                    <div
                      key={friend.id}
                      className="flex items-center justify-between bg-gray-800/80 backdrop-blur-sm p-4 
                        rounded-lg border border-purple-500/10 hover:border-purple-500/20 transition-all duration-300"
                    >
                      <span className="text-gray-300 font-medium">
                        {displayEmail || "Unknown User"}
                      </span>
                      <button
                        onClick={() => handleInviteClick([friendUserId])}
                        className="bg-gradient-to-r from-purple-500 to-pink-500 text-white 
                          font-small rounded-lg hover:from-purple-600 hover:to-pink-600 transition-all 
                          duration-300 transform hover:scale-105 shadow-lg ml-3"
                      >
                        Start Collaborative Category
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>

      {showCategorySelection && (
        <CategorySelectionModal
          onClose={() => setShowCategorySelection(false)}
          onSelect={handleCategorySelect}
          categories={categories}
        />
      )}
    </>
  );
};

const SettingsModal = ({ isOpen, onClose, preferences, onSave, user }) => {
  const [feedbackType, setFeedbackType] = useState(preferences.feedbackType);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSave = async () => {
    try {
      setIsSubmitting(true); // Add loading state
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/update_preferences`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: user.id,
            preferences: { feedbackType },
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to update preferences");

      const data = await response.json();
      onSave(data.preferences);
      onClose();
    } catch (error) {
      console.error("Error updating preferences:", error);
      alert("Failed to update preferences. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 rounded-lg p-6 w-96">
        <h2 className="text-xl font-bold text-white mb-4">Settings</h2>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Feedback Preference
          </label>
          <div className="space-y-2">
            <label className="flex items-center">
              <input
                type="radio"
                value="feedback_only"
                checked={feedbackType === "feedback_only"}
                onChange={(e) => setFeedbackType(e.target.value)}
                className="mr-2"
              />
              <span className="text-gray-300">Feedback Only</span>
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                value="feedback_and_grades"
                checked={feedbackType === "feedback_and_grades"}
                onChange={(e) => setFeedbackType(e.target.value)}
                className="mr-2"
              />
              <span className="text-gray-300">Feedback + Grades</span>
            </label>
          </div>
        </div>

        <div className="flex justify-end gap-2">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

// Add UnitProgress component
const UnitProgress = ({ category, units, children, isFirstUnit = false }) => {
  const isCompleted = units.progress >= 100;
  const [isCollapsed, setIsCollapsed] = useState(!isFirstUnit && !isCompleted);

  useEffect(() => {
    setIsCollapsed(!isFirstUnit && !isCompleted);
  }, [isFirstUnit, isCompleted]);

  // Get category-specific styles
  const getCategoryStyles = () => {
    switch(category.toLowerCase()) {
      case 'entrepreneurship':
        return {
          gradient: 'from-amber-500/20 via-orange-400/20 to-orange-500/20',
          accent: 'text-amber-400',
          border: 'border-amber-500/20',
          progress: 'from-amber-500 to-orange-500'
        };
      case 'computer science':
        return {
          gradient: 'from-cyan-500/20 via-blue-400/20 to-blue-500/20',
          accent: 'text-cyan-400',
          border: 'border-cyan-500/20',
          progress: 'from-cyan-500 to-blue-500'
        };
      case 'economics':
        return {
          gradient: 'from-emerald-500/20 via-emerald-400/20 to-teal-500/20',
          accent: 'text-emerald-400',
          border: 'border-emerald-500/20',
          progress: 'from-emerald-500 to-teal-500'
        };
      default:
        return {
          gradient: 'from-purple-500/20 via-purple-400/20 to-pink-500/20',
          accent: 'text-purple-400',
          border: 'border-purple-500/20',
          progress: 'from-purple-500 to-pink-500'
        };
    }
  };

  const styles = getCategoryStyles();

  // The title now comes directly from the backend with proper formatting
  let title = units.current_unit || "Untitled Unit";
  // Remove "Unit X:" prefix if it exists
  if (title.match(/^Unit \d+:/)) {
    title = title.replace(/^Unit \d+:\s*/, "");
  }

  return (
    <div className="relative p-[1px] mb-8 group">
      {/* Background glow effect */}
      <div className={`absolute inset-0 bg-gradient-to-r ${styles.gradient} rounded-xl blur transition duration-500 group-hover:opacity-100 opacity-75`} />

      <div className="relative bg-[#1C1C2E]/90 backdrop-blur-xl rounded-xl border border-white/5 overflow-hidden">
        <div className="p-6">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-4 flex-1">
              <button
                onClick={() => setIsCollapsed(!isCollapsed)}
                className={`text-white/50 hover:text-white/80 transition-colors p-2 rounded-lg hover:bg-white/5 ${
                  isCollapsed ? '-rotate-90' : ''
                }`}
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              <div className="flex-1 relative p-[1px] rounded-lg group">
                <div className={`absolute inset-0 bg-gradient-to-r ${styles.gradient} rounded-lg blur-[2px] transition duration-500 group-hover:opacity-100 opacity-75`} />
                <div className="relative bg-white/5 rounded-lg px-4 py-2 border border-white/10">
                  <div className="flex items-center gap-3">
                    <div className={`w-8 h-8 rounded-lg flex items-center justify-center bg-white/5 ${styles.accent}`}>
                      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z" />
                      </svg>
                    </div>
                    <h3 className="text-xl font-medium text-white">
                      {title}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-2">
            <div className="flex items-center justify-between text-sm mb-1">
              <span className={styles.accent}>Progress</span>
              <span className="text-white/90 font-medium">{Math.round(units.progress || 0)}%</span>
            </div>
            <div className="relative h-2 bg-white/5 rounded-full overflow-hidden">
              <div
                className={`absolute inset-y-0 left-0 bg-gradient-to-r ${styles.progress} transition-all duration-500 ease-out rounded-full`}
                style={{ width: `${units.progress || 0}%` }}
              />
            </div>
          </div>
        </div>

        <div
          className={`transition-all duration-300 ${
            isCollapsed ? 'hidden' : 'block'
          }`}
        >
          <div className="px-6 pb-6">{children}</div>
        </div>
      </div>
    </div>
  );
};

const Home = ({ session, user }) => {
  const navigate = useNavigate();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [userInfo, setUserInfo] = useState({
    dreamjob: "Loading...",
    biggest_personal_interest: "Loading...",
  });
  const [content, setContent] = useState([]);
  const [backgroundColor] = useState("#111827"); // default dark background
  const [showExtensionPrompt, setShowExtensionPrompt] = useState(
    localStorage.getItem("extensionPromptDismissed") !== "true"
  );
  
  // Add an effect to check if setup is complete
  useEffect(() => {
    // Check if we have ACTUAL content in localStorage (not just empty arrays/objects)
    const hasNodes = localStorage.getItem("nodes") && JSON.parse(localStorage.getItem("nodes") || "[]").length > 0;
    const hasEdges = localStorage.getItem("edges") && JSON.parse(localStorage.getItem("edges") || "[]").length > 0;
    const hasUnits = localStorage.getItem("units_data") && Object.keys(JSON.parse(localStorage.getItem("units_data") || "{}")).length > 0;
    const hasCategories = localStorage.getItem("categories") && JSON.parse(localStorage.getItem("categories") || "[]").length > 0;
    
    const hasActualContent = hasNodes && hasEdges && hasUnits && hasCategories;
    
    // Clear any potentially corrupted or empty local storage data
    if ((localStorage.getItem("nodes") || localStorage.getItem("edges") || 
         localStorage.getItem("units_data") || localStorage.getItem("categories")) && 
        !hasActualContent) {
      console.log("Clearing empty or inconsistent local storage data");
      localStorage.removeItem("nodes");
      localStorage.removeItem("edges");
      localStorage.removeItem("units_data");
      localStorage.removeItem("categories");
      localStorage.removeItem("setupComplete");
    }
    
    // Check if setup is complete - if not, redirect to form
    if ((!hasActualContent || localStorage.getItem("setupComplete") !== "true") && user?.id) {
      // Check with the API to see if user has content
      const checkForContent = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/check_existing_data?userId=${user.id}&bypass_cache=true`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.id}`,
              },
            }
          );
          
          if (response.ok) {
            const data = await response.json();
            
            // If user has recommendations or selected categories, they should be able to stay on Home page
            if (data.hasRecommendations || (data.selectedCategories && data.selectedCategories.length > 0)) {
              // Update local storage to prevent future redirects
              localStorage.setItem("setupComplete", "true");
            } else {
              // User needs to complete setup - redirect to form
              console.log("No content found, redirecting to form");
              navigate("/form");
            }
          } else {
            // If API call fails, redirect to form
            console.log("API call failed, redirecting to form");
            navigate("/form");
          }
        } catch (error) {
          console.error("Error checking if setup is complete:", error);
          // If there's an error, redirect to form
          navigate("/form");
        }
      };
      
      checkForContent();
    }
  }, [user?.id, navigate, content.length]);

  const [isProfileCollapsed, setIsProfileCollapsed] = useState(false);
  const [relationships, setRelationships] = useState([]);
  const [isCollaborative, setIsCollaborative] = useState(false);
  const [collaborativeSession, setCollaborativeSession] = useState(null);
  const [showCollaborativeModal, setShowCollaborativeModal] = useState(false);
  const [friends, setFriends] = useState([]);
  const pollInterval = useRef(null);
  const [cachedData, setCachedData] = useState(null);
  const contentCache = useRef({});
  const [initialLoad, setInitialLoad] = useState(true);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [userPreferences, setUserPreferences] = useState({
    feedbackType: "feedback_only",
  });
  const [completionStatus, setCompletionStatus] = useState({});
  const [hideCompleted, setHideCompleted] = useState(false);
  const [units, setUnits] = useState({});
  const [contentUnits, setContentUnits] = useState([]);
  const [minimizedPlaceholders, setMinimizedPlaceholders] = useState(true);

  // Update fetchCompletionStatus to handle errors gracefully
  const fetchCompletionStatus = useCallback(
    async (contentIds) => {
      try {
        // Filter out any invalid IDs
        const validContentIds = contentIds.filter(
          (id) => id && typeof id === "string"
        );

        if (validContentIds.length === 0) return;

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/get_completion_status`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              content_ids: validContentIds,
              user_id: user.id,
            }),
          }
        );

        if (!response.ok) throw new Error("Failed to fetch completion status");

        const data = await response.json();
        if (data.completion_status) {
          setCompletionStatus(data.completion_status);
        }
      } catch (error) {
        console.error("Error fetching completion status:", error);
        // Set empty completion status on error to avoid showing incorrect states
        setCompletionStatus({});
      }
    },
    [user.id]
  );

  // Update useEffect to handle content changes more robustly
  useEffect(() => {
    if (content && content.length > 0) {
      const contentIds = content
        .filter((item) => item.id) // Filter out items without IDs
        .map((item) => item.id);
      if (contentIds.length > 0) {
        fetchCompletionStatus(contentIds);
      }
    }
  }, [content, fetchCompletionStatus]);

  // 1. First define fetchUserInfo
  const fetchUserInfo = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/check_existing_data?userId=${user.id}`
      );
      if (response.ok) {
        const data = await response.json();
        console.log("Received user data:", data);

        if (data.exists && data.submission) {
          // Check if the values exist and are not empty
          const dreamjob = data.submission.dreamjob;
          const biggestInterest = data.submission.biggest_personal_interest;

          setUserInfo({
            dreamjob: dreamjob || "Not set",
            biggest_personal_interest: biggestInterest || "Not set",
          });

          if (
            data.selectedCategories &&
            Array.isArray(data.selectedCategories)
          ) {
            setSelectedCategories(data.selectedCategories);
          }
        } else {
          setUserInfo({
            dreamjob: "Not set",
            biggest_personal_interest: "Not set",
          });
        }
      } else {
        throw new Error("Failed to fetch user info");
      }
    } catch (error) {
      console.error("Error fetching user info:", error);
      setUserInfo({
        dreamjob: "Error loading",
        biggest_personal_interest: "Error loading",
      });
    }
  }, [user?.id]);

  // 2. Define refreshCache first (but don't implement its body yet)
  const refreshCache = useCallback(async () => {}, []); // Temporary empty implementation

  // 3. Define handleCategoryClick
  const handleCategoryClick = useCallback(
    (category, collaborative) => {
      if (!cachedData || !category) {
        return;
      }

      console.log(`Switching to category: ${category}`);

      // IMPORTANT: Clear all state when switching categories to prevent data bleeding
      setContentUnits([]);
      setContent([]);
      setRelationships([]);

      // Check if we have a cached result for this category
      const cacheKey = `${category}-${collaborative}`;
      if (contentCache.current[cacheKey]) {
        console.log(`Using cached data for ${category}`);
        setActiveCategory(category);
        setContent(contentCache.current[cacheKey].nodes);
        setRelationships(contentCache.current[cacheKey].edges);
        setCollaborativeSession(contentCache.current[cacheKey].session);
        setContentUnits(contentCache.current[cacheKey].units);
        setIsCollaborative(collaborative);
        return;
      }

      let filteredNodes = [];
      let filteredEdges = [];
      let categoryUnits = [];

      if (collaborative) {
        // Handle collaborative sessions
        const categoryWithoutPrefix = category.replace("collaborative ", "");
        const session = cachedData.collaborative_sessions.find(
          (s) =>
            s.category.toLowerCase() === categoryWithoutPrefix.toLowerCase()
        );

        if (session?.content) {
          try {
            const content =
              typeof session.content === "string"
                ? JSON.parse(session.content)
                : session.content;

            filteredNodes = content.nodes || [];
            filteredEdges = content.edges || [];

            setCollaborativeSession(session);
          } catch (e) {
            console.error("Error parsing collaborative content:", e);
          }
        }
      } else {
        // Filter units by category
        categoryUnits = cachedData.units.filter(
          (unit) => unit.data.category.toLowerCase() === category.toLowerCase()
        );

        console.log(
          `Found ${categoryUnits.length} units for category "${category}"`
        );

        // Sort units by order
        categoryUnits.sort((a, b) => {
          const orderA = a.data.metadata?.order || 0;
          const orderB = b.data.metadata?.order || 0;
          return orderA - orderB;
        });

        // NEW APPROACH: Use the edges array to find content nodes related to units

        // 1. Get all unit IDs that match our category
        const unitIds = categoryUnits.map(unit => unit.id);
        
        // 2. Find all edges where the source is one of our unit IDs
        filteredEdges = cachedData.edges.filter(
          edge => unitIds.includes(edge.source)
        );
        
        // 3. Get all content node IDs (the targets of the filtered edges)
        const contentNodeIds = filteredEdges.map(edge => edge.target);
        
        // 4. Find the actual content nodes in the main nodes array
        const contentNodes = cachedData.nodes.filter(
          node => contentNodeIds.includes(node.id)
        );
        
        // 5. Combine unit nodes with content nodes for the final filtered nodes
        filteredNodes = [
          ...categoryUnits,
          ...contentNodes
        ];

        // 6. Create a map of unitId -> content nodes
        const unitContentMap = {};
        filteredEdges.forEach(edge => {
          if (unitIds.includes(edge.source)) {
            // This edge connects a unit to a content node
            if (!unitContentMap[edge.source]) {
              unitContentMap[edge.source] = [];
            }
            // Find the content node in our filtered nodes
            const contentNode = contentNodes.find(node => node.id === edge.target);
            if (contentNode) {
              unitContentMap[edge.source].push(contentNode);
            }
          }
        });

        // 7. Add content to each unit
        categoryUnits = categoryUnits.map(unit => {
          return {
            ...unit,
            content: unitContentMap[unit.id] || [],
            expanded: true  // Default to expanded
          };
        });

        console.log(`Total filtered nodes: ${filteredNodes.length}`);
        console.log(`Total filtered edges: ${filteredEdges.length}`);
        console.log(`Units with content: ${categoryUnits.filter(u => u.content && u.content.length > 0).length}/${categoryUnits.length}`);
      }

      // Update state
      setActiveCategory(category);
      setIsCollaborative(collaborative);
      setContent(filteredNodes);
      setRelationships(filteredEdges);
      setContentUnits(categoryUnits);

      // Cache the filtered results
      contentCache.current[cacheKey] = {
        nodes: filteredNodes,
        edges: filteredEdges,
        units: categoryUnits,
        session: collaborative
          ? cachedData.collaborative_sessions?.find(
              (s) =>
                s.category.toLowerCase() ===
                category.replace("collaborative ", "").toLowerCase()
            )
          : null,
      };
    },
    [cachedData]
  );

  // 4. Define fetchData
  const fetchData = useCallback(
    async (forceRefresh = false) => {
      setIsLoading(true); // Ensure loading is set at the start
      try {
        // Don't use cached data on force refresh or if cache is empty
        if (cachedData && !forceRefresh) {
          console.log("Using cached data:", cachedData);
          // Ensure loading is false if using cache
          setIsLoading(false);
          return cachedData;
        }

        console.log(`Fetching project tree for userId: ${user.id}`);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/fetch_project_tree?userId=${user.id}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Raw fetched data:", data);

        // Validate core data structures
        if (!data || !Array.isArray(data.nodes) || !Array.isArray(data.edges) || !Array.isArray(data.units) || !Array.isArray(data.categories)) {
          console.error("Invalid data structure received from backend:", data);
          throw new Error("Invalid data structure received from backend.");
        }


        // --- START OF FIX ---
        // Directly use nodes, edges, and categories from the backend response
        const backendNodes = data.nodes || [];
        const backendEdges = data.edges || [];
        const backendCategories = data.categories || [];
        const backendUnits = data.units || []; // Still need units for rendering logic

        // Process units slightly to ensure they have category info if needed elsewhere,
        // but primary data comes directly from backend response.
        const processedUnits = backendUnits.map((unit) => {
          const displayId = unit.data?.display_unit_id;
          const categoryFromDisplayId = displayId ? displayId.split("-")[0].toLowerCase() : null;
          // Fallback to checking node data if display_unit_id is missing/malformed
          const categoryFromNodeData = unit.data?.category?.toLowerCase();
          const category = categoryFromDisplayId || categoryFromNodeData || "uncategorized";

          // Ensure content within units also has category info
          const processedContent = (unit.content || []).map(content => ({
            ...content,
            data: {
              ...content.data,
              category: content.data?.category?.toLowerCase() || category, // Ensure content gets category
            },
          }));

          return {
            ...unit,
            data: {
              ...unit.data,
              category: category,
              type: unit.data?.type || "unit", // Ensure type is set
            },
            content: processedContent,
          };
        });

        // Add type='unit' to unit nodes within the main backendNodes array if not present
        const finalNodes = backendNodes.map(node => {
          const isUnit = backendUnits.some(u => u.id === node.id);
          return {
            ...node,
            data: {
              ...node.data,
              // Ensure type is set, prioritize unit type if it matches a unit ID
              type: isUnit ? 'unit' : (node.data?.type || 'unknown'),
              // Ensure category is present in node data
              category: node.data?.category?.toLowerCase() || processedUnits.find(u => u.id === node.id)?.data?.category || 'uncategorized',
            }
          };
        });


        const processedData = {
          nodes: finalNodes, // Use nodes directly from backend
          edges: backendEdges, // Use edges directly from backend
          units: processedUnits, // Use processed units (contains nested content with categories)
          categories: backendCategories, // Use categories directly from backend
          collaborative_sessions: data.collaborative_sessions || [],
        };
        // --- END OF FIX ---


        console.log("Corrected Processed data structure:");
        console.log("- Nodes:", processedData.nodes.length);
        console.log("- Edges:", processedData.edges.length);
        console.log("- Units:", processedData.units.length); // Still reflects original unit count
        console.log("- Categories:", processedData.categories);


        setCachedData(processedData);


        // Set categories and default category on initial load
        // Use the categories directly from the backend response now
        if (processedData.categories?.length) {
          const uniqueCategories = [...new Set(processedData.categories)];
          setSelectedCategories(uniqueCategories);


          // If activeCategory isn't set OR isn't in the new list, set a default
          if (!activeCategory || !uniqueCategories.includes(activeCategory)) {
            const defaultCategory = uniqueCategories[0];
            console.log(`Setting default active category to: ${defaultCategory}`);
            // No need to determine if collaborative here, handleCategoryClick does that
            // Schedule the category click slightly after state updates settle
            setTimeout(() => handleCategoryClick(defaultCategory, defaultCategory.startsWith("collaborative ")), 0);
          } else {
             // If active category is already set and valid, refresh its view
             console.log(`Refreshing active category: ${activeCategory}`);
             setTimeout(() => handleCategoryClick(activeCategory, activeCategory.startsWith("collaborative ")), 0);
          }
        } else {
            // No categories available
            console.log("No categories available after fetch.");
            setActiveCategory(null);
            setContent([]);
            setRelationships([]);
            setContentUnits([]);
        }


        return processedData;
      } catch (error) {
        console.error("Error fetching data:", error);
        setHasError(true); // Indicate an error occurred
        // Don't return potentially stale cached data on error
        // Let the component render an error state
      } finally {
        setInitialLoad(false);
        setIsLoading(false); // Ensure loading is false at the end
      }
    },
    [user?.id, activeCategory, cachedData, handleCategoryClick] // Include handleCategoryClick dependency
  );

  // 5. Now implement refreshCache properly
  // Replace the empty implementation with the real one
  Object.assign(refreshCache, {
    current: async () => {
      try {
        contentCache.current = {}; // Clear the content cache
        const newData = await fetchData(true);
        if (activeCategory) {
          handleCategoryClick(activeCategory, isCollaborative);
        }
      } catch (error) {
        console.error("Error refreshing cache:", error);
        if (!cachedData) {
          setHasError(true);
        }
      }
    },
  });

  // 6. Define useEffect
  useEffect(() => {
    if (user?.id) {
      setIsLoading(true); // Set loading state at start
      Promise.all([fetchUserInfo(), fetchData()])
        .then(([_, data]) => {
          if (data && !activeCategory && data.categories?.length > 0) {
            const defaultCategory = data.categories[0];
            const isCollaborativeCategory =
              defaultCategory.startsWith("collaborative ");
            handleCategoryClick(defaultCategory, isCollaborativeCategory);
          }
        })
        .catch((error) => {
          console.error("Error loading data:", error);
          if (!cachedData) {
            setHasError(true);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [
    user?.id,
    fetchUserInfo,
    fetchData,
    activeCategory,
    handleCategoryClick,
    cachedData,
  ]);

  // 7. Define handleReroll
  const handleReroll = async (contentId, rejectReason) => {
    try {
      const rejectedContent = content.find(
        (item) => item.id === contentId
      )?.data;

      if (!rejectedContent) {
        console.error("Could not find content to reroll");
        setHasError(true);
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/reroll_content`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.id}`,
          },
          body: JSON.stringify({
            contentId: contentId,
            rejectReason: rejectReason,
            userId: user.id,
            rejectedContent: rejectedContent,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Get the new content from the response
      const newContentData = await response.json();

      // Update the content in state
      setContent((prevContent) =>
        prevContent.map((item) =>
          item.id === contentId
            ? { ...item, data: newContentData.newContent }
            : item
        )
      );

      // Clear content cache for this category to force fresh data on next category switch
      const cacheKey = `${activeCategory}-${isCollaborative}`;
      if (contentCache.current[cacheKey]) {
        delete contentCache.current[cacheKey];
      }

      // Refresh the cache in the background
      await refreshCache.current();

      // Trigger a re-fetch of the current category
      handleCategoryClick(activeCategory, isCollaborative);
    } catch (error) {
      console.error("Error rerolling content:", error);
      setHasError(true);
    }
  };

  const handleDismissExtensionPrompt = () => {
    localStorage.setItem("extensionPromptDismissed", "true");
    setShowExtensionPrompt(false);
  };

  const handleNewContent = async (newContent) => {
    if (!newContent || !Array.isArray(newContent)) return;

    // Get the most recent node to use as parent
    const parentNode = content[content.length - 1];
    if (!parentNode) return;

    // Add new content to nodes with proper timestamps
    const timestamp = Date.now();
    const newNodes = newContent.map((content, index) => ({
      id: `${timestamp}-rec-${index}`,
      type: "customNode",
      data: {
        label: content.title,
        description: content.description,
        url: content.url,
        category: content.category,
      },
    }));

    // Create edges from parent to new nodes
    const newEdges = newNodes.map((node) => ({
      id: `e${parentNode.id}-${node.id}`,
      source: parentNode.id,
      target: node.id,
    }));

    setContent((prevContent) => [...prevContent, ...newNodes]);
    setRelationships((prevRelationships) => [
      ...prevRelationships,
      ...newEdges,
    ]);

    // Refresh the project tree to ensure everything is in sync
    if (activeCategory) {
      await refreshCache();
    }

    await refreshCache(); // Add refreshCache here
  };

  const sortContent = (items) => {
    // Create a map of parent to children relationships
    const parentChildMap = {};
    relationships.forEach((edge) => {
      if (!parentChildMap[edge.source]) {
        parentChildMap[edge.source] = [];
      }
      parentChildMap[edge.source].push(edge.target);
    });

    // Function to get creation time from ID
    const getTimestamp = (id) => {
      const match = id.match(/^\d+/);
      return match ? parseInt(match[0]) : 0;
    };

    // Sort items based on creation time and type
    return [...items].sort((a, b) => {
      // Always put central node first
      if (a.type === "centralNode") return -1;
      if (b.type === "centralNode") return 1;

      // Get timestamps from IDs
      const timeA = getTimestamp(a.id);
      const timeB = getTimestamp(b.id);

      // Sort by timestamp first
      if (timeA !== timeB) return timeA - timeB;

      // If timestamps are equal, put recommendations before projects
      if (a.type === "customNode" && b.type === "projectNode") return -1;
      if (a.type === "projectNode" && b.type === "customNode") return 1;

      return 0;
    });
  };

  // Modify the content rendering section in Home component
  const renderContent = () => {
    // If we have unit-structured content, display that
    if (contentUnits && contentUnits.length > 0) {
      // Sort units to ensure consistent ordering
      const sortedUnits = [...contentUnits].sort((a, b) => {
        // Sort by category first
        if (a.data.category !== b.data.category) {
          return a.data.category.localeCompare(b.data.category);
        }
        // Then by order within category
        return (a.data.metadata?.order || 0) - (b.data.metadata?.order || 0);
      });

      // Track which categories we've seen to identify first unit in each category
      const seenCategories = new Set();
      // Track if we've found the next item to work on
      let foundNextItem = false;

      // For debugging, log all the categories and if they're first
      console.log("Units by category:");
      sortedUnits.forEach((unit) => {
        const category = unit.data.category;
        const isFirst = !seenCategories.has(category);
        console.log(
          `- ${category}: ${unit.data.label} (${
            isFirst ? "FIRST" : "not first"
          })`
        );
        if (isFirst) seenCategories.add(category);
      });

      // Reset the set for the actual rendering
      seenCategories.clear();

      return (
        <div className="space-y-8">
          {sortedUnits.map((unit, index) => {
            // Use the category-specific unit number from the backend
            let unitDisplay = "";

            if (unit.data.display_unit_id) {
              if (unit.data.display_unit_id.startsWith("ww2_")) {
                // For WWII units, use a more descriptive prefix
                const section = unit.data.display_unit_id.replace("ww2_", "");
                // Capitalize first letter and format
                unitDisplay =
                  "WWII " + section.charAt(0).toUpperCase() + section.slice(1);
              } else if (unit.data.category_unit_number) {
                // For regular units, use the category-specific number
                unitDisplay = unit.data.category_unit_number.toString();
              }
            }

            // Check if this is the first unit in its category
            const category = unit.data.category?.toLowerCase(); // Normalize category
            const isFirstInCategory = !seenCategories.has(category);

            // Mark this category as seen
            if (isFirstInCategory && category) {
              seenCategories.add(category);
            }

            // Only expand if it's the first unit in its category or if it's completed
            const isCompleted = completionStatus?.[category]?.percentage >= 100;
            const defaultExpanded = isFirstInCategory || isCompleted;

            // If expanded isn't explicitly set, use our default logic
            const isExpanded =
              unit.expanded !== undefined ? unit.expanded : defaultExpanded;

            // Determine if this unit should be marked as the next one to work on
            // It should be the first incomplete unit we encounter
            const shouldBeNext = !foundNextItem && !isCompleted;
            if (shouldBeNext) {
              foundNextItem = true;
            }

            return (
              <div
                key={unit.id}
                className="bg-gray-800/80 rounded-xl p-6 shadow-lg border border-indigo-500/20"
              >
                <div
                  className="flex justify-between items-center cursor-pointer mb-4"
                  onClick={() => {
                    // Toggle unit expansion
                    const updatedUnits = contentUnits.map((u) =>
                      u.id === unit.id ? { ...u, expanded: !u.expanded } : u
                    );
                    setContentUnits(updatedUnits);
                  }}
                >
                  <div className="flex items-center gap-4 flex-1">
                    <div className="bg-gradient-to-r from-blue-900/40 to-indigo-900/40 rounded-lg px-4 py-2 border border-blue-500/30 flex-1">
                      <h3 className="text-xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-blue-300 to-indigo-300">
                        {unit.data.label}
                      </h3>
                    </div>
                    <svg
                      className={`w-5 h-5 text-gray-400 transform transition-transform ${
                        isExpanded ? "rotate-180" : ""
                      }`}
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>

                {/* Progress bar */}
                <div className="w-full bg-gray-700 rounded-full h-2.5 mb-4">
                  <div
                    className="bg-blue-500 h-2.5 rounded-full transition-all duration-500 ease-in-out"
                    style={{
                      width: `${
                        completionStatus?.[category]?.percentage || 0
                      }%`,
                    }}
                  />
                </div>

                {isExpanded && (
                  <div className="space-y-4 mt-4">
                    {unit.content && unit.content.length > 0 ? (
                      unit.content.map((contentNode, contentIndex) => {
                        // Find the first incomplete content item in this unit
                        let isNextContentItem = false;
                        if (shouldBeNext && contentIndex === 0) {
                          isNextContentItem = true;
                        }

                        // Check if this is a project node
                        if (
                          contentNode.type === "projectNode" &&
                          contentNode.data.project
                        ) {
                          return (
                            <ProjectBox
                              key={contentNode.id}
                              data={{
                                ...contentNode.data.project,
                                has_project_submission:
                                  contentNode.data.has_project_submission,
                              }}
                              onReroll={handleReroll}
                              defaultExpanded={false}
                              user={user}
                              activeCategory={activeCategory}
                              onNewContent={handleNewContent}
                              refreshCache={refreshCache}
                              nodes={content}
                              edges={relationships}
                              isNext={
                                isNextContentItem &&
                                !contentNode.data.has_project_submission
                              }
                            />
                          );
                        }

                        // Otherwise render as a content node
                        return (
                          <React.Fragment key={contentNode.id}>
                            <ContentBox
                              content={{
                                id: contentNode.id,
                                title: contentNode.data.label,
                                description: contentNode.data.description,
                                url: contentNode.data.url,
                                category: contentNode.data.category,
                                type: contentNode.data.type,
                                estimated_time: contentNode.data.estimated_time,
                                is_completed: contentNode.data.is_completed,
                              }}
                              onReroll={handleReroll}
                              user={user}
                              defaultExpanded={false}
                              isNext={
                                isNextContentItem &&
                                !contentNode.data.is_completed
                              }
                            />
                            {/* Add project placeholder if this content item doesn't already have a project */}
                            {!contentNode.data.has_project && (
                              <ProjectPlaceholder />
                            )}
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <p className="text-gray-400 italic">
                        No content available in this unit
                      </p>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      );
    }

    // If no units available, fall back to the old flat structure
    if (!content || content.length === 0) {
      return (
        <div className="text-center p-8 bg-gray-800 rounded-xl">
          <p className="text-gray-300">
            No content available for this category yet.
          </p>
        </div>
      );
    }

    // Group content by unit
    const contentByUnit = {};
    content.forEach((item) => {
      const category =
        item?.data?.category?.toLowerCase() ||
        item?.data?.project?.category?.toLowerCase();
      if (!category) return;

      // For now, group everything in one unit per category
      // You can modify this logic to group by actual units when that data is available
      const unitKey = `${category}-unit-1`;
      if (!contentByUnit[unitKey]) {
        contentByUnit[unitKey] = {
          items: [],
          category: category,
          unit: units?.[category] || {
            current_unit: "Untitled Unit",
            progress: 0,
            completed_submissions: 0,
            total_required: 0,
          },
        };
      }
      contentByUnit[unitKey].items.push(item);
      contentByUnit[unitKey].unit.total_required =
        contentByUnit[unitKey].items.length;
    });

    // Track which categories we've seen to only expand first unit in category
    const seenCategories = new Set();

    // First, sort the units by category name for consistent ordering
    const sortedUnitEntries = Object.entries(contentByUnit).sort((a, b) =>
      a[1].category.localeCompare(b[1].category)
    );

    // For debugging
    console.log("Flat structure units by category:");
    sortedUnitEntries.forEach(([unitKey, { category }]) => {
      const isFirst = !seenCategories.has(category);
      console.log(
        `- ${category}: ${unitKey} (${isFirst ? "FIRST" : "not first"})`
      );
      if (isFirst) seenCategories.add(category);
    });

    // Reset for actual rendering
    seenCategories.clear();

    // Track if we've found the next item to work on across all units
    let foundNextItemGlobal = false;

    return sortedUnitEntries.map(([unitKey, { items, category, unit }]) => {
      const sortedItems = sortContent(items);

      // Check if this is the first unit in its category
      const isFirstInCategory = !seenCategories.has(category);
      if (isFirstInCategory) {
        seenCategories.add(category);
      }

      // Track if we've found the next item within this unit
      let foundNextItemInUnit = false;

      return (
        <UnitProgress
          key={unitKey}
          category={category}
          units={unit}
          isFirstUnit={isFirstInCategory}
        >
          <div className="space-y-4">
            {sortedItems.map((item) => {
              if (!item?.data) return null;

              // Determine if this is the next item to work on
              const isCompleted = item.data?.is_completed || false;
              const isNextItem = !foundNextItemGlobal && !isCompleted;

              if (isNextItem) {
                foundNextItemGlobal = true;
                foundNextItemInUnit = true;
              }

              if (item.type === "customNode") {
                return (
                  <React.Fragment key={item.id}>
                    <ContentBox
                      content={{
                        id: item.id,
                        title: item.data.label,
                        description: item.data.description,
                        url: item.data.url,
                        category: item.data.category,
                        is_completed: item.data.is_completed,
                      }}
                      onReroll={handleReroll}
                      user={user}
                      defaultExpanded={false}
                      isNext={isNextItem && !item.data.is_completed}
                    />
                    {/* Add placeholder for future project if no project exists yet */}
                    {!item.data.has_project && <ProjectPlaceholder />}
                  </React.Fragment>
                );
              } else if (item.type === "projectNode" && item.data.project) {
                return (
                  <ProjectBox
                    key={item.id}
                    data={{
                      ...item.data.project,
                      has_project_submission: item.data.has_project_submission,
                    }}
                    onReroll={handleReroll}
                    defaultExpanded={false}
                    user={user}
                    activeCategory={activeCategory}
                    onNewContent={handleNewContent}
                    refreshCache={refreshCache}
                    nodes={content}
                    edges={relationships}
                    isNext={isNextItem && !item.data.has_project_submission}
                  />
                );
              } else if (item.type === "centralNode") {
                return (
                  <div
                    key={item.id}
                    className="bg-gray-800/40 rounded-lg p-4 border border-purple-500/20"
                  >
                    <h3 className="text-lg font-semibold text-purple-300">
                      {item.data.label}
                    </h3>
                    <p className="text-gray-300 mt-2">
                      {item.data.description}
                    </p>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </UnitProgress>
      );
    });

    // This code is only used as a fallback if the above code doesn't return anything
    // It should never actually run if contentByUnit has entries
    if (Object.keys(contentByUnit).length === 0) {
      const items = sortContent(content);

      // Find the first incomplete item to mark as "next"
      let foundNextItem = false;

      return (
        <div className="space-y-8">
          {items.map((item) => {
            // Determine if this is the next item to work on
            const isCompleted = item.data?.is_completed || false;
            const isNextItem = !foundNextItem && !isCompleted;

            if (isNextItem) {
              foundNextItem = true;
            }

            if (item.type === "projectNode" && item.data.project) {
              return (
                <ProjectBox
                  key={item.id}
                  data={{
                    ...item.data.project,
                    has_project_submission: item.data.has_project_submission,
                  }}
                  onReroll={handleReroll}
                  defaultExpanded={false}
                  user={user}
                  activeCategory={activeCategory}
                  onNewContent={handleNewContent}
                  refreshCache={refreshCache}
                  nodes={content}
                  edges={relationships}
                  isNext={isNextItem && !item.data.has_project_submission}
                />
              );
            }

            return (
              <React.Fragment key={item.id}>
                <ContentBox
                  content={{
                    id: item.id,
                    title: item.data.label,
                    description: item.data.description,
                    url: item.data.url,
                    category: item.data.category,
                    type: item.data.type,
                    estimated_time: item.data.estimated_time,
                    is_completed: item.data.is_completed,
                  }}
                  onReroll={handleReroll}
                  user={user}
                  defaultExpanded={false}
                  isNext={isNextItem && !item.data.is_completed}
                />
                {/* Add placeholder for future project if no project exists yet */}
                {!item.data.has_project && <ProjectPlaceholder />}
              </React.Fragment>
            );
          })}
        </div>
      );
    }
  };

  const handleStartCollaborative = async (invitedFriends, category) => {
    try {
      if (!category) {
        console.error("No category selected");
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/start_collaborative_session`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            user_id: user.id,
            category: category,
            invited: invitedFriends,
          }),
        }
      );

      if (response.ok) {
        const session = await response.json();
        setCollaborativeSession(session);
        setIsCollaborative(true);
        setShowCollaborativeModal(false);
        setActiveCategory(category);
        await refreshCache.current();
      }
    } catch (error) {
      console.error("Error starting collaborative session:", error);
    }
  };

  // Add to useEffect in Home component
  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/check_existing_data?userId=${user.id}`
        );
        const data = await response.json();
        if (data.submission?.user_preferences) {
          setUserPreferences(JSON.parse(data.submission.user_preferences));
        }
      } catch (error) {
        console.error("Error fetching preferences:", error);
      }
    };

    if (user?.id) {
      fetchPreferences();
    }
  }, [user?.id]);

  // Add to existing useEffect that fetches completion status
  useEffect(() => {
    const fetchData = async () => {
      if (!user?.id || !content.length) return;

      try {
        const contentIds = content.map((item) => item.id);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/get_completion_status`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId: user.id,
              contentIds,
            }),
          }
        );
        const data = await response.json();
        setCompletionStatus(data.completionStatus || {});
      } catch (error) {
        console.error("Error fetching completion status:", error);
      }
    };

    // Debounce the fetch operation to avoid too many requests
    const timer = setTimeout(() => {
      fetchData();
    }, 200);

    return () => clearTimeout(timer);
  }, [user?.id, content]);

  // Add a useEffect to initialize unit expansion states when contentUnits change
  const initializedRef = useRef(false);

  useEffect(() => {
    // Skip if we've already initialized or there are no content units
    if (initializedRef.current || !contentUnits || contentUnits.length === 0)
      return;

    // Track which categories we've seen to identify first unit in each category
    const seenCategories = new Set();

    // Update unit expanded states
    const updatedUnits = contentUnits.map((unit) => {
      const category = unit.data.category?.toLowerCase();
      const isFirstInCategory = !seenCategories.has(category);
      const isCompleted = completionStatus?.[category]?.percentage >= 100;

      if (isFirstInCategory && category) {
        seenCategories.add(category);
      }

      // Set expanded state based on first-in-category or completion status
      return {
        ...unit,
        expanded: isFirstInCategory || isCompleted,
      };
    });

    // Update the contentUnits state with proper expansion
    setContentUnits(updatedUnits);
    initializedRef.current = true;
  }, [activeCategory, contentUnits, completionStatus]);

  // Reset the initialization flag when the active category changes
  useEffect(() => {
    initializedRef.current = false;
  }, [activeCategory]);

  if (isLoading || (initialLoad && !cachedData)) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-500" />
          <p className="mt-4 text-gray-300">
            Loading your personalized content...
          </p>
        </div>
      </div>
    );
  }

  if (hasError) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="text-center p-8 bg-gray-800 rounded-xl shadow-2xl border border-red-500/20">
          <h2 className="text-2xl text-red-400 mb-4">
            Oops! Something went wrong.
          </h2>
          <p className="text-gray-300 mb-6">
            We're having trouble loading your content.
          </p>
          <button
            onClick={() => window.location.reload()}
            className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  if (!selectedCategories || selectedCategories.length === 0) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="text-center p-8 bg-gray-800 rounded-xl shadow-2xl border border-yellow-500/20">
          <h2 className="text-2xl text-yellow-400 mb-4">
            No Categories Selected
          </h2>
          <p className="text-gray-300 mb-6">
            Please select your learning categories to get started.
          </p>
          <button
            onClick={() => {
              // Clear the setupComplete flag to prevent redirection loop
              localStorage.removeItem("setupComplete");
              navigate("/form");
            }}
            className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            Select Categories
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen relative overflow-hidden bg-[#0F0F1A]">
      {/* Rich background with layered effects */}
      <div className="fixed inset-0 z-0">
        {/* Subtle grid pattern */}
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:14px_24px]" />
        
        {/* Animated gradient background */}
        <div className="absolute inset-0 bg-gradient-to-br from-indigo-950 via-[#0F0F1A] to-purple-950">
          <div className="absolute top-0 left-0 right-0 h-[500px] bg-gradient-to-b from-blue-900/20 to-transparent" />
          <div className="absolute bottom-0 left-0 right-0 h-[500px] bg-gradient-to-t from-purple-900/20 to-transparent" />
        </div>
        
        {/* Floating particles/orbs effect */}
        <div className="absolute top-20 left-20 w-[300px] h-[300px] rounded-full bg-blue-600/5 blur-3xl animate-float" />
        <div className="absolute top-40 right-20 w-[250px] h-[250px] rounded-full bg-purple-600/5 blur-3xl animate-float-delayed" />
        <div className="absolute bottom-20 left-1/3 w-[200px] h-[200px] rounded-full bg-cyan-600/5 blur-3xl animate-float-slow" />
      </div>

      <Header />

      <div className="relative z-10 max-w-7xl mx-auto px-4 py-8 space-y-6">
        {/* Learner Settings Card */}
        <div className="relative group">
          <div className="absolute -inset-[1px] bg-gradient-to-r from-purple-500/20 via-blue-500/20 to-cyan-500/20 rounded-xl blur transition duration-500 group-hover:opacity-100 opacity-75" />
          <div className="relative bg-[#1C1C2E]/80 backdrop-blur-xl rounded-xl p-6 border border-white/5">
            <div className="flex items-start justify-between mb-4">
              <div className="flex items-center gap-2">
                <h2 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white via-blue-100 to-white">
                  Learner Settings
                </h2>
                <button
                  onClick={() => setIsProfileCollapsed(!isProfileCollapsed)}
                  className="text-blue-300/80 hover:text-blue-200 transition-colors p-2 rounded-lg hover:bg-white/5"
                >
                  <svg
                    className={`w-5 h-5 transform transition-transform ${
                      isProfileCollapsed ? "rotate-180" : ""
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex gap-4">
                <button
                  onClick={() => setHideCompleted(!hideCompleted)}
                  className={`px-4 py-2 font-medium rounded-md transition-all duration-300 ${
                    hideCompleted
                      ? "bg-emerald-500/90 text-white hover:bg-emerald-600/90 shadow-lg shadow-emerald-500/20"
                      : "bg-white/5 text-blue-100 hover:bg-white/10 border border-white/10"
                  }`}
                >
                  {hideCompleted ? "Show Completed" : "Hide Completed"}
                </button>
                <button
                  onClick={() => setShowUpdatePopup(true)}
                  className="px-4 py-2 bg-gradient-to-r from-blue-500/90 to-purple-500/90 hover:from-blue-600/90 hover:to-purple-600/90 text-white font-medium rounded-md transition-all duration-300 shadow-lg shadow-blue-500/20"
                >
                  Update Profile
                </button>
                <button
                  onClick={() => setShowCollaborativeModal(true)}
                  className="px-6 py-2 bg-gradient-to-r from-purple-500/90 to-pink-500/90 hover:from-purple-600/90 hover:to-pink-600/90 text-white font-medium rounded-md transition-all duration-300 shadow-lg shadow-purple-500/20"
                >
                  Friends & Collaboration
                </button>
                <button
                  onClick={() => setShowSettingsModal(true)}
                  className="px-4 py-2 bg-white/5 text-blue-100 rounded-md hover:bg-white/10 transition-all duration-300 border border-white/10"
                >
                  Settings
                </button>
              </div>
            </div>

            {!isProfileCollapsed && (
              <div className="space-y-3 animate-fadeIn">
                <div className="flex items-center gap-2 p-3 rounded-lg bg-white/5 border border-white/10">
                  <span className="text-blue-200/80 font-medium min-w-[120px]">
                    Dream Job:
                  </span>
                  <span className="text-white/90">
                    {userInfo?.dreamjob || "Loading..."}
                  </span>
                </div>
                <div className="flex items-center gap-2 p-3 rounded-lg bg-white/5 border border-white/10">
                  <span className="text-blue-200/80 font-medium min-w-[120px]">
                    Biggest Interest:
                  </span>
                  <span className="text-white/90">
                    {userInfo?.biggest_personal_interest || "Loading..."}
                  </span>
                </div>
              </div>
            )}

            {isProfileCollapsed && (
              <div className="space-y-3">
                <div className="flex items-center gap-2">
                  <span className="text-blue-200/80 font-medium min-w-[120px]">
                    Dream Job:
                  </span>
                  <span className="text-white/90">
                    {userInfo?.dreamjob
                      ? userInfo.dreamjob.length > 50
                        ? userInfo.dreamjob.substring(0, 50) + "..."
                        : userInfo.dreamjob
                      : "Loading..."}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <span className="text-blue-200/80 font-medium min-w-[120px]">
                    Biggest Interest:
                  </span>
                  <span className="text-white/90">
                    {userInfo?.biggest_personal_interest
                      ? userInfo.biggest_personal_interest.length > 50
                        ? userInfo.biggest_personal_interest.substring(0, 50) + "..."
                        : userInfo.biggest_personal_interest
                      : "Loading..."}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Category Navigation */}
        <div className="relative group">
          <div className="absolute -inset-[1px] bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-pink-500/20 rounded-xl blur transition duration-500 group-hover:opacity-100 opacity-75" />
          <div className="relative bg-[#1C1C2E]/80 backdrop-blur-xl rounded-xl overflow-hidden border border-white/5">
            <div className="flex flex-wrap">
              {selectedCategories.map((category) => {
                const isCollabCategory = category.toLowerCase().startsWith("collaborative ");
                const getCategoryColors = (cat) => {
                  switch(cat.toLowerCase()) {
                    case 'entrepreneurship':
                      return 'from-amber-500/90 to-orange-500/90 hover:from-amber-600/90 hover:to-orange-600/90 shadow-amber-500/20';
                    case 'computer science':
                      return 'from-cyan-500/90 to-blue-500/90 hover:from-cyan-600/90 hover:to-blue-600/90 shadow-cyan-500/20';
                    case 'economics':
                      return 'from-emerald-500/90 to-teal-500/90 hover:from-emerald-600/90 hover:to-teal-600/90 shadow-emerald-500/20';
                    default:
                      return isCollabCategory 
                        ? 'from-purple-500/90 to-pink-500/90 hover:from-purple-600/90 hover:to-pink-600/90 shadow-purple-500/20'
                        : 'from-blue-500/90 to-indigo-500/90 hover:from-blue-600/90 hover:to-indigo-600/90 shadow-blue-500/20';
                  }
                };
                
                return (
                  <button
                    key={category}
                    onClick={() => handleCategoryClick(category, isCollabCategory)}
                    className={`flex-1 px-6 py-4 text-center transition-all duration-300 relative group ${
                      activeCategory === category
                        ? `bg-gradient-to-r ${getCategoryColors(category)} text-white font-medium shadow-lg`
                        : 'text-blue-200/80 hover:text-blue-100 hover:bg-white/5'
                    }`}
                  >
                    {category}
                    {activeCategory === category && (
                      <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-gradient-to-r from-white/0 via-white/50 to-white/0" />
                    )}
                  </button>
                );
              })}
            </div>
          </div>
        </div>

        {/* Add @keyframes for animations at the end of your CSS */}
        <style jsx="true">{`
          @keyframes float {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(-20px); }
          }
          @keyframes float-delayed {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(-15px); }
          }
          @keyframes float-slow {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(-10px); }
          }
          .animate-float {
            animation: float 15s ease-in-out infinite;
          }
          .animate-float-delayed {
            animation: float-delayed 18s ease-in-out infinite;
            animation-delay: -9s;
          }
          .animate-float-slow {
            animation: float-slow 20s ease-in-out infinite;
            animation-delay: -5s;
          }
          .animate-fadeIn {
            animation: fadeIn 0.3s ease-out;
          }
          @keyframes fadeIn {
            from { opacity: 0; transform: translateY(-10px); }
            to { opacity: 1; transform: translateY(0); }
          }
        `}</style>

        {/* Rest of your content */}
        <div className="space-y-6">
          {renderContent()}
          <FutureContent />
        </div>
      </div>

      {showUpdatePopup && userInfo && (
        <UpdateProfileDialog
          onClose={() => setShowUpdatePopup(false)}
          currentDreamJob={userInfo.dreamjob}
          currentPersonalInterest={userInfo.biggest_personal_interest}
          onUpdate={async (dreamJob, personalInterest) => {
            try {
              const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/update_user_info`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    userId: user.id,
                    dreamJob,
                    personalInterest,
                  }),
                }
              );

              if (!response.ok) throw new Error("Update failed");

              setUserInfo({
                ...userInfo,
                dreamjob: dreamJob,
                biggest_personal_interest: personalInterest,
              });
              setShowUpdatePopup(false);
              await refreshCache();
            } catch (error) {
              console.error("Error updating profile:", error);
              setHasError(true);
            }
          }}
        />
      )}

      {showCollaborativeModal && (
        <FriendsModal
          onClose={() => setShowCollaborativeModal(false)}
          onInvite={handleStartCollaborative}
          friends={friends}
          user={user}
        />
      )}
      <SettingsModal
        isOpen={showSettingsModal}
        onClose={() => setShowSettingsModal(false)}
        preferences={userPreferences}
        onSave={(newPreferences) => {
          setUserPreferences(newPreferences);
          setShowSettingsModal(false);
        }}
        user={user}
      />
    </div>
  );
};

const HomeWithErrorBoundary = ({ session, user }) => {
  return (
    <ErrorBoundary
      fallback={
        <div className="h-screen flex items-center justify-center bg-gray-900">
          <div className="text-center p-8 bg-gray-800 rounded-xl shadow-2xl border border-red-500/20">
            <h2 className="text-2xl text-red-400 mb-4">
              Oops! Something went wrong.
            </h2>
            <p className="text-gray-300 mb-6">
              We're having trouble loading your content.
            </p>
            <button
              onClick={() => window.location.reload()}
              className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              Try Again
            </button>
          </div>
        </div>
      }
    >
      <Home session={session} user={user} />
    </ErrorBoundary>
  );
};

// Add a new ProjectPlaceholder component
const ProjectPlaceholder = () => {
  return (
    <div className="relative my-2 group project-box">
      {/* Placeholder with purple haze */}
      <div className="relative p-[1px] rounded-xl">
        {/* Blurred purple gradient background */}
        <div className="absolute inset-0 bg-gradient-to-r from-purple-500/20 via-purple-400/20 to-pink-500/20 rounded-xl blur-sm" />

        <div className="bg-gray-900/80 backdrop-blur-sm rounded-xl relative border border-purple-500/20 py-1 px-3 shadow-[0_0_15px_rgba(168,85,247,0.15)] opacity-60 hover:opacity-80 transition-all duration-300">
          <div className="flex items-center gap-2">
            <svg
              className="w-4 h-4 flex-shrink-0 text-purple-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
              />
            </svg>
            <p className="text-purple-300 text-sm font-medium whitespace-nowrap">
              Future Project
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeWithErrorBoundary;
