import React from "react";
import { useParams, Navigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { posts } from "../posts";
import Header from "./Header";

const BlogPost = () => {
  const { slug } = useParams();
  const post = posts.find((p) => p.slug === slug);

  if (!post) {
    return <Navigate to="/blog" replace />;
  }

  // Extract first paragraph for meta description
  const firstParagraph = post.description;
  const lastUpdated = post.meta?.dateModified
    ? new Date(post.meta.dateModified).toLocaleDateString()
    : null;

  return (
    <div className="bg-gray-950 min-h-screen">
      <Header />
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto">
          {/* Back button */}
          <Link
            to="/blog"
            className="inline-flex items-center mb-6 text-blue-400 hover:text-blue-300 transition-colors group"
          >
            <svg
              className="w-5 h-5 mr-2 transform transition-transform group-hover:-translate-x-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
            Back to Blog Posts
          </Link>

          <article className="bg-gray-800 bg-opacity-80 backdrop-blur-xl rounded-2xl shadow-2xl p-8 md:p-12">
            <Helmet>
              <title>{post.title} - Heretic School Blog</title>
              <meta name="description" content={firstParagraph} />
              <meta
                property="og:title"
                content={`${post.title} - Heretic School Blog`}
              />
              <meta property="og:description" content={firstParagraph} />
              <meta property="og:type" content="article" />
              <meta
                property="og:url"
                content={`https://hereticschool.com/blog/${post.slug}`}
              />
            </Helmet>

            {lastUpdated && (
              <div className="text-gray-400 text-sm mb-4">
                Last Updated: {lastUpdated}
              </div>
            )}

            <div
              className="blog-content prose prose-lg prose-invert mx-auto"
              dangerouslySetInnerHTML={{
                __html: post.markdown,
              }}
            />
          </article>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
