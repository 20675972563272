import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import ErrorBoundary from "./ErrorBoundary";
import posthog from "posthog-js";
import { debounce } from "lodash";
import { enableDebugListeners } from './debug';

// Initialize PostHog
posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, {
  api_host: process.env.REACT_APP_POSTHOG_HOST || "https://app.posthog.com",
  capture_pageview: false, // We'll handle this manually in App.js
});

// Enable debug utilities globally as early as possible
if (process.env.NODE_ENV !== 'production') {
  console.log('🛠️ Development mode detected, enabling debug utilities');
  
  // Force enable debugging in all environments for troubleshooting
  window.DEBUG_FORCE_ENABLED = true;
  
  // Initialize with a retry mechanism to ensure it's loaded
  const initDebugUtils = () => {
    try {
      if (typeof enableDebugListeners === 'function') {
        enableDebugListeners();
        console.log('🛠️ Debug utilities initialized successfully');
      } else {
        console.warn('🛠️ Debug utilities not available yet, will retry...');
        setTimeout(initDebugUtils, 500);
      }
    } catch (e) {
      console.error('🛠️ Error initializing debug utilities:', e);
      setTimeout(initDebugUtils, 1000);
    }
  };
  
  // Start initialization process
  initDebugUtils();
  
  // Make sure the debug utilities are available even if the module fails to load
  window.debugApp = window.debugApp || {
    // Fallback implementations
    checkCORS: async () => {
      console.warn('Using fallback CORS check');
      try {
        const response = await fetch('https://heretic-school-flask.vercel.app/api/article_interaction', {
          method: 'OPTIONS'
        });
        console.log('CORS check result:', response.status);
        return { success: response.ok, status: response.status };
      } catch (e) {
        console.error('CORS check failed:', e);
        return { success: false, error: e.message };
      }
    },
    
    testInteraction: (type = 'explain') => {
      console.warn('Using fallback test interaction');
      alert(`Would test interaction type: ${type} (fallback implementation)`);
    },
    
    getActionHistory: () => {
      console.warn('Action history not available (fallback)');
      return [];
    },
    
    // Add the new utilities as fallbacks
    checkComponents: () => {
      console.warn('Using fallback component checker');
      const result = {
        articleReader: !!window._articleReaderInstance,
        highlightMenu: !!window._highlightMenuInstance,
        menuElement: !!document.querySelector('.article-highlight-menu')
      };
      console.log('Component status:', result);
      return result;
    },
    
    runHighlightTest: (type) => {
      console.warn(`Using fallback highlight test for type: ${type}`);
      alert(`Would run highlight test for: ${type} (fallback implementation)`);
    }
  };
  
  // Utility to reload debugApp from any page
  window.reloadDebugUtils = () => {
    console.log('🔄 Reloading debug utilities...');
    if (typeof enableDebugListeners === 'function') {
      enableDebugListeners();
      console.log('✅ Debug utilities reloaded');
      return true;
    } else {
      console.error('❌ Debug utilities module not found');
      return false;
    }
  };
}

// Function to suppress specific errors
const suppressErrors = () => {
  // Suppress console errors
  const originalConsoleError = console.error;
  console.error = (...args) => {
    // Ignore all console errors in production
    if (process.env.NODE_ENV === "production") return;

    // In development, only log non-React warnings
    if (process.env.NODE_ENV === "development" && !/^Warning:/.test(args[0])) {
      originalConsoleError(...args);
    }
  };

  // Suppress window errors
  window.addEventListener(
    "error",
    (event) => {
      event.stopImmediatePropagation();
      event.preventDefault();
      return true;
    },
    true
  );

  // Suppress unhandled promise rejections
  window.addEventListener("unhandledrejection", (event) => {
    event.preventDefault();
  });

  // Patch React's error overlay
  if (process.env.NODE_ENV === "development") {
    const originalError = console.error;
    console.error = (...args) => {
      if (!/React will try to recreate this component tree/.test(args[0])) {
        originalError(...args);
      }
    };
  }

  // Patch ResizeObserver
  const originalResizeObserver = window.ResizeObserver;
  window.ResizeObserver = class ResizeObserver extends originalResizeObserver {
    constructor(callback) {
      super((entries, observer) => {
        const suppressedCallback = debounce((entries, observer) => {
          try {
            callback(entries, observer);
          } catch (e) {
            if (
              e.message !==
              "ResizeObserver loop completed with undelivered notifications."
            ) {
              throw e;
            }
            // Else, swallow the specific ResizeObserver error
          }
        }, 200);
        suppressedCallback(entries, observer);
      });
    }
  };
};

// Apply error suppression
suppressErrors();

// Wrap the entire app in an error boundary that silently catches errors
const SilentErrorBoundary = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={() => null}>{children}</ErrorBoundary>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <SilentErrorBoundary>
      <App />
    </SilentErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
