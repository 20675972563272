import React, { forwardRef, useEffect, useState, useCallback } from "react";

// Custom tooltip component to show more descriptive information
const Tooltip = ({ text, children }) => {
  return (
    <div className="group relative">
      {children}
      <div className="absolute opacity-0 group-hover:opacity-100 bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-900 text-white text-xs rounded-md shadow-lg transition-opacity duration-300 whitespace-nowrap pointer-events-none z-[100000]">
        {text}
        <div className="absolute top-full left-1/2 transform -translate-x-1/2 border-4 border-transparent border-t-gray-900"></div>
      </div>
    </div>
  );
};

const ArticleHighlightMenu = forwardRef(({
  position,
  onExplain,
  onExpand,
  onDeepDive,
  onClose,
  isLoading,
  selectedText,
  style
}, ref) => {
  // Add draggable state
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [menuPos, setMenuPos] = useState(position);
  
  // Update internal position when prop changes
  useEffect(() => {
    setMenuPos(position);
  }, [position]);
  
  // Add an effect to log when the component mounts and unmounts
  useEffect(() => {
    console.log("ArticleHighlightMenu mounted with position:", position);
    console.log("Style prop received:", style);
    
    // Log element to DOM for debugging
    setTimeout(() => {
      const menuElement = document.getElementById('article-highlight-menu');
      if (menuElement) {
        const rect = menuElement.getBoundingClientRect();
        console.log("Menu element found in DOM at: ", {
          top: rect.top,
          left: rect.left,
          width: rect.width,
          height: rect.height,
          style: window.getComputedStyle(menuElement)
        });
      } else {
        console.warn("Menu element NOT found in DOM after mount");
      }
    }, 100);
    
    return () => {
      console.log("ArticleHighlightMenu unmounted");
    };
  }, [position, style]);
  
  // Drag handlers
  const handleMouseDown = useCallback((e) => {
    // Only enable dragging when clicked on the drag handle or menu background (not buttons)
    if (e.target.closest('button')) {
      return;
    }
    
    e.preventDefault();
    e.stopPropagation();
    
    // Calculate offset from current position to mouse position
    const offsetX = e.clientX - menuPos.x;
    const offsetY = e.clientY - menuPos.y;
    
    setDragOffset({ x: offsetX, y: offsetY });
    setIsDragging(true);
    
    console.log("Started dragging menu", { offsetX, offsetY });
  }, [menuPos]);
  
  const handleMouseMove = useCallback((e) => {
    if (!isDragging) return;
    
    e.preventDefault();
    e.stopPropagation();
    
    // Calculate new position
    const newX = e.clientX - dragOffset.x;
    const newY = e.clientY - dragOffset.y;
    
    setMenuPos({ x: newX, y: newY });
  }, [isDragging, dragOffset]);
  
  const handleMouseUp = useCallback((e) => {
    if (!isDragging) return;
    
    e.preventDefault();
    e.stopPropagation();
    
    setIsDragging(false);
    console.log("Stopped dragging menu");
  }, [isDragging]);
  
  // Add global mouse move and up handlers when dragging
  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
    
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);
  
  // Handler to stop event propagation and prevent losing selection
  const handleButtonClick = (e, callback, interactionType) => {
    // Prevent default behavior and stop propagation
    e.preventDefault();
    e.stopPropagation();
    
    console.log(`Button clicked: ${interactionType}`, { selectedText });
    
    // If callback exists and not currently loading, call it with formatted data
    if (typeof callback === 'function' && !isLoading) {
      // Format the interaction data to match the structure expected by handleInteraction
      const interactionData = {
        interaction_type: interactionType,
        selected_text: selectedText
      };
      
      console.log(`Calling callback for ${interactionType} with data:`, interactionData);
      
      // Call the callback with the interaction data
      callback(interactionData);
    }
  };

  // Move the useEffect for viewport boundary checking before the conditional return
  useEffect(() => {
    // Keep menu in viewport bounds when position changes
    if (menuPos.x && menuPos.y) {
      const menuElement = document.getElementById('article-highlight-menu');
      if (menuElement) {
        const rect = menuElement.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const viewportWidth = window.innerWidth;
        
        // Check if menu is going off-screen
        let newX = menuPos.x;
        let newY = menuPos.y;
        
        // Check bottom edge - if menu is going below viewport
        if (rect.bottom > viewportHeight - 20) {
          // Move it above the selection point
          newY = menuPos.y - (rect.height / 2) - 20;
          console.log("Adjusting menu position - too close to bottom edge", { oldY: menuPos.y, newY });
        }
        
        // Check top edge - if menu is going above viewport
        if (rect.top < 20) {
          // Move it below the selection point
          newY = 20 + (rect.height / 2);
          console.log("Adjusting menu position - too close to top edge", { oldY: menuPos.y, newY });
        }
        
        // Check left edge
        if (rect.left < 20) {
          newX = 20 + (rect.width / 2);
        }
        
        // Check right edge
        if (rect.right > viewportWidth - 20) {
          newX = viewportWidth - 20 - (rect.width / 2);
        }
        
        // Update position if needed
        if (newX !== menuPos.x || newY !== menuPos.y) {
          setMenuPos({ x: newX, y: newY });
        }
      }
    }
  }, [menuPos]);

  // Log rendering for debugging
  console.log("Rendering ArticleHighlightMenu", { selectedText, menuPos, style, isDragging });

  // If no valid selected text, don't render the menu
  if (!selectedText || selectedText.trim() === '') {
    console.log("No valid selected text, not rendering highlight menu");
    return null;
  }

  // Update the menuStyle with better styling
  const menuStyle = {
    backgroundColor: "rgba(25, 35, 50, 0.97)",
    border: "1px solid rgba(124, 58, 237, 0.5)",
    borderRadius: "12px",
    padding: "12px",
    paddingTop: "16px", // Extra space for the drag handle
    boxShadow: isDragging 
      ? "0 12px 28px rgba(0, 0, 0, 0.6), 0 0 0 4px rgba(99, 102, 241, 0.4)" 
      : "0 8px 24px rgba(0, 0, 0, 0.4), 0 0 0 2px rgba(99, 102, 241, 0.2)",
    display: 'flex',
    gap: '10px',
    position: 'fixed',
    top: menuPos.y || 0,
    left: menuPos.x || 0,
    transform: isDragging 
      ? 'translate(-50%, -100%) scale(1.03)' 
      : 'translate(-50%, -100%) scale(1)',
    zIndex: 9999999,
    visibility: 'visible',
    opacity: 1,
    pointerEvents: 'auto',
    transition: isDragging ? 'none' : 'box-shadow 0.3s ease, transform 0.2s ease',
    ...style
  };

  return (
    <div
      ref={ref}
      id="article-highlight-menu"
      style={menuStyle}
      onClick={(e) => e.stopPropagation()}
      onMouseDown={handleMouseDown}
      className={`highlight-menu ${isDragging ? 'grabbing' : 'grab'} relative`}
    >
      {/* Improve drag handle */}
      <div 
        className="absolute top-0 left-0 right-0 h-5 cursor-move flex items-center justify-center group"
        style={{ 
          borderTopLeftRadius: '11px', 
          borderTopRightRadius: '11px',
          background: 'linear-gradient(to right, rgba(124, 58, 237, 0.1), rgba(79, 70, 229, 0.1))'
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
          handleMouseDown(e);
        }}
      >
        <div className="w-10 h-1.5 bg-gray-500/50 rounded-full group-hover:bg-indigo-400/60 transition-colors"></div>
        <div className="absolute right-2 top-0.5 text-gray-400/60 text-[10px]">drag</div>
      </div>

      {/* Status indicator for dragging */}
      {isDragging && (
        <div className="absolute -top-6 left-1/2 transform -translate-x-1/2 bg-indigo-700/90 text-white text-xs py-1 px-3 rounded shadow-lg animate-pulse whitespace-nowrap">
          Moving menu
        </div>
      )}

      <div className="flex pt-2">
        <Tooltip text="Explain in simple terms - Get a clear, simplified explanation of this text">
          <button
            type="button"
            onClick={(e) => handleButtonClick(e, onExplain, "explain")}
            className={`p-2 rounded-lg text-white bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-500 hover:to-blue-600 active:bg-blue-800 transition-colors flex items-center justify-center w-11 h-11 ${
              isLoading ? "opacity-50 cursor-not-allowed" : ""
            } focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1 focus:ring-offset-gray-900 transform transition-all duration-200 hover:scale-105 hover:shadow-lg`}
            title="Explain in simpler terms"
            aria-label="Explain in simpler terms"
            disabled={isLoading}
            data-action="explain"
            style={{
              minWidth: "44px",
              minHeight: "44px",
              cursor: "pointer"
            }}
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 24 24" 
              fill="currentColor" 
              className="w-6 h-6"
            >
              <path d="M11.25 4.533A9.707 9.707 0 006 3a9.735 9.735 0 00-3.25.555.75.75 0 00-.5.707v14.25a.75.75 0 001 .707A8.237 8.237 0 016 18.75c1.995 0 3.823.707 5.25 1.886V4.533zM12.75 20.636A8.214 8.214 0 0118 18.75c.966 0 1.89.166 2.75.47a.75.75 0 001-.708V4.262a.75.75 0 00-.5-.707A9.735 9.735 0 0018 3a9.707 9.707 0 00-5.25 1.533v16.103z" />
            </svg>
          </button>
        </Tooltip>

        <Tooltip text="Expand with details - Get additional information and context about this topic">
          <button
            type="button"
            onClick={(e) => handleButtonClick(e, onExpand, "expand")}
            className={`p-2 rounded-lg text-white bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-500 hover:to-indigo-500 active:bg-purple-800 transition-colors flex items-center justify-center w-11 h-11 ${
              isLoading ? "opacity-50 cursor-not-allowed" : ""
            } focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-1 focus:ring-offset-gray-900 transform transition-all duration-200 hover:scale-105 hover:shadow-lg`}
            title="Expand with more details"
            aria-label="Expand with more details"
            disabled={isLoading}
            data-action="expand"
            style={{
              minWidth: "44px",
              minHeight: "44px",
              cursor: "pointer"
            }}
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 24 24" 
              fill="currentColor" 
              className="w-6 h-6"
            >
              <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clipRule="evenodd" />
            </svg>
          </button>
        </Tooltip>

        <div className="w-px h-11 bg-gray-700/50 mx-2"></div>

        <Tooltip text="Close menu">
          <button
            type="button"
            onClick={(e) => handleButtonClick(e, onClose)}
            className="p-2 rounded-lg text-gray-400 hover:text-white hover:bg-gray-700 active:bg-gray-600 transition-colors flex items-center justify-center w-9 h-9 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-1 focus:ring-offset-gray-900 transform transition-all duration-200 hover:scale-105"
            title="Close menu"
            aria-label="Close menu"
            data-action="close"
            style={{
              minWidth: "36px",
              minHeight: "36px",
              cursor: "pointer",
              alignSelf: "center"
            }}
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 24 24" 
              fill="currentColor" 
              className="w-5 h-5"
            >
              <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clipRule="evenodd" />
            </svg>
          </button>
        </Tooltip>

        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-800/95 rounded-lg backdrop-blur-sm">
            <svg className="animate-spin h-7 w-7 text-indigo-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        )}
      </div>
    </div>
  );
});

export default ArticleHighlightMenu; 