import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-800 py-8 mt-12">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0">
            <h3 className="text-xl font-bold">Heretic.School</h3>
            <p className="text-gray-400 mt-2">Learn wherever you go</p>
          </div>

          <div className="flex flex-col md:flex-row gap-8">
            <div>
              <h4 className="text-lg font-semibold mb-2">Resources</h4>
              <ul className="space-y-1">
                <li>
                  <a
                    href="https://www.heretic.school/blog"
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <a
                    href="https://chrome.google.com/webstore/detail/hereticschool/jffaiidojfhfgnpbffokeehbaeipoeie"
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    Chrome Extension
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <h4 className="text-lg font-semibold mb-2">Company</h4>
              <ul className="space-y-1">
                <li>
                  <a
                    href="https://www.heretic.school/about"
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.heretic.school/contact"
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-700 mt-8 pt-8 text-center text-gray-400">
          <p>© {currentYear} Heretic.School. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
