import React, { useState, useEffect } from "react";

// Gradient text component
const Gradient = ({ children }) => (
  <span className="text-transparent bg-gradient-to-br from-purple-500 to-indigo-500 bg-clip-text">
    {children}
  </span>
);

const ArticleLibrary = ({ user, onSelectArticle }) => {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPublicMode, setIsPublicMode] = useState(false);

  useEffect(() => {
    // Check if we're in public mode (unauthenticated user)
    if (!user || user.id === "public" || user.role === "anonymous") {
      console.log("Using public mode for article library");
      setIsPublicMode(true);
    } else {
      setIsPublicMode(false);
    }

    const fetchArticles = async () => {
      setIsLoading(true);
      setError(null);

      try {
        // For public mode or missing user ID, use the debug route to get all articles
        if (isPublicMode || !user || !user.id || user.id === "public") {
          console.log("Fetching all public articles");
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/debug/all_articles`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const data = await response.json();
          console.log("API response (all articles):", data);

          if (!response.ok) {
            throw new Error(data.error || "Failed to fetch articles");
          }

          console.log("Articles received:", data.articles ? data.articles.length : 0);
          setArticles(data.articles || []);
        } else {
          // For authenticated users, get their specific articles
          console.log("Fetching articles for user ID:", user.id);
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/get_user_articles?user_id=${user.id}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const data = await response.json();
          console.log("API response (user articles):", data);

          if (!response.ok) {
            throw new Error(data.error || "Failed to fetch articles");
          }

          console.log("Articles received:", data.articles ? data.articles.length : 0);
          setArticles(data.articles || []);
        }
      } catch (err) {
        console.error("Error fetching articles:", err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchArticles();
  }, [user, isPublicMode]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const handleArticleClick = (article) => {
    // Check if the article has a slug
    if (article.slug) {
      // If using React Router, we can navigate directly instead of using the callback
      window.location.href = `/articles/${article.slug}`;
    } else {
      // Fallback to the old ID-based method
      onSelectArticle(article.id);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-pulse flex flex-col items-center">
          <div className="w-16 h-16 mb-4 bg-purple-800/50 rounded-full"></div>
          <div className="text-purple-400 text-xl">Loading articles...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 bg-red-900/30 border border-red-500/50 rounded-lg text-red-200">
        <div className="flex items-start">
          <svg
            className="w-5 h-5 mt-0.5 mr-2 flex-shrink-0"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <p>Failed to load articles: {error}</p>
        </div>
      </div>
    );
  }

  if (articles.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center text-center p-12">
        <div className="text-gray-500 mb-6">
          <svg
            className="w-12 h-12 mx-auto"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
            />
          </svg>
        </div>
        <h2 className="text-xl font-medium text-white mb-2">No articles available</h2>
        {!isPublicMode ? (
          <>
            <p className="text-gray-400 mb-6">Generate your first article using the "Generate New" button<br />to see it here.</p>
            
            <button 
              className="text-purple-400 hover:text-purple-300 text-sm font-medium cursor-pointer underline underline-offset-2" 
              onClick={() => document.querySelector('button[data-action="generate"]')?.click()}
            >
              Create your first article →
            </button>
          </>
        ) : (
          <p className="text-gray-400 mb-6">No published articles are available at this time.<br />Please check back later.</p>
        )}
      </div>
    );
  }

  return (
    <div className="rounded-xl overflow-hidden border border-gray-800 bg-gray-900/50 backdrop-blur-lg p-6 shadow-xl transform transition-all duration-300 hover:border-indigo-500/20 hover:shadow-indigo-500/10">
      <h2 className="text-2xl font-semibold mb-6">
        {isPublicMode ? (
          <Gradient>Educational Articles</Gradient>
        ) : (
          <>Your <Gradient>Articles</Gradient></>
        )}
      </h2>

      <div className="space-y-4">
        {articles.map((article) => (
          <div
            key={article.id}
            className="group cursor-pointer rounded-lg border border-gray-800 bg-gray-900/50 hover:bg-gray-800/50 hover:border-purple-500/30 p-4 transition-all transform hover:translate-y-[-2px] hover:shadow-lg duration-300"
            onClick={() => handleArticleClick(article)}
          >
            <div className="flex justify-between items-start">
              <div>
                <h3 className="text-lg font-medium text-white group-hover:text-transparent group-hover:bg-clip-text group-hover:bg-gradient-to-r group-hover:from-purple-400 group-hover:to-indigo-400 transition-colors mb-1">
                  {article.title}
                </h3>
                <div className="flex items-center space-x-3 text-sm">
                  <span className="text-gray-400 flex items-center">
                    <svg
                      className="w-4 h-4 mr-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      ></path>
                    </svg>
                    {formatDate(article.created_at)}
                  </span>
                </div>
              </div>
              <div className="text-gray-500 rounded-full p-1.5 group-hover:bg-purple-600/10 group-hover:text-purple-400 transition-colors">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                  />
                </svg>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArticleLibrary; 