import React, { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import Header from "./Header";
import posthog from "posthog-js";
import { track } from "@vercel/analytics";
import { Link, useNavigate, useLocation } from "react-router-dom";

// Gradient text component similar to other pages
const Gradient = ({ children }) => (
  <span className="text-transparent bg-gradient-to-br from-purple-500 to-indigo-500 bg-clip-text">
    {children}
  </span>
);

const Auth = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [supabaseInitialized, setSupabaseInitialized] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false); // To toggle between login and signup views
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (supabase) {
      setSupabaseInitialized(true);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      posthog.capture("login_attempted", { email });
      track("login_attempted", { email });

      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw error;

      // After successful login, check if user needs to complete form
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/check_existing_data?userId=${data.user.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        console.log("Check existing data result:", result);

        // Handle login tracking
        try {
          await fetch(`${process.env.REACT_APP_API_URL}/api/handle-login`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              email: email,
              userId: data.user.id,
            }),
          });
        } catch (handlerError) {
          console.error("Error calling login handler:", handlerError);
        }

        // Student account flow - check if they need to complete form
        if (!result.found || !result.submission) {
          console.log("New user - redirecting to form");
          localStorage.removeItem("setupComplete");
          // Use navigate with state to force a fresh load
          navigate("/form", {
            replace: true,
            state: {
              fromAuth: true,
              timestamp: Date.now(),
            },
          });
          return;
        }

        // Only redirect to home if user has completed setup
        console.log("User setup complete, redirecting to home");
        navigate("/home", { replace: true });
      } catch (checkError) {
        console.error("Error checking user data:", checkError);
        // On error, assume form needs to be completed
        localStorage.removeItem("setupComplete");
        navigate("/form", { replace: true });
      }
    } catch (error) {
      posthog.capture("login_error", { error: error.message });
      track("login_error", { error: error.message });
      console.error("Login error:", error);
      alert(error.message || "An error occurred during login");
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
      });

      if (error) throw error;

      posthog.capture("user_signed_up", { email });
      track("user_signed_up", { email });

      if (data && data.user) {
        // Sign out immediately after signup
        await supabase.auth.signOut();

        // Show success message
        alert("Account created successfully! Please log in to continue.");

        // Clear form and reset to login view
        setEmail("");
        setPassword("");
        setIsSignUp(false);

        return;
      }
    } catch (error) {
      console.error("Error signing up:", error.message);
      posthog.capture("signup_error", { error: error.message });
      track("signup_error", { error: error.message });
      alert(error.message || "An error occurred during sign up");
    } finally {
      setLoading(false);
    }
  };

  if (!supabaseInitialized) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900">
        <div className="animate-pulse flex flex-col items-center">
          <div className="w-16 h-16 mb-4 bg-purple-800/50 rounded-full"></div>
          <div className="text-purple-400 text-xl">Initializing...</div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Header />
      <div className="min-h-screen flex items-center justify-center bg-gray-950 py-12 px-4 sm:px-6 lg:px-8 relative overflow-hidden">
        {/* Background elements */}
        <div className="absolute inset-0 overflow-hidden">
          {/* Subtle grid pattern similar to shadcn style */}
          <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:14px_24px]"></div>
          
          {/* Radial gradient background */}
          <div className="absolute left-[50%] top-[50%] -z-10 h-[1000px] w-[1000px] -translate-x-[50%] -translate-y-[50%] bg-radial-gradient"></div>
          
          {/* Accent colors */}
          <div className="absolute -top-40 right-0 h-[500px] w-[500px] rounded-full bg-purple-500/20 blur-[100px] -z-10"></div>
          <div className="absolute -bottom-40 left-0 h-[500px] w-[500px] rounded-full bg-indigo-500/20 blur-[100px] -z-10"></div>
        </div>

        <style jsx="true">{`
          .bg-radial-gradient {
            background: radial-gradient(circle at center, rgba(124, 58, 237, 0.05) 0%, rgba(49, 46, 129, 0.02) 25%, rgba(0, 0, 0, 0) 80%);
          }
        `}</style>

        <div className="w-full max-w-md space-y-8 relative z-10">
          {/* Auth Panel */}
          <div className="rounded-xl overflow-hidden border border-neutral-800 bg-neutral-900/50 backdrop-blur-lg shadow-xl">
            {/* Tab Selector - A shadcn-inspired login/signup tabs */}
            <div className="flex border-b border-gray-800">
              <button
                onClick={() => setIsSignUp(false)}
                className={`flex-1 py-4 text-sm font-medium transition-colors ${
                  !isSignUp 
                    ? "text-white border-b-2 border-indigo-500" 
                    : "text-gray-400 hover:text-gray-300"
                }`}
              >
                Sign In
              </button>
              <button
                onClick={() => setIsSignUp(true)}
                className={`flex-1 py-4 text-sm font-medium transition-colors ${
                  isSignUp 
                    ? "text-white border-b-2 border-indigo-500" 
                    : "text-gray-400 hover:text-gray-300"
                }`}
              >
                Create Account
              </button>
            </div>
            
            {/* Form Container */}
            <div className="p-6">
              <div className="mb-6">
                <h2 className="text-2xl font-semibold tracking-tight text-white">
                  {isSignUp ? "Create an account" : "Welcome "}
                  {!isSignUp && <Gradient>back</Gradient>}
                </h2>
                <p className="mt-2 text-sm text-gray-400">
                  {isSignUp 
                    ? "Enter your details to create a new account" 
                    : "Enter your credentials to access your account"}
                </p>
              </div>

              <form className="space-y-5">
                <div className="space-y-1">
                  <label
                    htmlFor="email"
                    className="block text-xs font-medium text-gray-300"
                  >
                    Email
                  </label>
                  <div className="relative">
                    <input
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="name@example.com"
                      required
                      className="w-full px-3 py-3 bg-gray-900/80 border border-gray-700 rounded-lg text-white placeholder-gray-500 text-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent shadow-inner shadow-indigo-900/20"
                    />
                  </div>
                </div>

                <div className="space-y-1">
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="password"
                      className="block text-xs font-medium text-gray-300"
                    >
                      Password
                    </label>
                    {!isSignUp && (
                      <Link
                        to="/reset-password"
                        className="text-xs text-indigo-400 hover:text-indigo-300"
                      >
                        Forgot password?
                      </Link>
                    )}
                  </div>
                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="••••••••"
                      required
                      className="w-full px-3 py-3 bg-gray-900/80 border border-gray-700 rounded-lg text-white placeholder-gray-500 text-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent shadow-inner shadow-indigo-900/20 pr-9"
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-300"
                    >
                      {showPassword ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                      )}
                    </button>
                  </div>
                </div>

                <button
                  onClick={isSignUp ? handleSignUp : handleLogin}
                  disabled={loading}
                  className={`w-full py-3 px-6 rounded-lg font-bold text-lg mt-4 relative ${
                    isSignUp
                      ? "bg-gradient-to-r from-green-600 to-green-700 hover:from-green-700 hover:to-green-800 text-white"
                      : "bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white"
                  } focus-visible:outline-none focus:shadow-outline transform transition-all duration-300 hover:scale-[1.02] hover:shadow-lg ${
                    isSignUp ? "focus-visible:ring-green-500" : "focus-visible:ring-purple-500"
                  } disabled:opacity-50 disabled:pointer-events-none`}
                >
                  {loading ? (
                    <div className="flex items-center justify-center">
                      <svg
                        className="animate-spin -ml-1 mr-2 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      {isSignUp ? "Creating Account..." : "Signing In..."}
                    </div>
                  ) : (
                    <span className="inline-flex items-center justify-center">
                      <span className="mr-2">{isSignUp ? "✨" : "🔐"}</span>
                      {isSignUp ? "Create Account" : "Sign In"}
                    </span>
                  )}
                </button>
              </form>

              {isSignUp && (
                <div className="mt-6 pt-5 border-t border-neutral-800">
                  <div className="flex flex-col space-y-2">
                    <a
                      href="https://chromewebstore.google.com/detail/hereticschool-chrome-exte/odlfeaecpimnkgmeigjokbhemhbppage"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-xs text-center text-purple-400 hover:text-purple-300 inline-flex items-center justify-center gap-1 group"
                    >
                      Get Chrome Extension
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-3 h-3 transition-transform group-hover:translate-x-0.5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
          
          <p className="mt-4 text-center text-xs text-neutral-500">
            By using our service, you agree to our{" "}
            <a href="/terms" className="text-purple-400 hover:text-purple-300">
              Terms of Service
            </a>{" "}
            and{" "}
            <a href="/privacy" className="text-purple-400 hover:text-purple-300">
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Auth;
