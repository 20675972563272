import React, { useState, useEffect } from "react";
import ArticleGenerator from "./ArticleGenerator";
import ArticleReader from "./ArticleReader";
import ArticleLibrary from "./ArticleLibrary";
import Header from "./Header";
import { useParams, useNavigate } from "react-router-dom";

// Gradient text component similar to Auth.js
const Gradient = ({ children }) => (
  <span className="text-transparent bg-gradient-to-br from-purple-500 to-indigo-500 bg-clip-text">
    {children}
  </span>
);

const ArticleSystem = ({ session, user }) => {
  const { slugOrId } = useParams(); // Get slug or ID from URL if present
  const navigate = useNavigate();
  const [selectedArticleId, setSelectedArticleId] = useState(null);
  const [currentArticle, setCurrentArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("library"); // 'library' or 'generator'
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [processingArticle, setProcessingArticle] = useState(false);
  
  // Create a default user object for unauthenticated access
  const defaultUser = { id: "public", role: "anonymous", email: "anonymous@user.com" };
  
  // Safe user object - use the actual user if available, otherwise use the default
  const safeUser = user || defaultUser;

  // Check if article has a temporary ID
  const isTemporaryArticle = (article) => {
    return article && article.article_id && 
           (article.article_id.toString().startsWith("temp_") || 
            article.article_id.toString().includes("temp-"));
  };

  // Log user info for debugging
  useEffect(() => {
    console.log("ArticleSystem - User:", user);
    console.log("ArticleSystem - Session:", session);
    
    // Check if user is authenticated
    if (user && user.id) {
      setIsAuthenticated(true);
    } else {
      console.log("User not authenticated or missing user object - using public mode");
      setIsAuthenticated(false);
    }
  }, [user, session]);

  // Fetch article either by ID (from state) or by slug/id (from URL)
  useEffect(() => {
    // If we have a slug or ID from the URL, use that
    if (slugOrId) {
      fetchArticleBySlugOrId(slugOrId);
    } 
    // Otherwise, if we have a selected ID from state, use that
    else if (selectedArticleId) {
      fetchArticleById(selectedArticleId);
    } else {
      setCurrentArticle(null);
      setProcessingArticle(false);
    }
  }, [selectedArticleId, slugOrId]);

  const fetchArticleBySlugOrId = async (slugOrId) => {
    setIsLoading(true);
    setError(null);

    try {
      // Use the new endpoint that accepts either slug or ID
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/article/${slugOrId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Failed to fetch article");
      }

      const article = data.article;
      setCurrentArticle(article);
      
      // Check if this is a temporary article and schedule a retry if it is
      if (isTemporaryArticle(article)) {
        setProcessingArticle(true);
        console.log("Article has temporary ID, will retry fetch after delay");
        
        // Schedule a retry to check if the article has been finalized
        setTimeout(() => {
          console.log("Retrying fetch for temporary article");
          fetchArticleBySlugOrId(slugOrId);
        }, 5000); // Try again after 5 seconds
      } else {
        setProcessingArticle(false);
      }
      
      // Update the selected article ID to match the fetched article
      setSelectedArticleId(article.id);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchArticleById = async (articleId) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/get_article?article_id=${articleId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Failed to fetch article");
      }

      const article = data.article;
      setCurrentArticle(article);
      
      // Check if this is a temporary article and schedule a retry if it is
      if (isTemporaryArticle(article)) {
        setProcessingArticle(true);
        console.log("Article has temporary ID, will retry fetch after delay");
        
        // Schedule a retry to check if the article has been finalized
        setTimeout(() => {
          console.log("Retrying fetch for temporary article");
          fetchArticleById(articleId);
        }, 5000); // Try again after 5 seconds
      } else {
        setProcessingArticle(false);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleArticleGenerated = (article) => {
    setCurrentArticle(article);
    setSelectedArticleId(article.article_id);
    
    // Check if this is a temporary article
    if (isTemporaryArticle(article)) {
      setProcessingArticle(true);
      console.log("Article has temporary ID, will retry fetch after delay");
      
      // Schedule a retry to fetch the finalized article
      setTimeout(() => {
        fetchArticleById(article.article_id);
      }, 5000); // Try after 5 seconds
    } else {
      setProcessingArticle(false);
    }
    
    // Switch to reader view
    setActiveTab("library");
    
    // Update URL to reflect the new article
    if (article.url) {
      // Extract just the slug or ID part from the URL
      const urlParts = article.url.split('/');
      const slugOrId = urlParts[urlParts.length - 1];
      navigate(`/articles/${slugOrId}`, { replace: true });
    }
  };

  const handleSelectArticle = (articleId) => {
    setSelectedArticleId(articleId);
  };

  const handleBack = () => {
    setSelectedArticleId(null);
    setCurrentArticle(null);
    setProcessingArticle(false);
    // Update URL to remove the slug/ID
    navigate("/articles", { replace: true });
  };

  // Processing message for temporary articles
  const renderProcessingMessage = () => {
    if (!processingArticle) return null;
    
    return (
      <div className="bg-indigo-900/30 p-6 rounded-lg border border-indigo-500/50 mb-8 animate-pulse">
        <h3 className="text-xl font-medium text-indigo-300 mb-2">Article Processing</h3>
        <p className="text-gray-300">
          This article is currently being generated and processed. Some content may not be available yet.
          The page will automatically refresh when processing is complete.
        </p>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-950 text-white relative overflow-hidden">
      <Header session={session} />
      
      {/* Background elements */}
      <div className="absolute inset-0 overflow-hidden">
        {/* Subtle grid pattern */}
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]"></div>
        
        {/* Radial gradient background */}
        <div className="absolute left-[50%] top-[50%] -z-10 h-[1000px] w-[1000px] -translate-x-[50%] -translate-y-[50%] bg-radial-gradient"></div>
        
        {/* Accent colors */}
        <div className="absolute -top-40 right-0 h-[500px] w-[500px] rounded-full bg-purple-500/20 blur-[100px] -z-10"></div>
        <div className="absolute -bottom-40 left-0 h-[500px] w-[500px] rounded-full bg-indigo-500/20 blur-[100px] -z-10"></div>
      </div>
      
      <style jsx="true">{`
        .bg-radial-gradient {
          background: radial-gradient(circle at center, rgba(124, 58, 237, 0.05) 0%, rgba(49, 46, 129, 0.02) 25%, rgba(0, 0, 0, 0) 80%);
        }
      `}</style>
      
      <div className="container max-w-6xl mx-auto px-4 py-8 relative z-10">
        <h1 className="text-4xl font-bold mb-8 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-indigo-400">
          Article System
        </h1>

        {!currentArticle ? (
          <div>
            <div className="flex justify-end mb-4">
              <div className="flex space-x-2">
                <button
                  className={`px-4 py-2 rounded-md text-sm font-medium transition ${
                    activeTab === "library"
                      ? "bg-indigo-600 text-white"
                      : "bg-gray-800 text-gray-300 hover:bg-gray-700"
                  }`}
                  onClick={() => setActiveTab("library")}
                >
                  <Gradient>Articles</Gradient>
                </button>
                {isAuthenticated && (
                  <button
                    className={`px-4 py-2 rounded-md text-sm font-medium transition ${
                      activeTab === "generator"
                        ? "bg-indigo-600 text-white"
                        : "bg-gray-800 text-gray-300 hover:bg-gray-700"
                    }`}
                    onClick={() => setActiveTab("generator")}
                    data-action="generate"
                  >
                    <Gradient>Generate New</Gradient>
                  </button>
                )}
                {!isAuthenticated && (
                  <button 
                    className="px-4 py-2 rounded-md text-sm font-medium bg-gray-800 text-gray-300 hover:bg-gray-700 transition"
                    onClick={() => navigate("/login")}
                  >
                    <Gradient>Login to Create</Gradient>
                  </button>
                )}
              </div>
            </div>

            {activeTab === "library" ? (
              <ArticleLibrary user={safeUser} onSelectArticle={handleSelectArticle} />
            ) : (
              <ArticleGenerator
                user={safeUser}
                onArticleGenerated={handleArticleGenerated}
              />
            )}
          </div>
        ) : (
          <>
            <div className="mb-6">
              <button
                onClick={handleBack}
                className="inline-flex items-center px-4 py-2 bg-gray-800/80 text-gray-300 rounded-lg border border-gray-700/50 hover:bg-gray-700/80 transition-all transform hover:scale-[1.02] hover:border-indigo-500/30"
              >
                <svg
                  className="w-5 h-5 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 19l-7-7m0 0l7-7m-7 7h18"
                  ></path>
                </svg>
                Back to Articles
              </button>
            </div>

            {renderProcessingMessage()}

            {isLoading ? (
              <div className="flex justify-center items-center h-64">
                <div className="animate-pulse flex flex-col items-center">
                  <div className="w-16 h-16 mb-4 bg-purple-800/50 rounded-full"></div>
                  <div className="text-purple-400 text-xl">Loading article...</div>
                </div>
              </div>
            ) : error ? (
              <div className="p-4 bg-red-900/30 border border-red-500/50 rounded-lg text-red-200">
                {error}
              </div>
            ) : (
              <ArticleReader article={currentArticle} user={safeUser} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ArticleSystem; 