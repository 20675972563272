import React, { useState } from "react";

// Gradient text component
const Gradient = ({ children }) => (
  <span className="text-transparent bg-gradient-to-br from-purple-500 to-indigo-500 bg-clip-text">
    {children}
  </span>
);

const ArticleGenerator = ({ user, onArticleGenerated }) => {
  const [topic, setTopic] = useState("");
  const [length, setLength] = useState("long");
  const [complexity, setComplexity] = useState("advanced");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/generate_article`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            topic,
            length,
            complexity,
            user_id: user.id,
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Failed to generate article");
      }

      // Pass the generated article to parent component
      onArticleGenerated(data);
      
      // Clear form
      setTopic("");
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto">
      <div className="rounded-xl overflow-hidden border border-gray-800 bg-gray-900/50 backdrop-blur-lg shadow-xl p-6 transform transition-all duration-300 hover:border-indigo-500/20 hover:shadow-indigo-500/10">
        <h2 className="text-2xl font-semibold tracking-tight mb-6">
          Generate an <Gradient>educational article</Gradient>
        </h2>

        <form onSubmit={handleSubmit} className="space-y-5">
          <div className="space-y-1">
            <label
              htmlFor="topic"
              className="block text-xs font-medium text-gray-300"
            >
              Topic
            </label>
            <input
              type="text"
              id="topic"
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
              placeholder="Enter article topic (e.g., Quantum Physics, Ancient Rome)"
              className="w-full px-3 py-3 bg-gray-900/80 border border-gray-700 rounded-lg text-white placeholder-gray-500 text-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent shadow-inner shadow-indigo-900/20"
              required
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-1">
              <label
                htmlFor="length"
                className="block text-xs font-medium text-gray-300"
              >
                Length
              </label>
              <select
                id="length"
                value={length}
                onChange={(e) => setLength(e.target.value)}
                className="w-full px-3 py-3 bg-gray-900/80 border border-gray-700 rounded-lg text-white text-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent shadow-inner shadow-indigo-900/20 appearance-none pr-8"
              >
                <option value="short">Short (3-5 min read)</option>
                <option value="medium">Medium (5-10 min read)</option>
                <option value="long">Long (10-15 min read)</option>
              </select>
            </div>

            <div className="space-y-1">
              <label
                htmlFor="complexity"
                className="block text-xs font-medium text-gray-300"
              >
                Complexity
              </label>
              <select
                id="complexity"
                value={complexity}
                onChange={(e) => setComplexity(e.target.value)}
                className="w-full px-3 py-3 bg-gray-900/80 border border-gray-700 rounded-lg text-white text-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent shadow-inner shadow-indigo-900/20 appearance-none pr-8"
              >
                <option value="basic">Basic (Easy to understand)</option>
                <option value="intermediate">Intermediate (Some domain knowledge)</option>
                <option value="advanced">Advanced (In-depth analysis)</option>
              </select>
            </div>
          </div>

          <div className="p-3 bg-indigo-900/20 border border-indigo-500/20 rounded-lg text-indigo-300 text-sm">
            <div className="flex items-start">
              <svg
                className="w-4 h-4 mt-0.5 mr-2 flex-shrink-0"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <p>
                AI-generated articles may take 15-30 seconds to create. Once generated, you can highlight any text to interact with the content.
              </p>
            </div>
          </div>

          {error && (
            <div className="p-3 bg-red-900/30 border border-red-500/50 rounded-lg text-red-200 text-sm">
              <div className="flex items-start">
                <svg
                  className="w-4 h-4 mt-0.5 mr-2 flex-shrink-0"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <p>{error}</p>
              </div>
            </div>
          )}

          <button
            type="submit"
            disabled={isLoading}
            className="w-full py-3 px-6 rounded-lg font-bold text-white bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 transition-all focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-1 focus:ring-offset-gray-900 disabled:opacity-50 transform transition-all duration-300 hover:scale-[1.02] hover:shadow-lg mt-4"
          >
            {isLoading ? (
              <div className="flex items-center justify-center">
                <svg
                  className="animate-spin -ml-1 mr-2 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Generating Article...
              </div>
            ) : (
              <>
                <span className="mr-2">✨</span> 
                Generate Article
              </>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ArticleGenerator; 