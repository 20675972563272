import React, { useState, useRef, useEffect, useCallback, memo } from "react";
import ArticleHighlightMenu from "./ArticleHighlightMenu";
import ReactMarkdown from 'react-markdown';
// Import marked using the correct syntax for version 15.0.7
// eslint-disable-next-line no-unused-vars
import { marked } from 'marked';
// Import only what we use
// eslint-disable-next-line no-unused-vars
import { logAction } from "../debug";
import debounce from 'lodash/debounce';
import { createPortal } from 'react-dom';

// Format date helper function
const formatDate = (dateString) => {
  if (!dateString) return "N/A";
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

// RenderParagraph component to handle paragraph rendering
const RenderParagraph = ({ content, onHighlightClick }) => {
  if (!content) return null;
  
  // If content is a string, render it as a paragraph with Markdown
  if (typeof content === 'string') {
    return (
      <div className="mb-4 text-gray-300">
        <ReactMarkdown
          components={{
            p: ({node, ...props}) => <p className="mb-4 text-gray-300" {...props} />,
            strong: ({node, ...props}) => <strong className="font-bold text-white" {...props} />,
            em: ({node, ...props}) => <em className="text-gray-300 italic" {...props} />,
            code: ({node, ...props}) => <code className="bg-gray-800 px-1 py-0.5 rounded text-sm" {...props} />,
            ul: ({node, ...props}) => <ul className="list-disc pl-5 mb-4 space-y-2 text-gray-300" {...props} />,
            ol: ({node, ...props}) => <ol className="list-decimal pl-5 mb-4 space-y-2 text-gray-300" {...props} />,
            li: ({node, ...props}) => <li className="mb-1" {...props} />,
          }}
        >
          {content}
        </ReactMarkdown>
      </div>
    );
  }
  
  // If content has type and text properties, render based on type
  if (content.type && content.text) {
    switch (content.type) {
      case 'header':
        return (
          <h2 className="text-2xl font-bold mb-4 text-white">
            <div>
              <ReactMarkdown components={{strong: ({node, ...props}) => <strong className="font-bold" {...props} />}}>
                {content.text}
              </ReactMarkdown>
            </div>
          </h2>
        );
      case 'subheader':
        return (
          <h3 className="text-xl font-bold mb-3 text-white">
            <div>
              <ReactMarkdown components={{strong: ({node, ...props}) => <strong className="font-bold" {...props} />}}>
                {content.text}
              </ReactMarkdown>
            </div>
          </h3>
        );
      case 'list':
        if (Array.isArray(content.items)) {
          return (
            <ul className="list-disc pl-5 mb-4 space-y-2 text-gray-300">
              {content.items.map((item, idx) => (
                <li key={idx}>
                  <div>
                    <ReactMarkdown components={{strong: ({node, ...props}) => <strong className="font-bold text-white" {...props} />}}>
                      {item}
                    </ReactMarkdown>
                  </div>
                </li>
              ))}
            </ul>
          );
        }
        return (
          <p className="mb-4 text-gray-300">
            <div>
              <ReactMarkdown components={{strong: ({node, ...props}) => <strong className="font-bold text-white" {...props} />}}>
                {content.text}
              </ReactMarkdown>
            </div>
          </p>
        );
      case 'code':
        return (
          <pre className="bg-gray-800 p-3 rounded-md mb-4 overflow-x-auto">
            <code className="text-gray-300">{content.text}</code>
          </pre>
        );
      default:
        return (
          <p className="mb-4 text-gray-300">
            <div>
              <ReactMarkdown components={{strong: ({node, ...props}) => <strong className="font-bold text-white" {...props} />}}>
                {content.text}
              </ReactMarkdown>
            </div>
          </p>
        );
    }
  }
  
  // Fallback
  return <p className="mb-4 text-gray-300">{JSON.stringify(content)}</p>;
};

// Modify the MemoizedHighlightMenu component to ensure better visibility
// eslint-disable-next-line no-unused-vars
const MemoizedHighlightMenu = memo(({ position, statusMessage, handleInteraction, interactionInProgress, selectedText, closeMenu }) => {
  // Get viewport dimensions for safety checks
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;
  
  // Ensure we have valid coordinates, with fallbacks
  let { x, y } = position;
  
  // Check if we have unlikely coordinates (possible NaN or extreme values)
  if (isNaN(x) || x < 0 || x > viewportWidth * 2) {
    x = viewportWidth / 2; // Fallback to center
    console.warn("Invalid menu X position, using fallback:", x);
  }
  
  if (isNaN(y) || y < 0 || y > viewportHeight * 3) { // Allow some room for scroll
    y = 100 + (window.scrollY || 0); // Fallback to near top + scroll position
    console.warn("Invalid menu Y position, using fallback:", y);
  }
  
  // Calculate safe position (stay away from edges)
  const safeX = Math.max(100, Math.min(x, viewportWidth - 100));
  
  // Safely check if statusMessage is defined
  const hasStatusMessage = typeof statusMessage === 'string' && statusMessage.trim() !== '';
  
  console.log("Rendering highlight menu at:", { safeX, y, viewportWidth, viewportHeight });
  
  // If there's no valid content (no status message and no selection), don't render anything
  if (!hasStatusMessage && (!selectedText || selectedText.trim() === '')) {
    console.log("No content to display in highlight menu, not rendering");
    return null;
  }

  return hasStatusMessage ? (
    <div
      className="fixed px-4 py-2 rounded-lg shadow-xl bg-indigo-900/90 text-white"
      style={{
        position: 'fixed',
        top: `${y}px`,
        left: `${safeX}px`,
        transform: 'translate(-50%, 0)',
        zIndex: 9999999
      }}
    >
      {statusMessage}
    </div>
  ) : (
    <ArticleHighlightMenu
      position={{ x: safeX, y }}
      onExplain={(data) => handleInteraction({ ...data, interaction_type: "explain" })}
      onExpand={(data) => handleInteraction({ ...data, interaction_type: "expand" })}
      onDeepDive={(data) => handleInteraction({ ...data, interaction_type: "deep_dive" })}
      onClose={closeMenu}
      isLoading={interactionInProgress}
      selectedText={selectedText}
    />
  );
});

const ArticleReader = ({ article, user }) => {
  const [selectedText, setSelectedText] = useState("");
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [showMenu, setShowMenu] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [expandedContent, setExpandedContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [highlights, setHighlights] = useState([]);
  const [contentReady, setContentReady] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [interactionHistory, setInteractionHistory] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [storedRange, setStoredRange] = useState(null);
  const [responses, setResponses] = useState([]);
  const [interactionInProgress, setInteractionInProgress] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [currentHighlightId, setCurrentHighlightId] = useState(null);
  const [statusMessage, setStatusMessage] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  
  // Check if user is authenticated (not anonymous/public)
  useEffect(() => {
    if (user && user.id && user.id !== "public" && user.role !== "anonymous") {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [user]);
  
  // Add article content as a state variable to force re-renders of the entire content
  const [articleContent, setArticleContent] = useState(() => {
    return typeof article.content === 'string' 
      ? article.content 
      : article.content && article.content.paragraphs 
        ? article.content.paragraphs.join('\n\n')
        : "No content available";
  });
  
  // Refs
  const articleRef = useRef(null);
  const contentRef = useRef(null);
  const menuRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const currentSelection = useRef("");
  const lastKnownRange = useRef(null);
  
  // Initialize additional state variables to handle selection issues
  // eslint-disable-next-line no-unused-vars
  const [menuVisible, setMenuVisible] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [attemptingSelection, setAttemptingSelection] = useState(false);
  
  // Add a ref to track component mount state
  const componentMounted = useRef(true);
  
  // Create refs for highlight spans to fix the React hooks warnings
  const currentHighlightSpanRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const currentPermanentHighlightRef = useRef(null);
  
  // Helper function to check if DOM operations can be performed
  const canPerformDOMOperations = useCallback(() => {
    return componentMounted.current && contentRef.current && document.body.contains(contentRef.current);
  }, [contentRef]);
  
  // Helper to log menu position information consistently
  const logMenuPosition = useCallback((source, x, y, rect) => {
    console.log(`📋 [${source}] Menu position: x=${Math.round(x)}, y=${Math.round(y)}`, {
      rect: rect ? {
        left: Math.round(rect.left),
        top: Math.round(rect.top),
        width: Math.round(rect.width),
        height: Math.round(rect.height)
      } : 'No rect provided',
      scroll: {
        x: window.scrollX,
        y: window.scrollY
      },
      viewport: {
        width: window.innerWidth,
        height: window.innerHeight
      }
    });
  }, []);
  
  // IMPROVED helper to check if element is still in DOM
  const isElementInDOM = useCallback((element) => {
    if (!element) return false;
    
    try {
      // First check if the element is still connected to the document
      if (element.isConnected === false) {
        return false;
      }
      
      // Then check if it's attached to the document
      return document.contains(element);
    } catch (error) {
      console.warn("Error checking if element is in DOM:", error);
      return false;
    }
  }, []);
  
  // Helper to check if the DOM is ready for manipulation
  const isDOMReady = useCallback(() => {
    return contentRef.current && isElementInDOM(contentRef.current);
  }, [contentRef, isElementInDOM]);
  
  // Function to clean up empty divs created during DOM operations
  const cleanupEmptyDivs = useCallback(() => {
    if (!contentRef.current || !componentMounted.current) return;
    
    // Use setTimeout to defer DOM operations until after React's reconciliation
    setTimeout(() => {
      try {
        // Verify contentRef is still valid
        if (!contentRef.current || !document.body.contains(contentRef.current)) {
          console.log("Content ref no longer valid, skipping cleanup");
          return;
        }
        
        // Find and remove any empty divs that might have been created by DOM operations
        const emptyDivs = contentRef.current.querySelectorAll('div:empty:not(.article-inline-response)');
        
        if (emptyDivs.length > 0) {
          console.log(`Found ${emptyDivs.length} empty divs to clean up`);
          
          // Process each empty div
          Array.from(emptyDivs).forEach(div => {
            try {
              // Re-check that div is still in DOM
              if (!document.body.contains(div)) {
                console.warn("Empty div no longer in DOM");
                return;
              }
              
              const parent = div.parentNode;
              
              // Ensure parent exists and is in DOM
              if (!parent || !document.body.contains(parent)) {
                console.warn("Parent of empty div no longer in DOM");
                return;
              }
              
              // Verify div is still a child of parent
              let isChild = false;
              for (let i = 0; i < parent.childNodes.length; i++) {
                if (parent.childNodes[i] === div) {
                  isChild = true;
                  break;
                }
              }
              
              if (isChild) {
                // Safe to remove child
                parent.removeChild(div);
              } else {
                console.warn("Empty div is no longer a child of its parent");
              }
            } catch (err) {
              console.warn("Error removing empty div:", err);
            }
          });
        }
      } catch (error) {
        console.error("Error cleaning up empty divs:", error);
      }
    }, 0);
  }, [contentRef, componentMounted]);
  
  // Modify removeTemporaryHighlighting to check parent-child relationship
  const removeTemporaryHighlighting = useCallback(() => {
    console.log("Removing temporary highlight");
    
    try {
      // First check if component is still mounted
      if (!componentMounted.current) {
        console.log("Component unmounted, skipping highlight removal");
        return;
      }
      
      // Remove any existing temporary highlight
      if (currentHighlightSpanRef.current) {
        try {
          // Ensure the highlight is in the DOM
          if (!document.body.contains(currentHighlightSpanRef.current)) {
            console.log("Highlight no longer in DOM, cleaning up reference");
            currentHighlightSpanRef.current = null;
            return;
          }
          
          const parent = currentHighlightSpanRef.current.parentNode;
          // Get the text content before we do anything else
          const text = currentHighlightSpanRef.current.textContent || "";
          
          // Only proceed if parent exists and is in DOM
          if (parent && document.body.contains(parent)) {
            // Create a text node to preserve the text - ensure this happens before any DOM manipulation
            const textNode = document.createTextNode(text);
            
            // Verify parent-child relationship before replacing
            let isChild = false;
            for (let i = 0; i < parent.childNodes.length; i++) {
              if (parent.childNodes[i] === currentHighlightSpanRef.current) {
                isChild = true;
                break;
              }
            }
            
            if (isChild) {
              try {
                // Safe to replace child - with try/catch for additional safety
                parent.replaceChild(textNode, currentHighlightSpanRef.current);
                console.log("Temporary highlight removed");
              } catch (replaceError) {
                console.error("Error during replaceChild operation:", replaceError);
                // In case of error, attempt to insert the text node as a fallback
                try {
                  parent.insertBefore(textNode, currentHighlightSpanRef.current);
                  parent.removeChild(currentHighlightSpanRef.current);
                  console.log("Temporary highlight removed using fallback method");
                } catch (fallbackError) {
                  console.error("Fallback replacement also failed:", fallbackError);
                }
              }
            } else {
              console.warn("Highlight no longer a child of its parent, can't remove");
            }
          } else {
            console.warn("Parent of highlight no longer in DOM");
          }
        } catch (replaceError) {
          console.error("Error replacing highlight with text:", replaceError);
        }
        
        // Reset reference regardless of outcome
        currentHighlightSpanRef.current = null;
      }
    } catch (error) {
      console.error("Error removing temporary highlight:", error);
      // Reset reference on error too
      currentHighlightSpanRef.current = null;
    }
  }, [componentMounted]);

  // Modify applyTemporaryHighlighting to ensure parent-child relationships
  const applyTemporaryHighlighting = useCallback((range, selectedText) => {
    console.log("Applying temporary highlighting");
    
    try {
      // First check if component is still mounted
      if (!componentMounted.current) {
        console.log("Component unmounted, skipping highlight application");
        return;
      }
      
      // Safety check - make sure we aren't highlighting empty text
      if (!range || range.collapsed) {
        console.log("Range is empty or collapsed, not highlighting");
        return;
      }
      
      // Get the selected text from the range if not provided
      const actualText = selectedText || (range ? range.toString().trim() : "");
      
      if (!actualText || actualText.length === 0) {
        console.log("Selected text is empty, not highlighting");
        return;
      }
      
      // First clean up any existing temporary highlights
      removeTemporaryHighlighting();
      
      // Create the highlight span
      const span = document.createElement("span");
      span.className = "temp-highlight";
      span.style.display = "inline";
      span.style.backgroundColor = "rgba(124, 58, 237, 0.2)"; // Purple highlight
      span.style.borderRadius = "2px";
      span.style.padding = "1px 0";
      
      // IMPORTANT: Don't set the text content directly - we'll surround the existing selection instead
      
      // Store reference to our highlight span
      currentHighlightSpanRef.current = span;
      
      try {
        // Ensure the range containers are still in the DOM
        if (!document.body.contains(range.startContainer) || !document.body.contains(range.endContainer)) {
          console.warn("Range containers are no longer in DOM, aborting highlight");
          return;
        }
        
        // IMPORTANT: Use surroundContents instead of deleteContents and insertNode
        // This preserves the original content and surrounds it with our highlight span
        // Create a clone of the range to avoid modifying the user's actual selection
        const highlightRange = range.cloneRange();
        
        try {
          // Try to surround the contents with our span
          highlightRange.surroundContents(span);
          console.log("Successfully applied highlight using surroundContents");
        } catch (surroundError) {
          // If surroundContents fails (can happen with complex selections across elements),
          // fall back to a more complex approach
          console.warn("surroundContents failed, using fallback method:", surroundError);
          
          // Create a document fragment for our selection
          const fragment = document.createDocumentFragment();
          
          // Clone the contents of the range
          const contents = highlightRange.cloneContents();
          
          // Append the contents to our span
          span.appendChild(contents);
          
          // Append the span to the fragment
          fragment.appendChild(span);
          
          // Clear the range's contents and insert our fragment
          highlightRange.deleteContents();
          highlightRange.insertNode(fragment);
          
          console.log("Applied highlight using fallback method");
        }
      } catch (error) {
        console.error("Error applying highlight:", error);
      }
      
      // Ensure no empty spans are left behind
      if (componentMounted.current) {
        cleanupEmptyDivs();
      }
      
    } catch (error) {
      console.error("Error in applyTemporaryHighlighting:", error);
    }
  }, [removeTemporaryHighlighting, cleanupEmptyDivs, componentMounted]);

  // Function to apply stored highlights to the article content
  const applyStoredHighlights = useCallback(() => {
    // Ensure component is mounted and we have highlights to apply
    if (!componentMounted.current || !highlights.length || !contentRef.current) {
      return;
    }

    console.log("Applying stored highlights to content", highlights.length);
    
    try {
      // Apply each highlight in the stored highlights array
      highlights.forEach(highlight => {
        console.log("Processing highlight:", highlight.id);
        // Implementation would go here - we're just stubbing it for now
        // In a full implementation, you would find the text in the DOM and wrap it
      });
    } catch (error) {
      console.error("Error applying stored highlights:", error);
    }
  }, [highlights, contentRef]);

  // Handle interactions from the highlight menu (explain, expand, deep dive)
  const handleInteraction = useCallback(async (data) => {
    try {
      // Start loading state
      setInteractionInProgress(true);
      setShowMenu(false); // Hide the menu while processing
      
      console.log("Processing interaction:", data.interaction_type, "for text:", selectedText);
      
      // If no text is selected, show an error and return
      if (!selectedText || selectedText.trim() === '') {
        setStatusMessage("Please select some text first");
        setTimeout(() => setStatusMessage(""), 2000);
        setInteractionInProgress(false);
        return;
      }
      
      // Apply temporary highlighting to the selection
      if (storedRange) {
        try {
          applyTemporaryHighlighting(storedRange, selectedText);
        } catch (highlightError) {
          console.error("Failed to apply highlight:", highlightError);
        }
      }
      
      // Prepare the request
      const interactionData = {
        selected_text: selectedText,
        interaction_type: data.interaction_type,
        article_id: article.id,
        surrounding_context: "Context extracted from the surrounding paragraphs would go here",
        user_id: user?.id || "anonymous"
      };
      
      // In a real implementation, this would make an API call
      // For now, we'll simulate a response after a delay
      setTimeout(() => {
        // Simulate response based on interaction type
        let responseText = "";
        
        switch (data.interaction_type) {
          case "explain":
            responseText = `Here's an explanation of "${selectedText}": This is a simulated response explaining the selected content.`;
            break;
          case "expand":
            responseText = `Expanding on "${selectedText}": This is a simulated response providing more detail about the selected content.`;
            break;
          case "deep_dive":
            responseText = `Deep dive into "${selectedText}": This is a simulated in-depth analysis of the selected content.`;
            break;
          default:
            responseText = "Interaction processed successfully.";
        }
        
        // Store the response
        setResponses(prev => [...prev, {
          id: Date.now().toString(),
          text: responseText,
          type: data.interaction_type,
          selection: selectedText
        }]);
        
        // Reset states
        setInteractionInProgress(false);
        removeTemporaryHighlighting();
        
        // Show success message briefly
        setStatusMessage("Response generated!");
        setTimeout(() => setStatusMessage(""), 2000);
        
      }, 1500); // Simulate API delay
      
    } catch (error) {
      console.error("Error handling interaction:", error);
      setStatusMessage("Error processing your request");
      setTimeout(() => setStatusMessage(""), 3000);
      setInteractionInProgress(false);
      removeTemporaryHighlighting();
    }
  }, [
    selectedText, 
    article, 
    user, 
    storedRange, 
    applyTemporaryHighlighting, 
    removeTemporaryHighlighting, 
    setResponses, 
    setStatusMessage, 
    setInteractionInProgress
  ]);

  // Add CSS to ensure the highlight menu is visible throughout the app
  useEffect(() => {
    // Add CSS to ensure the highlight menu is visible throughout the app
    const style = document.createElement('style');
    style.textContent = `
      /* Global override to ensure highlight menu visibility */
      #root {
        overflow: visible !important;
      }
      body {
        overflow-x: hidden !important;
      }
      .article-highlight-menu {
        background-color: rgba(30, 41, 59, 0.95) !important;
        border: 1px solid rgba(124, 58, 237, 0.5) !important;
        border-radius: 8px !important;
        padding: 8px !important;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3), 0 0 0 4px rgba(99, 102, 241, 0.2) !important;
        display: flex !important;
        gap: 8px !important;
        z-index: 9999999 !important;
        pointer-events: auto !important;
        opacity: 1 !important;
        visibility: visible !important;
      }
      .article-highlight-menu button {
        min-width: 40px !important;
        min-height: 40px !important;
      }
    `;
    document.head.appendChild(style);
    
    return () => {
      document.head.removeChild(style);
    };
  }, []);
  
  // Add a useEffect hook to update mount state
  useEffect(() => {
    // Set to true when mounted
    componentMounted.current = true;
    
    // Set to false when unmounting
    return () => {
      console.log("Component unmounting - setting mounted flag to false");
      componentMounted.current = false;
      
      // Clear any global variables we set
      if (window) {
        window._lastRange = null;
        window._lastSelectionTime = null;
        window._hasActiveSelection = false;
        window._articleReaderInstance = null;
      }
      
      // Also clear our menu state (use a try-catch to handle potential React errors)
      try {
        setShowMenu(false);
      } catch (error) {
        // Ignore errors during unmount
      }
    };
  }, []);

  // Global escape key handler to close menu
  useEffect(() => {
    const handleEscKey = (e) => {
      if (e.key === 'Escape' && showMenu) {
        console.log("Escape key pressed - closing menu");
        setShowMenu(false);
        removeTemporaryHighlighting(); // Remove temporary highlighting when menu is closed
      }
    };
    
    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [showMenu, removeTemporaryHighlighting]);
  
  useEffect(() => {
    console.log("Article content updated or component mounted");
    
    // Update article content when article changes
    const newContent = typeof article.content === 'string' 
      ? article.content 
      : article.content && article.content.paragraphs 
        ? article.content.paragraphs.join('\n\n')
        : "No content available";
        
    setArticleContent(newContent);
    
    // Mark content as not ready, then set it ready after a delay
    setContentReady(false);
    
    // Allow time for ReactMarkdown to render content
    const readyTimer = setTimeout(() => {
      console.log("Content marked as ready for interaction");
      setContentReady(true);
    }, 500);
    
    return () => clearTimeout(readyTimer);
  }, [article]);
  
  // Apply highlights after content is ready
  useEffect(() => {
    if (!contentReady || highlights.length === 0) return;
    
    console.log("Content ready, applying highlights:", highlights.length);
    
    // Use a timeout to ensure DOM is fully rendered
    const timer = setTimeout(() => {
      applyStoredHighlights();
    }, 300);
    
    return () => clearTimeout(timer);
  }, [highlights, contentReady, articleContent]);
  
  // Function to get accurate selection position
  const getSelectionPosition = useCallback(() => {
    // Get the current selection
    const selection = window.getSelection();
    
    // Return early if no selection
    if (!selection.rangeCount) {
      return { left: 0, top: 0, width: 0, height: 0, right: 0, bottom: 0 };
    }
    
    // Try to get the selection range
    try {
      const range = selection.getRangeAt(0);
      
      // Get the bounding rectangle
      let rect = range.getBoundingClientRect();
      
      // If the rectangle has zero dimensions, we need to use alternative approach
      if (rect.width === 0 || rect.height === 0) {
        console.log("Selection rectangle has zero dimensions, using temporary span");
        
        // Create a temporary span to get dimensions
        const span = document.createElement('span');
        span.textContent = '.'; // Add content to ensure it has dimensions
        
        // Insert span at selection
        const tempRange = range.cloneRange();
        tempRange.insertNode(span);
        
        // Get the span's position
        rect = span.getBoundingClientRect();
        
        // Clean up - remove the span
        span.remove();
        
        console.log("Got position from temporary span:", rect);
      }
      
      return rect;
    } catch (e) {
      console.error("Error getting selection position:", e);
      
      // Return safe fallback
      return { 
        left: window.innerWidth / 2, 
        top: window.innerHeight / 2, 
        width: 0, 
        height: 0,
        right: window.innerWidth / 2,
        bottom: window.innerHeight / 2
      };
    }
  }, []);

  // Add a mousedown handler to better track selection start points
  useEffect(() => {
    if (!contentReady || !contentRef.current) return;
    
    // Initialize global state
    window._lastRange = null;
    window._hasActiveSelection = false;
    window._lastSelectionTime = null;
    window._lastSelectedRange = null;
    
    const handleMouseDown = (e) => {
      // Verify component is still mounted
      if (!contentRef.current || !isElementInDOM(contentRef.current)) {
        console.log("Component unmounting, skipping mousedown handler");
        return;
      }
      
      // Fix: safely use closest() by checking the event target first
      const target = e.target;
      const hasClosest = target.closest !== undefined;
      
      // Don't interfere with clicks on menu, buttons, or existing highlights
      if (
        (hasClosest && target.closest('.article-highlight-menu')) ||
        (hasClosest && target.closest('.expanded-content')) ||
        (hasClosest && target.closest('button')) ||
        target.tagName === 'BUTTON' ||
        target.tagName === 'MARK' ||
        (hasClosest && target.closest('mark'))
      ) {
        return;
      }
      
      // Clear existing menu when starting a new selection
      if (showMenu) {
        setShowMenu(false);
      }
      
      // Store the initial click position for reference
      window._selectionStartPoint = {
        x: e.clientX,
        y: e.clientY
      };
      
      window._lastMouseX = e.clientX;
      window._lastMouseY = e.clientY;
      
      console.log("🖱️ Selection started at:", window._selectionStartPoint);
    };
    
    const contentElement = contentRef.current;
    contentElement.addEventListener('mousedown', handleMouseDown);
    
    return () => {
      // Make sure the element is still valid before removing the listener
      if (contentElement && isElementInDOM(contentElement)) {
        try {
          contentElement.removeEventListener('mousedown', handleMouseDown);
        } catch (err) {
          console.warn("Error removing mousedown listener:", err);
        }
      }
    };
  }, [contentReady, contentRef, showMenu, isElementInDOM]);

  // Helper to close menu and reset state
  const closeMenu = useCallback(() => {
    console.log("Closing highlight menu");
    setShowMenu(false);
    setMenuPosition({ x: 0, y: 0 });
    // Remove any temporary highlight when closing menu
    if (currentHighlightSpanRef.current && isElementInDOM(currentHighlightSpanRef.current)) {
      removeTemporaryHighlighting();
    }
  }, [isElementInDOM, removeTemporaryHighlighting]);
  
  // Update the testHighlightMenu function to simulate a realistic selection
  const testHighlightMenu = useCallback(() => {
    console.log("Testing highlight menu");
    
    // Set a test selection
    setSelectedText("This is a test selection that simulates highlighting text in the article");
    
    // Get the main content area for better positioning
    let contentRect = null;
    if (contentRef.current) {
      contentRect = contentRef.current.getBoundingClientRect();
    }
    
    // Position in the middle of the content area if available, otherwise in the viewport
    const centerX = contentRect ? contentRect.left + (contentRect.width / 2) : window.innerWidth / 2;
    const centerY = contentRect ? contentRect.top + (contentRect.height / 4) : window.innerHeight / 3;
    
    // Include scroll position
    const menuX = centerX + window.scrollX;
    const menuY = centerY + window.scrollY;
    
    // Update menu position
    setMenuPosition({ 
      x: menuX, 
      y: menuY
    });
    
    // Clear any status message
    setStatusMessage("");
    
    // Show the menu
    setShowMenu(true);
    
    // Log the action
    console.log("Test menu should be visible at:", { 
      x: menuX, 
      y: menuY, 
      scrollY: window.scrollY,
      contentRect: contentRect ? {
        top: contentRect.top,
        left: contentRect.left,
        width: contentRect.width,
        height: contentRect.height
      } : 'No content rect'
    });
    
    // Log to DOM as well
    setTimeout(() => {
      const menuElement = document.getElementById('article-highlight-menu');
      if (menuElement) {
        const rect = menuElement.getBoundingClientRect();
        console.log("Menu element found in DOM at: ", {
          top: rect.top,
          left: rect.left,
          width: rect.width,
          height: rect.height
        });
      } else {
        console.warn("Menu element NOT found in DOM after menu shown");
      }
    }, 100);
  }, [setSelectedText, setMenuPosition, setShowMenu, setStatusMessage, contentRef]);

  // Helper text for highlighting - modify to be simpler since we allow all users to interact
  const renderHelperText = () => {
    return (
      <div className="mb-4 py-3 px-4 rounded-lg bg-gray-900/20 border border-gray-800">
        <div className="flex items-center">
          <svg
            className="w-5 h-5 text-purple-400 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span className="text-purple-400 font-medium">Interactive Article:</span>
          <span className="text-gray-300 ml-2">Select any text to get explanations, additional context, or deeper insights.</span>
        </div>
      </div>
    );
  };

  // Update the renderArticleContent function to remove authentication check
  const renderArticleContent = () => {
    return (
      <div 
        className="leading-relaxed text-lg text-neutral-200 article-content"
        ref={contentRef}
        onMouseDown={() => {
          // Reset selection UI
            setShowMenu(false);
          setSelectedText("");
        }}
        onMouseUp={(e) => {
          // Handle text selection on mouse up (for all users)
          const selection = window.getSelection();
          if (selection && selection.toString().trim() !== '') {
            const range = selection.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            
            // Calculate position for the menu
            const menuX = rect.left + rect.width / 2;
            const menuY = rect.top;
            
            setSelectedText(selection.toString().trim());
          setMenuPosition({ x: menuX, y: menuY });
          setShowMenu(true);
          }
        }}
      >
        {/* Helper text for highlighting */}
        {renderHelperText()}
        
        {/* Render article paragraphs */}
        {article.content && article.content.paragraphs && article.content.paragraphs.map((paragraph, index) => (
          <RenderParagraph 
            key={index}
            content={paragraph}
            onHighlightClick={(highlightId) => {
              console.log(`Highlight clicked: ${highlightId}`);
              // You can add logic here to handle highlight clicks
            }}
          />
        ))}
        
        {/* Article is loading */}
        {!article.content && <div className="text-gray-400">Loading content...</div>}
      </div>
    );
  };

  // Update handleTextSelection to allow text selection for all users
  const handleTextSelection = useCallback(
    debounce((event, exactRange, exactText) => {
      try {
        // First check if component is still mounted
      if (!componentMounted.current) {
          console.log("Component unmounted, skipping text selection handling");
        return;
      }
      
        // Use the provided exact range and text when available
        if (exactRange && exactText) {
          console.log("Using exact selection data", exactText);
          setSelectedText(exactText);
          const rect = exactRange.getBoundingClientRect();
          
          // Calculate position for the menu
          const menuX = rect.left + rect.width / 2;
          const menuY = rect.top;
          
          // Log the menu position for debugging
          logMenuPosition("Exact Selection", menuX, menuY, rect);
          
          setMenuPosition({ x: menuX, y: menuY });
          setShowMenu(true);
          
          // Store range for later use
          setStoredRange(exactRange);
                    return;
                  }
                  
        // Get the current selection
        const selection = window.getSelection();
        
        // Make sure we have a valid selection
        if (!selection || selection.rangeCount === 0 || selection.toString().trim() === '') {
        return;
      }
      
        // Get the first range of the selection (the one the user made)
        const range = selection.getRangeAt(0);
        
        // Store the range for future use (e.g., highlighting)
        setStoredRange(range);
        
        // Get the text content of the selection
        const selectedText = range.toString().trim();
        
        if (selectedText === '') {
          return;
        }
        
        // Set the selected text in state
        setSelectedText(selectedText);
        
        // Get the bounding client rect of the selection
            const rect = range.getBoundingClientRect();
            
        // Calculate position for the menu (center horizontally, above the selection)
            const menuX = rect.left + rect.width / 2;
            const menuY = rect.top;
            
        // Set the menu position
        logMenuPosition("Text Selection", menuX, menuY, rect);
            setMenuPosition({ x: menuX, y: menuY });
        
        // Show the menu
            setShowMenu(true);
      } catch (error) {
        console.error("Error processing text selection:", error);
      }
    }, 250), // 250ms debounce to prevent rapid selection changes
    [componentMounted, setSelectedText, setMenuPosition, setShowMenu, logMenuPosition, setStoredRange]
  );

  // New promotional component for Heretic.school
  const renderPromoSection = () => {
    return (
      <div className="mt-12 pt-8 border-t border-gray-800">
        <div className="bg-gray-900/30 backdrop-blur-sm border border-indigo-500/20 rounded-xl p-6 shadow-xl transform transition-all duration-300 hover:border-indigo-500/30 hover:shadow-indigo-500/10">
          <h3 className="text-xl font-bold mb-3">
            <span className="text-transparent bg-clip-text bg-gradient-to-br from-purple-400 to-indigo-400">
              Discover More with Heretic.School
            </span>
          </h3>
          
          <p className="text-gray-300 mb-4">
            Personalized homeschool learning tailored to your teen's interests. Generate custom projects, explore interactive articles, and make education naturally engaging.
          </p>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div className="flex items-start p-3 bg-gray-900/50 rounded-lg border border-gray-800">
              <div className="h-8 w-8 rounded-full bg-purple-900/50 flex items-center justify-center mr-3 flex-shrink-0">
                <span className="text-lg">✨</span>
              </div>
              <div>
                <h4 className="font-medium text-indigo-300 mb-1">Project Generator</h4>
                <p className="text-sm text-gray-400">Create custom learning projects based on interests in seconds</p>
              </div>
            </div>
            
            <div className="flex items-start p-3 bg-gray-900/50 rounded-lg border border-gray-800">
              <div className="h-8 w-8 rounded-full bg-purple-900/50 flex items-center justify-center mr-3 flex-shrink-0">
                <span className="text-lg">🧠</span>
              </div>
              <div>
                <h4 className="font-medium text-indigo-300 mb-1">Interactive Learning</h4>
                <p className="text-sm text-gray-400">Engage with content through AI-powered insights and explanations</p>
              </div>
            </div>
          </div>
          
          <div className="flex flex-wrap gap-3 justify-center">
            <a 
              href="/home" 
              className="px-5 py-2 bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white font-bold rounded-lg transition-all duration-300 hover:scale-[1.02] hover:shadow-lg"
            >
              Explore Full Curriculum
            </a>
            <a 
              href="/generate" 
              className="px-5 py-2 border-2 border-indigo-500 text-indigo-300 hover:bg-indigo-900/20 font-bold rounded-lg transition-all duration-300"
            >
              Generate New Project
            </a>
          </div>
        </div>
      </div>
    );
  };

  // Return the component
  return (
    <div className="max-w-3xl mx-auto rounded-xl overflow-hidden border border-gray-800 bg-gray-900/50 backdrop-blur-lg shadow-xl transform transition-all duration-300 hover:border-indigo-500/30 hover:shadow-indigo-500/10" ref={articleRef}>
      <div className="p-6">
        <div className="article-header mb-8">
          <h1 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-indigo-400 mb-4">
            {article.title}
          </h1>
          <div className="flex items-center space-x-4 text-sm text-gray-400 mb-2">
            <span className="flex items-center">
              <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
              </svg>
              {formatDate(article.created_at || new Date().toISOString())}
            </span>
            {article.content?.metadata?.reading_time && (
              <span className="flex items-center">
                <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
                {article.content.metadata.reading_time} read
              </span>
            )}
            {article.content?.metadata?.difficulty && (
              <span className="flex items-center">
                <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path>
                </svg>
                {article.content.metadata.difficulty}
              </span>
            )}
          </div>

          {article.slug && (
            <div className="text-sm text-gray-500 mb-4">
              <span className="text-gray-400">Share: </span>
              <a 
                href={`${window.location.origin}/articles/${article.slug}`} 
                className="text-indigo-400 hover:text-indigo-300 transition-colors"
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(`${window.location.origin}/articles/${article.slug}`);
                  alert('Link copied to clipboard!');
                }}
              >
                Copy Link
              </a>
            </div>
          )}
        </div>

        {/* Display debug button only for authenticated users */}
        {isAuthenticated && (
        <div className="mb-4">
          <button
            onClick={testHighlightMenu}
            className="px-3 py-1 text-xs font-medium bg-indigo-900/50 text-indigo-300 border border-indigo-800/50 rounded-md hover:bg-indigo-800/50"
          >
            Debug: Test Highlight Menu
          </button>
        </div>
        )}

        {/* Article content */}
        <div 
          className="article-content-wrapper relative blog-content"
          ref={contentRef}
          onMouseDown={() => {
            // Reset selection UI
            setShowMenu(false);
            setSelectedText("");
          }}
          onMouseUp={(e) => {
            // Handle text selection on mouse up (for all users)
            const selection = window.getSelection();
            if (selection && selection.toString().trim() !== '') {
              const range = selection.getRangeAt(0);
              const rect = range.getBoundingClientRect();
              
              // Calculate position for the menu
              const menuX = rect.left + rect.width / 2;
              const menuY = rect.top;
              
              setSelectedText(selection.toString().trim());
              setMenuPosition({ x: menuX, y: menuY });
              setShowMenu(true);
            }
          }}
        >
          {/* Helper text for highlighting */}
          {renderHelperText()}
          
          {/* Render article paragraphs */}
          {article.content && article.content.paragraphs && article.content.paragraphs.map((paragraph, index) => (
            <RenderParagraph 
              key={index}
              content={paragraph}
              onHighlightClick={(highlightId) => {
                console.log(`Highlight clicked: ${highlightId}`);
                // You can add logic here to handle highlight clicks
              }}
            />
          ))}
          
          {/* Article is loading */}
          {!article.content && <div className="text-gray-400">Loading content...</div>}
        </div>
      </div>

      {isLoading && (
        <div className="fixed inset-0 bg-gray-900/70 backdrop-blur-sm flex items-center justify-center z-[100000]">
          <div className="bg-gray-800 rounded-xl p-6 shadow-2xl border border-indigo-500/20 max-w-md w-full">
            <div className="flex items-center justify-center mb-4">
              <svg className="animate-spin h-8 w-8 text-indigo-500 mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              <span className="text-lg font-medium text-white">Processing your request...</span>
            </div>
            <p className="text-gray-300 text-sm text-center">Generating a thoughtful response based on your selection.</p>
          </div>
        </div>
      )}
      
      {showMenu && componentMounted.current && selectedText && createPortal(
        statusMessage ? (
          <div 
            className="fixed px-4 py-2 rounded-lg shadow-xl bg-indigo-900/90 text-white z-[9999999]"
            style={{
              top: `${menuPosition.y}px`,
              left: `${menuPosition.x}px`,
              transform: 'translate(-50%, -100%)', // Position above the selection
              pointerEvents: 'none' // Allow clicking through the status message
            }}
          >
            {statusMessage}
          </div>
        ) : (
          <ArticleHighlightMenu
            ref={menuRef}
            position={menuPosition}
            onExplain={handleInteraction}
            onExpand={handleInteraction}
            onDeepDive={handleInteraction}
            onClose={closeMenu}
            isLoading={interactionInProgress}
            selectedText={selectedText}
            style={{
              position: 'fixed',
              zIndex: 9999999
            }}
          />
        ),
        document.body
      )}
      
      {/* Add the promotional section at the end of the article */}
      {renderPromoSection()}
    </div>
  );
};

export default ArticleReader; 