import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import posthog from "posthog-js";

// Gradient text component reused from LandingPage
const Gradient = ({ children }) => (
  <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-indigo-500">
    {children}
  </span>
);

const AboutUs = () => {
  React.useEffect(() => {
    posthog.capture("about_us_page_viewed");
  }, []);

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <Header />
      <main>
        <section className="py-20 bg-gray-900">
          <div className="container mx-auto px-4">
            <h1 className="text-4xl font-bold text-center mb-12">
              <Gradient>About Us</Gradient>
            </h1>
            <div className="flex flex-col items-center">
              <img
                src="/spaceshuttleandrew.png"
                alt="Andrew Garber"
                className="w-32 h-32 rounded-full mb-8"
              />
              <h2 className="text-2xl font-bold mb-4">
                Andrew Garber, Founder @ Heretic.School
              </h2>
              <div className="max-w-3xl text-center">
                <p className="mb-4">
                  Hi! I'm Andrew, a former homeschooler and the founder of
                  Heretic.School. I was a student in public school until my
                  Sophomore year of high school, when I decided to take my
                  education into my own hands. I know first-hand how much of a
                  difference personalized learning can make, so I decided to
                  create a platform that makes it easy to start homeschooling.
                </p>
                <p className="mb-4">
                  My path to becoming a homeschooler was not straightforward.
                  The idea of leaving the relative "safety" of public school,
                  the conventional path, was a hard sell to my parents. I had to
                  convince them that homeschooling was a viable option, that I
                  would be able to get into a good college, and have the basis
                  for a successful career. I spent months trying to convince
                  them that homeschooling was something we could make work to
                  give me the kind of education I wanted.
                </p>
                <p className="mb-4">
                  That's why I created Heretic.School - melding the best parts
                  of various curricula and learning styles into a cohesive
                  curriculum was difficult, and simply not available in one
                  place. I wanted to remove all of the friction from the process
                  of homeschooling, and learning in general, so that it would be
                  push-button easy to get started. Homeschooling has given me
                  the freedom to create Heretic.School, and I want to give that
                  same freedom to other students for whatever their interest is.
                  Even once I started homeschooling it wasn't as personalized as
                  I had dreamed it would be. I had to spend hours searching for
                  resources, and I still had to piece together a curriculum that
                  would work for me. Heretic.School is designed to make that
                  process easy and engaging for parents and students alike.
                </p>
                <p className="mb-4">
                  I love talking to anyone who is homeschooling or thinking
                  about it, and am always open to feedback and suggestions. Feel
                  free to reach out to me at{" "}
                  <Gradient>
                    <a href="mailto:andrew@heretic.school">
                      andrew@heretic.school
                    </a>
                  </Gradient>{" "}
                  with any questions or comments.
                </p>
                <p className="mb-4">
                  <Gradient>
                    <a
                      href="https://open.spotify.com/episode/2WGGwdK9Tg52Y74KGwX8ZC?si=0-WepyTNSBO5FBr7Nm6eHg"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ➡️ If you want to hear more about my story and the genesis
                      of Heretic.School, check out this podcast episode. ⬅️
                    </a>
                  </Gradient>{" "}
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="py-12 bg-gray-800">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-2xl font-bold mb-6">Our Mission</h2>
            <p className="text-gray-300 max-w-3xl mx-auto mb-6">
              At Heretic.School, we believe that education should be
              personalized to each student's interests and learning style. We're
              on a mission to make homeschooling accessible and engaging for
              exceptional teens who deserve more than one-size-fits-all
              education.
            </p>

            <div className="mt-12">
              <Link
                to="/curriculum"
                className="bg-gradient-to-r from-purple-500 to-indigo-500 text-white px-6 py-3 rounded-lg font-semibold hover:from-purple-600 hover:to-indigo-600 transition duration-300 inline-block mr-4"
              >
                Explore Our Curriculum
              </Link>
              <Link
                to="/"
                className="border border-purple-500 text-purple-300 px-6 py-3 rounded-lg font-semibold hover:bg-purple-900/30 transition duration-300 inline-block"
              >
                Try Nternalize Tool
              </Link>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default AboutUs;
