import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { supabase } from "./supabaseClient";
import Auth from "./components/Auth";
import Form from "./components/Form";
import SafeGraph from "./Graph";
import LandingPage from "./components/LandingPage";
import AboutUs from "./components/AboutUs";
import ExtensionDownload from "./components/ExtensionDownload";
import BlogList from "./components/BlogList";
import BlogPost from "./components/BlogPost";
import posthog from "posthog-js";
import { Analytics } from "@vercel/analytics/react";
import ErrorBoundary from "./components/ErrorBoundary";
import Transcript from "./components/Transcript";
import Plans from "./components/Plans";
import PasswordReset from "./components/PasswordReset";
import SafeHome from "./components/Home";
import Admin from "./components/Admin";
import ArticleSystem from "./components/ArticleSystem";

// Define RequireAuth before AppContent
const RequireAuth = ({ children }) => {
  const location = useLocation();
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;

    async function getSession() {
      const {
        data: { session },
        error,
      } = await supabase.auth.getSession();

      if (mounted) {
        if (error) console.error("Error:", error.message);
        setSession(session);
        setLoading(false);
      }
    }

    getSession();

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      if (mounted) {
        setSession(session);
        setLoading(false);
      }
    });

    return () => {
      mounted = false;
      subscription?.unsubscribe();
    };
  }, []);

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center bg-gray-900">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500" />
      </div>
    );
  }

  if (!session) {
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }

  return React.cloneElement(children, { session: session, user: session.user });
};

// Then define AppContent
const AppContent = () => {
  const [session, setSession] = useState(null);
  const location = useLocation();

  useEffect(() => {
    let mounted = true;

    async function getSession() {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (mounted) setSession(session);
    }

    getSession();

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      if (mounted) setSession(session);
    });

    return () => {
      mounted = false;
      subscription?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    posthog.capture("$pageview", {
      path: location.pathname,
      url: window.location.href,
    });
  }, [location]);

  useEffect(() => {
    if (session && session.user) {
      posthog.identify(session.user.id, {
        email: session.user.email,
        name: session.user.user_metadata?.full_name,
      });
      posthog.capture("user_logged_in");
    }
  }, [session]);

  return (
    <Routes>
      <Route path="/" element={<LandingPage session={session} />} />
      <Route path="/project-generation" element={<LandingPage session={session} />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/blog" element={<BlogList />} />
      <Route path="/blog/:slug" element={<BlogPost />} />
      <Route path="/auth" element={<Auth session={session} />} />
      <Route path="/login" element={<Navigate to="/auth" replace />} />
      <Route path="/reset-password" element={<PasswordReset />} />
      
      <Route
        path="/form"
        element={
          <RequireAuth>
            <Form />
          </RequireAuth>
        }
      />
      <Route
        path="/dashboard"
        element={
          <RequireAuth>
            <Form />
          </RequireAuth>
        }
      />
      <Route
        path="/home"
        element={
          <RequireAuth>
            <SafeHome />
          </RequireAuth>
        }
      />
      <Route path="/extension" element={<ExtensionDownload />} />
      <Route path="/plans" element={<Plans />} />
      <Route
        path="/graph"
        element={
          <RequireAuth>
            <SafeGraph />
          </RequireAuth>
        }
      />
      <Route
        path="/transcript"
        element={
          <RequireAuth>
            <Transcript />
          </RequireAuth>
        }
      />
      <Route path="/admin" element={<Admin />} />
      <Route
        path="/articles"
        element={
          <RequireAuth>
            <ArticleSystem />
          </RequireAuth>
        }
      />
      {/* Articles routes */}
      <Route path="/articles/:slugOrId" element={<ArticleSystem session={session} />} />
      <Route path="/article/:slugOrId" element={<Navigate replace to="/articles/:slugOrId" />} />
      {/* Catch all route for 404s */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

// Finally define App
const App = () => {
  useEffect(() => {
    const handleError = (event) => {
      if (
        event.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        event.stopImmediatePropagation();
      }
    };

    window.addEventListener("error", handleError);

    return () => {
      window.removeEventListener("error", handleError);
    };
  }, []);

  return (
    <ErrorBoundary>
      <Router>
        <AppContent />
        <Analytics />
      </Router>
    </ErrorBoundary>
  );
};

export default App;
