import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import posthog from "posthog-js";
import { track } from "@vercel/analytics";
import { supabase } from "../supabaseClient";

const PlanCard = ({
  title,
  price,
  features,
  isPopular,
  onSelect,
  imageSrc,
}) => {
  const isTrial = title === "Trial Plan";

  return (
    <div className="relative p-[2px] group h-full">
      {/* Gradient border effect from Home.js */}
      <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 via-cyan-500/20 to-teal-500/20 rounded-xl blur-sm" />

      <div
        className="bg-gray-900/90 backdrop-blur-sm rounded-xl p-6 relative border border-blue-500/10 shadow-[0_0_25px_rgba(59,130,246,0.15)] h-full flex flex-col"
        style={{
          boxShadow:
            "0 0 25px rgba(59,130,246,0.15), inset 0 0 20px rgba(59,130,246,0.1)",
        }}
      >
        <div className="text-center">
          <img
            src={imageSrc}
            alt={title}
            className="w-48 h-48 mx-auto mb-6 rounded-lg object-cover hover:scale-105 transition-transform duration-300"
          />
          <h3 className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-300 to-teal-300 mb-4">
            {title}
          </h3>
          <div className="text-3xl font-bold mb-8">
            {price === "0" ? (
              <div className="text-transparent bg-clip-text bg-gradient-to-r from-blue-300 to-teal-300">
                Free
              </div>
            ) : (
              <>
                <div className="text-base text-gray-400 line-through mb-1">
                  ${price}/month
                </div>
                <div className="flex flex-col items-center gap-2">
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-300 to-teal-300">
                    $49
                    <span className="text-sm text-gray-400">/month</span>
                  </span>
                  <span className="bg-gradient-to-r from-blue-500/10 to-teal-500/10 border border-blue-500/20 text-blue-300 px-3 py-1.5 rounded-lg text-sm font-medium">
                    82/100 Founding Member Discount $99 → $49
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
        <ul
          className={`mb-8 flex-grow flex flex-col ${
            isTrial ? "justify-between space-y-6" : "space-y-3"
          }`}
        >
          {features.map((feature, index) => (
            <li key={index} className="flex items-center text-gray-300">
              <svg
                className="w-5 h-5 text-teal-500 mr-3 flex-shrink-0"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <span className="text-sm">{feature}</span>
            </li>
          ))}
        </ul>
        <button
          onClick={onSelect}
          className="w-full py-2.5 px-4 rounded-lg font-semibold transition-all duration-300 transform hover:scale-105 bg-gradient-to-r from-blue-500 to-teal-500 text-white hover:from-blue-600 hover:to-teal-600 shadow-lg mt-auto"
        >
          Select Plan
        </button>
      </div>
    </div>
  );
};

const Plans = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(null);

  useEffect(() => {
    posthog.capture("plans_viewed", { type: "curriculum" });

    // Get current user
    const getCurrentUser = async () => {
      const { data } = await supabase.auth.getUser();
      if (data && data.user) {
        setUser(data.user);
      }
    };

    getCurrentUser();
  }, [location]);

  const handleSelectCurriculumPlan = (planType) => {
    posthog.capture("curriculum_plan_selected", { plan: planType });
    track("curriculum_plan_selected", { plan: planType });
    if (planType === "free") {
      navigate("/auth");
    } else {
      navigate("/auth", { state: { selectedPlan: "student" } });
    }
  };

  return (
    <div className="min-h-screen bg-gray-900">
      <Header />
      <div className="container mx-auto px-4 py-16">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-300 mb-4">
            Choose Your Plan
          </h2>

          <p className="text-xl text-gray-400">
            Start with our free plan or unlock unlimited potential with our student plan
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          <PlanCard
            title="Trial Plan"
            price="0"
            imageSrc="/vision.png"
            features={[
              "3 projects in each of 3 subject categories",
              "Basic progress tracking",
              "24hr email support",
              "Basic transcript generation",
              "Personalized Content and Project Generation",
              "AI-Powered Content Sidebar",
            ]}
            onSelect={() => handleSelectCurriculumPlan("free")}
          />
          <PlanCard
            title="Student Plan"
            price="99"
            imageSrc="/augmented.png"
            features={[
              "Everything in Trial Plan +",
              "Unlimited projects in unlimited subjects",
              "Advanced progress tracking",
              "3hr priority support",
              "Advanced transcript generation",
              "Personalized subject learning paths",
              "Cross-subject real-world projects",
              "Premium AI-powered project feedback",
            ]}
            isPopular={true}
            onSelect={() => handleSelectCurriculumPlan("student")}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Plans;
