import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "./Header";
import posthog from "posthog-js";
import { track } from "@vercel/analytics";

// Fixed list of available categories
const CATEGORIES = [
  "entrepreneurship",
  "education",
  "history",
  "astronomy",
  "political science",
  "economics",
  "geography",
  "earth sciences",
  "mathematics",
  "literature",
  "computer science",
  "law",
  "physics",
  "engineering",
  "medicine",
  "communications",
  "chemistry",
  "cultural studies",
  "arts",
  "finance",
  "philosophy",
  "psychology",
  "biology",
  "urban planning",
  "architecture",
  "physical fitness",
];

// List of quotes to display on the loading screen
const LOADING_QUOTES = [
  {
    text: "I've noticed a fascinating phenomenon in my twenty-five years of teaching: schools and schooling are increasingly irrelevant to the great enterprises of the planet. No one believes anymore that scientists are trained in science classes or politicians in civics classes or poets in English classes. The truth is that schools don't really teach anything except how to obey orders.",
    author: "John Taylor Gatto",
  },
  {
    text: "For the things we have to learn before we can do them, we learn by doing them.",
    author: "Aristotle",
  },
  {
    text: "When you take the free will out of education, that turns it into schooling.",
    author: "John Taylor Gatto",
  },
  {
    text: "Learning can only happen when a child is interested. If he's not interested, it's like throwing marshmallows at his head and calling it eating.",
    author: "Katrina Gutleben",
  },
  {
    text: "The aim of public education is not to spread enlightenment at all; it is simply to reduce as many individuals as possible to the same safe level, to breed and train a standardized citizenry, to put down dissent and originality.",
    author: "H.L. Mencken",
  },
  {
    text: "Throughout the centuries there were men who took first steps down new roads, armed with nothing but their own vision. The great creators, the thinkers, the artists, the scientists, the inventors, stood alone against the men of their time. Every new thought was opposed. Every new invention was denounced. But the men of unborrowed vision went ahead. They fought, they suffered, and they paid - but they won.",
    author: "Ayn Rand, The Fountainhead",
  },
  {
    text: "The secret of education lies in respecting the pupil.",
    author: "Ralph Waldo Emerson",
  },
  {
    text: "Our whole educational system, from the elementary schools to the universities, is increasingly turning out people who have never heard enough conflicting arguments to develop the skills and discipline required to produce a coherent analysis, based on logic and evidence.",
    author: "Thomas Sowell",
  },
  {
    text: "Education is the passport to the future, for tomorrow belongs to those who prepare for it today.",
    author: "Malcolm X",
  },
  {
    text: "I've concluded that genius is as common as dirt. We suppress genius because we haven't yet figured out how to manage a population of educated men and women. The solution, I think, is simple and glorious. Let them manage themselves.",
    author: "John Taylor Gatto",
  },
  {
    text: "Genius is an exceedingly common human quality, probably natural to most of us.",
    author: "John Taylor Gatto",
  },
  {
    text: "An investment in knowledge pays the best interest.",
    author: "Benjamin Franklin",
  },
  {
    text: "Whatever an education is, it should make you a unique individual, not a conformist; it should furnish you with an original spirit with which to tackle the big challenges; it should allow you to find values which will be your roadmap through life; it should make you spiritually rich, a person who loves whatever you are doing, wherever you are, whomever you are with; it should teach you what is important, how to live and how to die.",
    author: "John Taylor Gatto",
  },
  {
    text: "The process of forming, integrating and using concepts is not an automatic, but a volitional process—i.e., a process which uses both new and automatized material, but which is directed volitionally. It is not an innate, but an acquired skill; it has to be learned—it is the most crucially important part of learning—and all of man's other capacities depend on how well or how badly he learns it.",
    author: "Ayn Rand",
  },
  {
    text: "Intellectual growth should commence at birth and cease only at death.",
    author: "Albert Einstein",
  },
];

const Form = ({ user }) => {
  const [formData, setFormData] = useState({
    age: "",
    dreamjob: "",
    biggestPersonalInterest: "",
    feedbackType: "feedback_only",
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [step, setStep] = useState(1);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [recommendations, setRecommendations] = useState(null);
  const [isLoadingRecommendations, setIsLoadingRecommendations] =
    useState(false);
  const [currentAssessmentCategory, setCurrentAssessmentCategory] =
    useState(null);
  const [curriculumDescription, setCurriculumDescription] = useState("");
  const [assessment, setAssessment] = useState(null);
  const [assessmentAnswers, setAssessmentAnswers] = useState({});
  const [shortAnswer, setShortAnswer] = useState("");
  const [currentAssessmentIndex, setCurrentAssessmentIndex] = useState(0);
  const [units, setUnits] = useState({});
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);
  const [shuffledQuotes, setShuffledQuotes] = useState([]);
  const [fadeState, setFadeState] = useState("fade-in");
  const [currentFlowStep, setCurrentFlowStep] = useState("initial");
  const navigate = useNavigate();
  const location = useLocation();

  // Function to save units to localStorage
  const saveUnitsToLocalStorage = (units) => {
    try {
      localStorage.setItem("units_data", JSON.stringify(units));
      localStorage.setItem("setupComplete", "true");
    } catch (error) {
      console.error("Error saving units to localStorage:", error);
    }
  };

  // Define getStartRecommendations before it's used in useEffect
  const getStartRecommendations = async (retryState = null) => {
    try {
      setIsLoading(true);
      setError(null);

      // Reset the current flow to ensure we show the loading screen
      setCurrentFlowStep("loading");

      const requestData = retryState || {
        userId: user.id,
        age: formData.age,
        dreamjob: formData.dreamjob,
        biggestPersonalInterest: formData.biggestPersonalInterest,
      };

      console.log("Starting recommendations request with data:", requestData);
      console.log("Selected categories:", selectedCategories);

      // Track event in analytics
      posthog.capture("content_generation_started", {
        userId: requestData.userId,
        categories: selectedCategories,
        skipOnboarding: retryState?.skipOnboarding || false,
      });

      // Use passed categories if available, otherwise use selected categories
      const categoriesToProcess = retryState?.categories || selectedCategories;

      // Create an array of promises for each category
      const categoryPromises = categoriesToProcess.map((category) => {
        // Create a request for each individual category
        console.log(`Making request for category: ${category}`);
        return fetch(
          `${process.env.REACT_APP_API_URL}/api/get_category_recommendation`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId: requestData.userId,
              category: category,
              skipOnboarding: retryState?.skipOnboarding || false,
            }),
          }
        )
          .then(async (response) => {
            try {
              const responseData = await response.json();
              console.log(
                `Response for ${category}:`,
                response.status,
                responseData
              );

              // Handle assessment required (428) response
              if (
                response.status === 428 ||
                responseData.assessment_required ||
                responseData.error === "Onboarding required"
              ) {
                return {
                  status: 428,
                  category: responseData.category || category,
                  response: responseData,
                };
              }

              // Handle other errors
              if (!response.ok) {
                console.error(`API error for ${category}:`, responseData);
                return {
                  status: response.status,
                  category,
                  response: responseData,
                  error:
                    responseData.message ||
                    `Failed to generate content for ${category}`,
                };
              }

              return {
                status: 200,
                category,
                response: responseData,
              };
            } catch (error) {
              console.error(
                `Error processing response for ${category}:`,
                error
              );
              return {
                status: 500,
                category,
                error: `Failed to process response for ${category}: ${error.message}`,
              };
            }
          })
          .catch((error) => {
            console.error(`Network error for ${category}:`, error);
            return {
              status: 0, // Network error
              category,
              error: `Network error for ${category}: ${error.message}`,
            };
          });
      });

      // Wait for all promises to resolve
      const results = await Promise.all(categoryPromises);
      console.log("All category requests completed:", results);

      // Check if any categories require assessment
      const assessmentRequired = results.find(
        (result) => result.status === 428
      );
      if (assessmentRequired) {
        console.log("Assessment required for:", assessmentRequired);
        setIsLoading(false);
        setCurrentFlowStep("assessment");
        return;
      }

      // Check if all requests failed
      const allFailed = results.every((result) => result.status !== 200);
      if (allFailed) {
        const errorMessages = results
          .map((result) => result.error || `Failed to load ${result.category}`)
          .join("; ");
        throw new Error(`Failed to generate recommendations: ${errorMessages}`);
      }

      // Process successful results
      const newUnits = {};

      results.forEach((result) => {
        if (result.status === 200 && result.response && result.response.data) {
          newUnits[result.category] = result.response.data;
        } else {
          console.warn(
            `Missing or invalid data for ${result.category}:`,
            result
          );
          // Create a basic fallback structure for categories that failed but we still want to show
          newUnits[result.category] = {
            units: [
              {
                id: `${result.category.toUpperCase()}-FALLBACK-1`,
                title: `Introduction to ${result.category}`,
                description: "Basic concepts and fundamentals",
                order: 1,
                content: [],
              },
            ],
          };
        }
      });

      console.log("Processed units:", newUnits);

      // Ensure backend has time to process and save all content
      console.log(
        "Waiting briefly for backend to finish processing content..."
      );
      await new Promise((resolve) => setTimeout(resolve, 2000));

      // Generate projects for each category's first unit
      const projectGenerationPromises = Object.entries(newUnits)
        .map(([category, categoryData]) => {
          if (categoryData.units && categoryData.units.length > 0) {
            const firstUnit = categoryData.units[0];
            console.log(
              `Initiating project generation for ${category}, unit: ${firstUnit.id}`
            );
            return fetch(
              `${process.env.REACT_APP_API_URL}/api/generate_projects_for_unit`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  user_id: requestData.userId,
                  unit_id: firstUnit.id,
                  category: category,
                }),
              }
            )
              .then(async (response) => {
                const responseData = await response.json().catch((e) => ({}));
                if (!response.ok) {
                  console.error(
                    `Project generation API error for ${category}:`,
                    response.status,
                    responseData
                  );
                  throw new Error(
                    `Failed to generate projects for ${category}: ${
                      responseData.error || response.statusText
                    }`
                  );
                }
                console.log(
                  `Successfully generated projects for ${category}, unit: ${firstUnit.id}`,
                  responseData
                );
                return responseData;
              })
              .catch((error) => {
                console.error(
                  `Error generating projects for ${category}:`,
                  error
                );
                // Only return null instead of throwing to prevent one category failure from blocking others
                return null;
              });
          }
          return null;
        })
        .filter(Boolean); // Remove null promises

      // Wait for all project generation to complete
      if (projectGenerationPromises.length > 0) {
        console.log(
          `Generating projects for ${projectGenerationPromises.length} first units...`
        );
        try {
          const results = await Promise.all(projectGenerationPromises);
          console.log("Project generation completed with results:", results);

          // Check if all project generation failed
          const allFailed = results.every((result) => result === null);
          if (allFailed && projectGenerationPromises.length > 0) {
            console.error("All project generation attempts failed");
            // Add a retry mechanism for project generation
            console.log("Retrying project generation after a short delay...");
            await new Promise((resolve) => setTimeout(resolve, 3000));

            // Try one more time for each category
            const retryPromises = Object.entries(newUnits)
              .map(([category, categoryData]) => {
                if (categoryData.units && categoryData.units.length > 0) {
                  const firstUnit = categoryData.units[0];
                  console.log(
                    `RETRY: Generating projects for ${category}, unit: ${firstUnit.id}`
                  );
                  return fetch(
                    `${process.env.REACT_APP_API_URL}/api/generate_projects_for_unit`,
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        user_id: requestData.userId,
                        unit_id: firstUnit.id,
                        category: category,
                      }),
                    }
                  ).catch((e) => null); // Just ignore errors on retry
                }
                return null;
              })
              .filter(Boolean);

            if (retryPromises.length > 0) {
              await Promise.all(retryPromises);
              console.log("Project generation retry completed");
            }
          }
        } catch (error) {
          console.error("Project generation error:", error);
          // Continue with content loading even if project generation fails
          // But log it clearly for troubleshooting
        }
      } else {
        console.warn("No units available for project generation");
      }

      setIsLoading(false);

      // If we have some valid units, proceed even if some categories failed
      if (Object.keys(newUnits).length > 0) {
        console.log("Setting current flow step to viewContent");
        setCurrentFlowStep("viewContent");
        setUnits(newUnits);
        saveUnitsToLocalStorage(newUnits);

        // If there were partial failures, show a warning notification
        const partialFailures = results.filter((r) => r.status !== 200);
        if (partialFailures.length > 0) {
          setError({
            title: "Some categories couldn't be loaded",
            message:
              "We've loaded what we could, but some categories may be incomplete. Try refreshing or coming back later.",
            type: "warning",
          });
        }
      } else {
        throw new Error(
          "No valid content could be generated. Please try again."
        );
      }
    } catch (error) {
      console.error("Error getting recommendations:", error);
      setIsLoading(false);
      setError({
        title: "Error Loading Content",
        message:
          error.message ||
          "Failed to load recommendations. Please try again later.",
        type: "error",
      });
      setCurrentFlowStep("error");
    }
  };

  // Effect to cycle through quotes when loading is active
  useEffect(() => {
    if (!isLoading || shuffledQuotes.length === 0) return;

    let fadeTimeoutId;
    const intervalId = setInterval(() => {
      // Start fade out
      setFadeState("fade-out");

      // Wait for fade out animation to complete, then change quote and fade in
      fadeTimeoutId = setTimeout(() => {
        setCurrentQuoteIndex(
          (prevIndex) => (prevIndex + 1) % shuffledQuotes.length
        );
        setFadeState("fade-in");
      }, 500); // Half a second for fade-out animation
    }, 10000); // Change quote every 10 seconds

    // Cleanup both the interval and any pending timeout
    return () => {
      clearInterval(intervalId);
      if (fadeTimeoutId) clearTimeout(fadeTimeoutId);
    };
  }, [isLoading, shuffledQuotes]);

  // Shuffle quotes on initial load
  useEffect(() => {
    // Fisher-Yates shuffle algorithm
    const quotesCopy = [...LOADING_QUOTES];
    for (let i = quotesCopy.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [quotesCopy[i], quotesCopy[j]] = [quotesCopy[j], quotesCopy[i]];
    }
    // Set the shuffled quotes
    setShuffledQuotes(quotesCopy);
    // Set initial quote index
    setCurrentQuoteIndex(0);
  }, []);

  useEffect(() => {
    if (!user) {
      navigate("/auth");
      return;
    }

    // Function to check for local content and force redirect to dashboard if needed
    const checkForLocalContent = () => {
      // Check if there's actual content in localStorage
      const hasNodes = localStorage.getItem("nodes") && JSON.parse(localStorage.getItem("nodes") || "[]").length > 0;
      const hasEdges = localStorage.getItem("edges") && JSON.parse(localStorage.getItem("edges") || "[]").length > 0;
      const hasUnits = localStorage.getItem("units_data") && Object.keys(JSON.parse(localStorage.getItem("units_data") || "{}")).length > 0;
      const hasCategories = localStorage.getItem("categories") && JSON.parse(localStorage.getItem("categories") || "[]").length > 0;
      
      // If we have any local storage items but they're empty or inconsistent, clear them
      if ((localStorage.getItem("nodes") || localStorage.getItem("edges") || 
           localStorage.getItem("units_data") || localStorage.getItem("categories") || 
           localStorage.getItem("setupComplete")) && 
          (!hasNodes && !hasEdges && !hasUnits && !hasCategories)) {
        console.log("Clearing empty or inconsistent local storage data");
        localStorage.removeItem("nodes");
        localStorage.removeItem("edges");
        localStorage.removeItem("units_data");
        localStorage.removeItem("categories");
        localStorage.removeItem("setupComplete");
        return false;
      }
      
      // Only return true if we have actual content
      return hasNodes && hasEdges && hasUnits && hasCategories;
    };
    
    // If coming from auth, ensure we're starting fresh
    if (location.state?.fromAuth) {
      localStorage.removeItem("setupComplete");
    }

    // Check for actual content in localStorage
    const hasActualContent = checkForLocalContent();
    
    // Only redirect to home if we have actual content AND setupComplete flag is set
    // AND we're not coming from dashboard
    if (hasActualContent && localStorage.getItem("setupComplete") === "true" && !location.state?.fromDashboard) {
      console.log("Actual content found in localStorage, redirecting to dashboard");
      navigate("/home");
      return;
    }
    
    // Function to perform detailed check of user's setup state
    const checkFullExistingData = async () => {
      // Skip if coming directly from auth
      const skipCheck = location.state?.fromAuth;
      if (skipCheck) {
        setIsLoading(false);
        return;
      }

      try {
        console.log("Checking existing data for user:", user.id);
        const submissionResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/check_existing_data?userId=${user.id}&bypass_cache=true`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.id}`,
            },
          }
        );

        if (!submissionResponse.ok) {
          throw new Error(`HTTP error! status: ${submissionResponse.status}`);
        }

        const data = await submissionResponse.json();
        console.log("Existing data:", data);

        if (data.exists) {
          const { submission, hasRecommendations, selectedCategories } = data;

          // Save values to local storage
          if (submission) {
            localStorage.setItem("dreamjob", submission.dreamjob || "");
            localStorage.setItem(
              "biggestPersonalInterest",
              submission.biggest_personal_interest || ""
            );
            localStorage.setItem("age", submission.age || "");

            // Set form values
            setFormData((prevData) => ({
              ...prevData,
              dreamjob: submission.dreamjob || "",
              biggestPersonalInterest: submission.biggest_personal_interest || "",
              age: submission.age || "",
            }));
          }
          
          // If user has recommendations or selected categories, mark setup as complete
          if (hasRecommendations || (selectedCategories && selectedCategories.length > 0)) {
            console.log("User has recommendations or selected categories, marking setup as complete");
            localStorage.setItem("setupComplete", "true");
            navigate("/home");
            return; // Exit early after redirect
          }
          
          // Otherwise, determine which step of the form to show
          if (selectedCategories && selectedCategories.length > 0) {
            // If user has selected categories but no content yet
            setSelectedCategories(selectedCategories);
            setStep(2);
          } else {
            // User has submitted form but not selected categories
            setStep(1);
          }
        }

        // Always set loading to false after check completes
        setIsLoading(false);
      } catch (error) {
        console.error("Error checking existing data:", error);
        setIsLoading(false);
      }
    };

    // Simple check to start the process
    const checkExistingData = async () => {
      setIsLoading(true);
      
      try {
        // Use the existing API endpoint to check if user has content/recommendations
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/check_existing_data?userId=${user.id}&bypass_cache=true`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.id}`,
            },
          }
        );
        
        if (response.ok) {
          const data = await response.json();
          
          // If user has recommendations or categories, they've completed setup
          if (data.hasRecommendations || (data.selectedCategories && data.selectedCategories.length > 0)) {
            localStorage.setItem("setupComplete", "true");
            navigate("/home");
            return;
          }
          
          // Otherwise continue with full data check to determine form state
          checkFullExistingData();
        } else {
          // If the request fails, still try the full check
          checkFullExistingData();
        }
      } catch (error) {
        console.error("Error in quick content check:", error);
        // Continue with regular flow even if this check fails
        checkFullExistingData();
      }
    };
    
    // Start the checking process
    checkExistingData();
  }, [user, navigate, location.state?.fromAuth]);

  // Load recommendations when moving to step 2
  useEffect(() => {
    const loadRecommendations = async () => {
      if (step !== 2 || !user?.id) return;

      setIsLoadingRecommendations(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/recommend_categories`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.id}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data.error) {
          throw new Error(data.error);
        }

        setRecommendations(data);
      } catch (error) {
        console.error("Error loading recommendations:", error);
        setError("Failed to load recommendations. Please try again.");
      } finally {
        setIsLoadingRecommendations(false);
      }
    };

    loadRecommendations();
  }, [step, user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmitStep1 = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    try {
      posthog.capture("form_step1_submitted", formData);
      track("form_step1_submitted", formData);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/submit_form`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: user.id,
            age: formData.age,
            dreamjob: formData.dreamjob,
            biggestPersonalInterest: formData.biggestPersonalInterest,
            feedbackType: formData.feedbackType,
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || `HTTP error! status: ${response.status}`);
      }

      // Move to step 2 after successful submission
      setStep(2);
    } catch (error) {
      console.error("Error submitting form:", error);
      setError("Failed to submit form. Please try again later.");
      posthog.capture("form_step1_error", { error: error.message });
      track("form_step1_error", { error: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCategorySelect = async (categoryOrEvent) => {
    try {
      setIsLoading(true);
      const isFinalSubmit =
        !categoryOrEvent || categoryOrEvent.type === "click";
      const category = isFinalSubmit ? null : categoryOrEvent;

      const newCategories = isFinalSubmit
        ? selectedCategories
        : selectedCategories.includes(category)
        ? selectedCategories.filter((c) => c !== category)
        : [...selectedCategories, category];

      // Save categories using the existing submit_form endpoint instead of the non-existent user/categories endpoint
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/submit_form`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: user.id,
            selected_categories: newCategories, // Use the same field name expected by the backend
            // Include current form data to ensure it's not lost
            age: formData.age,
            dreamjob: formData.dreamjob,
            biggestPersonalInterest: formData.biggestPersonalInterest,
            feedbackType: formData.feedbackType
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save categories");
      }

      // Update local state after successful save
      if (!isFinalSubmit) {
        setSelectedCategories(newCategories);
      }

      // If this is the final submit and we have categories selected, move to assessment
      if (isFinalSubmit && selectedCategories.length > 0) {
        setStep(3);
        setCurrentAssessmentIndex(0);
        setCurrentAssessmentCategory(selectedCategories[0]);
        navigate("/form"); // Ensure navigation back to form
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Failed to save categories");
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerateAssessment = async () => {
    try {
      setIsLoading(true);
      setError("");

      // Keep track of retry attempts
      let currentAttempt = 0;
      const maxRetries = 3;

      // Define sleep function for retry backoff
      const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

      // Extract the retry logic to a separate function to avoid declaring functions in loops
      const attemptWithRetry = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/generate_category_assessment`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                userId: user.id,
                category: currentAssessmentCategory,
                age: formData.age,
                curriculumDescription: curriculumDescription
              }),
            }
          );

          if (!response.ok) {
            const errorData = await response.json();
            // If it's an overload error, retry
            if (response.status === 529) {
              throw new Error("Server overloaded");
            }
            throw new Error(errorData.error || "Failed to generate assessment");
          }

          const data = await response.json();

          if (
            !data.data ||
            !data.data.multiple_choice ||
            !data.data.short_answer
          ) {
            throw new Error("Invalid assessment data received");
          }

          setAssessment(data.data);
          setAssessmentAnswers({});
          setShortAnswer("");
          return true; // Success
        } catch (error) {
          if (
            currentAttempt < maxRetries - 1 && 
            (error.message === "Server overloaded" || error.message.includes("Failed to fetch"))
          ) {
            currentAttempt++;
            // Wait with exponential backoff
            await sleep(Math.pow(2, currentAttempt) * 1000);
            return false; // Continue trying
          }
          
          // Out of retries or non-retriable error
          console.error("Error generating assessment:", error);
          setError(
            error.message || "Failed to generate assessment. Please try again."
          );
          // Reset assessment state on error
          setAssessment(null);
          setAssessmentAnswers({});
          setShortAnswer("");
          return true; // Stop trying, we're done
        }
      };

      // Main retry loop
      while (currentAttempt < maxRetries) {
        const isDone = await attemptWithRetry();
        if (isDone) break;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitAssessment = async () => {
    try {
      setIsLoading(true);
      setError(null);

      // Validate current assessment
      if (!assessment || !currentAssessmentCategory) {
        throw new Error("No active assessment to submit");
      }
      
      // Keep track of retry attempts
      let currentAttempt = 0;
      const maxRetries = 3;
      
      // Define sleep function for retry backoff
      const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      
      // Extract the retry logic to avoid function declarations in loops
      const attemptSubmitWithRetry = async () => {
        try {
          // First submit the current assessment
          const assessmentResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/submit_category_assessment`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                userId: user.id,
                category: currentAssessmentCategory,
                assessmentResults: {
                  multiple_choice: assessment.multiple_choice.map((q, i) => ({
                    question: q.question,
                    answer: assessmentAnswers[i],
                    correct: q.correct_answer === assessmentAnswers[i],
                  })),
                  short_answer: {
                    question: assessment.short_answer.question,
                    answer: shortAnswer,
                  },
                },
              }),
            }
          );

          if (!assessmentResponse.ok) {
            const errorData = await assessmentResponse.json();
            if (assessmentResponse.status === 529) {
              throw new Error("Server overloaded");
            }
            throw new Error(errorData.error || "Failed to submit assessment");
          }

          // Move to the next category in the list or finish the assessment process
          const currentIndex = selectedCategories.indexOf(currentAssessmentCategory);
          if (currentIndex < selectedCategories.length - 1) {
            // Move to the next category
            setCurrentAssessmentCategory(selectedCategories[currentIndex + 1]);
            setCurrentAssessmentIndex(currentIndex + 1);
            setCurriculumDescription("");
            setAssessment(null);
          } else {
            // All categories assessed, proceed to content generation
            getStartRecommendations({
              userId: user.id,
              age: formData.age,
              dreamjob: formData.dreamjob,
              biggestPersonalInterest: formData.biggestPersonalInterest,
              categories: selectedCategories
            });
          }
          
          return true; // Success
        } catch (error) {
          if (
            currentAttempt < maxRetries - 1 && 
            (error.message === "Server overloaded" || error.message.includes("Failed to fetch"))
          ) {
            currentAttempt++;
            // Wait with exponential backoff
            await sleep(Math.pow(2, currentAttempt) * 1000);
            return false; // Continue trying
          }
          
          // Out of retries or non-retriable error
          console.error("Error submitting assessment:", error);
          setError(
            error.message || "Failed to submit assessment. Please try again."
          );
          return true; // Stop trying, we're done
        }
      };
      
      // Main retry loop
      while (currentAttempt < maxRetries) {
        const isDone = await attemptSubmitWithRetry();
        if (isDone) break;
      }
    } catch (error) {
      console.error("Error in assessment flow:", error);
      setError(
        error.message || "An error occurred during assessment submission."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const renderFeedbackTypeSelector = () => (
    <div className="mb-6">
      <label className="block text-sm font-medium text-gray-300 mb-2">
        Feedback Preference
      </label>
      <div className="flex gap-4">
        <label className="flex items-center px-4 py-3 bg-gray-900/70 rounded-lg border border-gray-700 cursor-pointer hover:border-indigo-500/50 transition-colors w-1/2">
          <input
            type="radio"
            name="feedbackType"
            value="feedback_only"
            checked={formData.feedbackType === "feedback_only"}
            onChange={handleChange}
            className="mr-2 text-indigo-500 focus:ring-indigo-500"
          />
          <span className="text-gray-300">Feedback Only</span>
        </label>
        <label className="flex items-center px-4 py-3 bg-gray-900/70 rounded-lg border border-gray-700 cursor-pointer hover:border-indigo-500/50 transition-colors w-1/2">
          <input
            type="radio"
            name="feedbackType"
            value="feedback_and_grades"
            checked={formData.feedbackType === "feedback_and_grades"}
            onChange={handleChange}
            className="mr-2 text-indigo-500 focus:ring-indigo-500"
          />
          <span className="text-gray-300">Feedback + Grades</span>
        </label>
      </div>
      <p className="mt-1 text-sm text-gray-400">
        Choose how you'd like to receive feedback on your submissions
      </p>
    </div>
  );

  const renderAssessmentStep = () => {
    if (!currentAssessmentCategory) return null;

    return (
      <>
        <h2 className="text-3xl font-bold mb-6 text-center text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-indigo-400">
          Previous Curriculum Assessment
        </h2>
        <p className="text-gray-300 mb-8 text-center">
          Help us understand your knowledge level in{" "}
          <span className="text-indigo-400 font-medium capitalize">{currentAssessmentCategory}</span>
        </p>

        {!assessment ? (
          <div className="space-y-6 bg-gray-900/50 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50">
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                What curriculum or learning experience have you had in{" "}
                <span className="text-indigo-400 capitalize">{currentAssessmentCategory}</span>?
              </label>
              <textarea
                value={curriculumDescription}
                onChange={(e) => setCurriculumDescription(e.target.value)}
                className="w-full px-4 py-3 bg-gray-900/80 border border-gray-700 rounded-lg text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300 shadow-inner shadow-indigo-900/20"
                placeholder="Briefly describe your previous learning experience..."
                rows={3}
              />
            </div>
            <button
              onClick={handleGenerateAssessment}
              disabled={!curriculumDescription || isLoading}
              className="w-full px-6 py-4 bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white font-bold rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-300 hover:scale-[1.02] hover:shadow-lg flex items-center justify-center"
            >
              {isLoading ? (
                <div className="flex items-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Generating Assessment...
                </div>
              ) : (
                <span className="inline-flex items-center">
                  <span className="mr-2">📝</span> Start Assessment
                </span>
              )}
            </button>
          </div>
        ) : (
          <div className="space-y-8">
            <div className="space-y-6">
              <h3 className="text-xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-indigo-400 mb-4">
                Multiple Choice Questions
              </h3>
              {assessment.multiple_choice.map((question, index) => (
                <div key={index} className="bg-gray-900/50 backdrop-blur-sm p-5 rounded-lg border border-gray-800/50">
                  <p className="text-white mb-4">{question.question}</p>
                  <div className="space-y-3">
                    {question.options.map((option, optionIndex) => (
                      <label
                        key={optionIndex}
                        className={`flex items-center space-x-3 p-3 rounded-lg transition-all cursor-pointer hover:bg-gray-800/50 ${
                          assessmentAnswers[index] === optionIndex ? 'bg-indigo-900/30 border border-indigo-500/50' : 'border border-transparent'
                        }`}
                      >
                        <input
                          type="radio"
                          name={`question-${index}`}
                          checked={assessmentAnswers[index] === optionIndex}
                          onChange={() =>
                            setAssessmentAnswers({
                              ...assessmentAnswers,
                              [index]: optionIndex,
                            })
                          }
                          className="text-indigo-500 focus:ring-indigo-500"
                        />
                        <span className={`text-gray-300 ${assessmentAnswers[index] === optionIndex ? 'text-white' : ''}`}>{option}</span>
                      </label>
                    ))}
                  </div>
                </div>
              ))}
            </div>

            <div className="space-y-4">
              <h3 className="text-xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-indigo-400">
                Short Answer Question
              </h3>
              <div className="bg-gray-900/50 backdrop-blur-sm p-5 rounded-lg border border-gray-800/50">
                <p className="text-white mb-4">
                  {assessment.short_answer.question}
                </p>
                <textarea
                  value={shortAnswer}
                  onChange={(e) => setShortAnswer(e.target.value)}
                  className="w-full px-4 py-3 bg-gray-900/80 border border-gray-700 rounded-lg text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300 shadow-inner shadow-indigo-900/20"
                  placeholder="Write your answer here..."
                  rows={6}
                />
              </div>
            </div>

            <div className="flex justify-end">
              <button
                onClick={handleSubmitAssessment}
                disabled={
                  isLoading ||
                  Object.keys(assessmentAnswers).length <
                    assessment.multiple_choice.length ||
                  !shortAnswer
                }
                className="px-8 py-4 bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white font-bold rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-300 hover:scale-[1.02] hover:shadow-lg flex items-center"
              >
                {isLoading ? (
                  <div className="flex items-center">
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Submitting...
                  </div>
                ) : (
                  <span className="inline-flex items-center">
                    <span className="mr-2">✓</span> Submit Assessment
                  </span>
                )}
              </button>
            </div>
          </div>
        )}
      </>
    );
  };

  const renderError = () => {
    if (!error) return null;

    return (
      <div className="bg-red-900/50 backdrop-blur-sm border border-red-500/50 text-white px-6 py-4 rounded-lg relative mb-6 shadow-lg">
        <div className="flex items-start">
          <div className="flex-shrink-0 mt-0.5">
            <svg className="h-5 w-5 text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
          </div>
          <div className="ml-3 flex-1">
            <h3 className="text-lg font-medium text-red-300">
              {typeof error === "string" ? "Error" : error.title || "Error"}
            </h3>
            <div className="mt-2 text-sm text-red-100">
              <p>{typeof error === "string" ? error : error.message}</p>
            </div>
            {error.canRetry && (
              <div className="mt-4">
                <button
                  onClick={() => getStartRecommendations(error.retryState)}
                  className="inline-flex items-center px-4 py-2 bg-red-600 hover:bg-red-700 text-white font-medium rounded-md transition-colors duration-200"
                >
                  <svg className="mr-2 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                  </svg>
                  Try Again
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    const currentQuote =
      shuffledQuotes.length > 0 ? shuffledQuotes[currentQuoteIndex] : null;

    return (
      <div className="min-h-screen bg-gray-950 text-white relative overflow-hidden flex items-center justify-center">
        {/* Background elements */}
        <div className="absolute inset-0 overflow-hidden">
          {/* Subtle grid pattern */}
          <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:14px_24px]"></div>
          
          {/* Radial gradient background */}
          <div className="absolute left-[50%] top-[50%] -z-10 h-[1000px] w-[1000px] -translate-x-[50%] -translate-y-[50%] bg-radial-gradient"></div>
          
          {/* Accent colors */}
          <div className="absolute -top-40 right-0 h-[500px] w-[500px] rounded-full bg-purple-500/20 blur-[100px] -z-10"></div>
          <div className="absolute -bottom-40 left-0 h-[500px] w-[500px] rounded-full bg-indigo-500/20 blur-[100px] -z-10"></div>
        </div>

        <div className="flex flex-col items-center text-center max-w-xl px-4 z-10">
          <div className="w-20 h-20 mb-8 relative">
            <div className="absolute inset-0 rounded-full border-t-2 border-b-2 border-indigo-500 animate-spin"></div>
            <div className="absolute inset-2 rounded-full border-r-2 border-l-2 border-purple-500 animate-spin animate-pulse"></div>
            <div className="absolute inset-4 rounded-full border-t-2 border-b-2 border-blue-500 animate-spin animation-delay-300"></div>
          </div>
          <p className="text-gray-300 text-xl mb-10 font-medium text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-indigo-400">
            Loading your personalized content...
          </p>
          {currentQuote && (
            <div
              className={`transition-opacity duration-500 rounded-xl bg-neutral-900/70 backdrop-blur-sm p-8 border border-neutral-800/60 shadow-xl ${
                fadeState === "fade-out" ? "opacity-0" : "opacity-100"
              }`}
            >
              <p className="text-white text-lg italic mb-4 leading-relaxed">
                "{currentQuote.text}"
              </p>
              <p className="text-indigo-300 text-sm">
                — {currentQuote.author}
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (error && currentFlowStep === "error") {
    return (
      <div className="min-h-screen bg-gray-950 text-white relative overflow-hidden flex items-center justify-center">
        {/* Background elements */}
        <div className="absolute inset-0 overflow-hidden">
          {/* Subtle grid pattern */}
          <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:14px_24px]"></div>
          
          {/* Radial gradient background */}
          <div className="absolute left-[50%] top-[50%] -z-10 h-[1000px] w-[1000px] -translate-x-[50%] -translate-y-[50%] bg-radial-gradient"></div>
          
          {/* Accent colors */}
          <div className="absolute -top-40 right-0 h-[500px] w-[500px] rounded-full bg-red-500/20 blur-[100px] -z-10"></div>
          <div className="absolute -bottom-40 left-0 h-[500px] w-[500px] rounded-full bg-pink-500/20 blur-[100px] -z-10"></div>
        </div>

        <div className="w-full max-w-2xl p-4 z-10">
          <div className="rounded-xl overflow-hidden border border-red-800/30 bg-neutral-900/70 backdrop-blur-lg shadow-xl p-8">
            <div className="text-center mb-6">
              <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-red-500/20 mb-4">
                <svg className="w-8 h-8 text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
              </div>
              <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-red-400 to-pink-400">
                {typeof error === "string" ? "Error Occurred" : error.title || "Error Occurred"}
              </h2>
            </div>

            <div className="bg-red-900/20 backdrop-blur-sm border border-red-500/30 text-white px-6 py-4 rounded-lg relative mb-6">
              <p className="text-red-100">{typeof error === "string" ? error : error.message}</p>
            </div>

            {error.canRetry && (
              <div className="flex justify-center mt-6">
                <button
                  onClick={() => getStartRecommendations(error.retryState)}
                  className="px-6 py-3 bg-gradient-to-r from-red-600 to-pink-600 hover:from-red-700 hover:to-pink-700 text-white font-bold rounded-lg transition-all duration-300 hover:scale-[1.02] hover:shadow-lg flex items-center"
                >
                  <svg className="mr-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                  </svg>
                  Try Again
                </button>
              </div>
            )}
            
            <div className="mt-8 pt-4 border-t border-neutral-800">
              <button 
                onClick={() => navigate("/")}
                className="w-full py-3 text-gray-400 hover:text-white transition-colors"
              >
                Return to Homepage
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Header />
      <div className="min-h-screen bg-gray-950 text-white relative overflow-hidden">
        {/* Background elements similar to Auth.js */}
        <div className="absolute inset-0 overflow-hidden">
          {/* Subtle grid pattern */}
          <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:14px_24px]"></div>
          
          {/* Radial gradient background */}
          <div className="absolute left-[50%] top-[50%] -z-10 h-[1000px] w-[1000px] -translate-x-[50%] -translate-y-[50%] bg-radial-gradient"></div>
          
          {/* Accent colors */}
          <div className="absolute -top-40 right-0 h-[500px] w-[500px] rounded-full bg-purple-500/20 blur-[100px] -z-10"></div>
          <div className="absolute -bottom-40 left-0 h-[500px] w-[500px] rounded-full bg-indigo-500/20 blur-[100px] -z-10"></div>
        </div>

        <style jsx="true">{`
          .bg-radial-gradient {
            background: radial-gradient(circle at center, rgba(124, 58, 237, 0.05) 0%, rgba(49, 46, 129, 0.02) 25%, rgba(0, 0, 0, 0) 80%);
          }
        `}</style>

        <div className="relative flex items-center justify-center p-4 py-16 z-10">
          <div className="w-full max-w-2xl rounded-xl overflow-hidden border border-neutral-800 bg-neutral-900/50 backdrop-blur-lg shadow-xl p-8">
            <h2 className="text-4xl font-bold mb-6 text-center text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-indigo-400">
              Heretic.School
            </h2>

            {error && renderError()}

            {currentFlowStep === "loading" ? (
              <div className="flex flex-col items-center text-center">
                <div className="w-16 h-16 mb-8 relative">
                  <div className="absolute inset-0 rounded-full border-t-2 border-b-2 border-indigo-500 animate-spin"></div>
                  <div className="absolute inset-1 rounded-full border-r-2 border-l-2 border-purple-500 animate-spin animate-pulse"></div>
                </div>
                <p className="text-gray-300 text-lg mb-6">
                  Loading your personalized content...
                </p>
                {shuffledQuotes.length > 0 && (
                  <div className={`transition-opacity duration-500 bg-neutral-900/70 backdrop-blur-sm p-6 rounded-lg border border-neutral-800 max-w-lg ${
                    fadeState === "fade-out" ? "opacity-0" : "opacity-100"
                  }`}>
                    <p className="text-white text-lg italic mb-3 leading-relaxed">
                      "{shuffledQuotes[currentQuoteIndex].text}"
                    </p>
                    <p className="text-indigo-300 text-sm">
                      — {shuffledQuotes[currentQuoteIndex].author}
                    </p>
                  </div>
                )}
              </div>
            ) : currentFlowStep === "assessment" ? (
              renderAssessmentStep()
            ) : currentFlowStep === "viewContent" ? (
              <div className="text-center space-y-6">
                <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-green-500/20 mb-4">
                  <svg className="w-8 h-8 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <h3 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-teal-400 mb-4">
                  Content Generated Successfully!
                </h3>
                <p className="text-gray-300 mb-6 max-w-lg mx-auto">
                  Your personalized curriculum has been created. You can now
                  explore your learning journey tailored to your interests and goals.
                </p>
                <button
                  onClick={() => navigate("/home")}
                  className="px-8 py-4 bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white font-bold rounded-lg transition-all duration-300 hover:scale-[1.02] hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
                >
                  <span className="inline-flex items-center">
                    <span className="mr-2">📊</span> Go to Learning Dashboard
                  </span>
                </button>
              </div>
            ) : (
              <>
                {step === 1 ? (
                  <>
                    <p className="text-gray-300 mb-8 text-center">
                      Please provide detailed information to help us tailor your
                      learning experience. The more specific you are, the better
                      we can customize your journey.
                    </p>
                    <form onSubmit={handleSubmitStep1} className="space-y-6">
                      <div>
                        <label
                          htmlFor="age"
                          className="block text-sm font-medium text-gray-300 mb-1"
                        >
                          Age
                        </label>
                        <input
                          type="number"
                          step="1"
                          id="age"
                          name="age"
                          value={formData.age}
                          onChange={handleChange}
                          placeholder="Enter your age - only whole numbers are accepted"
                          required
                          className="w-full px-4 py-3 bg-gray-900/80 border border-gray-700 rounded-lg text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300 shadow-inner shadow-indigo-900/20"
                        />
                        <p className="mt-1 text-sm text-gray-400">
                          Your age helps us provide age-appropriate content and
                          recommendations.
                        </p>
                      </div>
                      <div>
                        <label
                          htmlFor="dreamjob"
                          className="block text-sm font-medium text-gray-300 mb-1"
                        >
                          Student's Dream Job
                        </label>
                        <textarea
                          id="dreamjob"
                          name="dreamjob"
                          value={formData.dreamjob}
                          onChange={handleChange}
                          placeholder="Startup founder in the climate tech space, focusing on developing innovative carbon capture technologies. I'm passionate about combining entrepreneurship with environmental impact to address the urgent challenge of climate change."
                          required
                          rows={4}
                          className="w-full px-4 py-3 bg-gray-900/80 border border-gray-700 rounded-lg text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300 shadow-inner shadow-indigo-900/20"
                        />
                        <p className="mt-1 text-sm text-gray-400">
                          Understanding your career aspirations helps us tailor
                          content to support your professional goals.
                        </p>
                      </div>
                      <div>
                        <label
                          htmlFor="biggestPersonalInterest"
                          className="block text-sm font-medium text-gray-300 mb-1"
                        >
                          Student's Biggest Personal Interest
                        </label>
                        <textarea
                          id="biggestPersonalInterest"
                          name="biggestPersonalInterest"
                          value={formData.biggestPersonalInterest}
                          onChange={handleChange}
                          placeholder="The logistics of the Eastern Front in World War II, particularly how supply chains and resource management impacted military strategies. I'm fascinated by how logistical challenges shaped the outcome of major battles and the war as a whole."
                          required
                          rows={4}
                          className="w-full px-4 py-3 bg-gray-900/80 border border-gray-700 rounded-lg text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300 shadow-inner shadow-indigo-900/20"
                        />
                        <p className="mt-1 text-sm text-gray-400">
                          The things that excite your brain are just as important
                          as your dream job - can be connected to your dream job,
                          but doesn't have to be.
                        </p>
                      </div>
                      {renderFeedbackTypeSelector()}
                      <button
                        type="submit"
                        disabled={isLoading}
                        className="w-full px-6 py-4 bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white font-bold rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-300 hover:scale-[1.02] hover:shadow-lg flex items-center justify-center"
                      >
                        {isLoading ? (
                          <>
                            <svg
                              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                            Processing...
                          </>
                        ) : (
                          <span className="inline-flex items-center">
                            <span className="mr-2">✨</span> Next Step
                          </span>
                        )}
                      </button>
                    </form>
                  </>
                ) : step === 2 ? (
                  <>
                    <h2 className="text-3xl font-bold mb-6 text-center text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-indigo-400">
                      Select Your Learning Categories
                    </h2>
                    <p className="text-gray-300 mb-8 text-center text-lg">
                      Choose up to 3 categories that interest you the most
                      {selectedCategories.length > 0 && (
                        <span className="ml-2 text-indigo-400 font-semibold">
                          ({selectedCategories.length}/3 selected)
                        </span>
                      )}
                    </p>

                    {/* Recommendations Section */}
                    <div className="mb-12 bg-gray-900/50 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50">
                      <h3 className="text-2xl font-semibold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-purple-300 to-indigo-300">
                        Recommended Categories
                      </h3>
                      {isLoadingRecommendations ? (
                        <div className="flex justify-center items-center py-8">
                          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-400"></div>
                        </div>
                      ) : recommendations && recommendations.recommendations ? (
                        <>
                          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
                            {recommendations.recommendations.map((rec) => (
                              <div
                                key={rec.category}
                                className="flex flex-col space-y-4"
                              >
                                <button
                                  onClick={() => {
                                    if (
                                      selectedCategories.includes(rec.category)
                                    ) {
                                      setSelectedCategories(
                                        selectedCategories.filter(
                                          (c) => c !== rec.category
                                        )
                                      );
                                    } else if (selectedCategories.length < 3) {
                                      setSelectedCategories([
                                        ...selectedCategories,
                                        rec.category,
                                      ]);
                                    }
                                  }}
                                  disabled={
                                    selectedCategories.length >= 3 &&
                                    !selectedCategories.includes(rec.category)
                                  }
                                  className={`
                                    p-4 rounded-lg text-lg font-medium transition-all duration-200 transform hover:scale-105
                                    ${
                                      selectedCategories.includes(rec.category)
                                        ? "bg-gradient-to-r from-purple-600 to-indigo-600 text-white shadow-lg scale-105"
                                        : selectedCategories.length >= 3
                                        ? "bg-gray-800/70 text-gray-400 cursor-not-allowed opacity-50"
                                        : "bg-gray-800/70 text-gray-300 hover:bg-gray-700/70 hover:text-white"
                                    }
                                    border-2 
                                    ${
                                      selectedCategories.includes(rec.category)
                                        ? "border-indigo-400"
                                        : "border-transparent"
                                    }
                                    flex items-center justify-center text-center
                                    hover:shadow-lg shadow-indigo-500/10
                                  `}
                                >
                                  <span className="capitalize">
                                    {rec.category}
                                  </span>
                                  {selectedCategories.includes(rec.category) && (
                                    <svg
                                      className="w-5 h-5 ml-2 text-white"
                                      fill="none"
                                      stroke="currentColor"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M5 13l4 4L19 7"
                                      />
                                    </svg>
                                  )}
                                </button>
                                <div className="bg-gray-800/40 rounded-lg p-4 text-sm border border-gray-700/50 backdrop-blur-sm">
                                  <div className="mb-3">
                                    <h4 className="font-semibold text-indigo-300 mb-1 flex items-center">
                                      {rec.based_on === "dream_job" ? (
                                        <>
                                          <svg
                                            className="w-4 h-4 mr-1"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth={2}
                                              d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                            />
                                          </svg>
                                          Career Goal
                                        </>
                                      ) : (
                                        <>
                                          <svg
                                            className="w-4 h-4 mr-1"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth={2}
                                              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                                            />
                                          </svg>
                                          Personal Interest
                                        </>
                                      )}
                                    </h4>
                                    <p className="text-gray-300">
                                      {rec.relevance}
                                    </p>
                                  </div>
                                  <div>
                                    <h4 className="font-semibold text-indigo-300 mb-1">
                                      Key Benefits
                                    </h4>
                                    <ul className="list-disc list-inside text-gray-300 space-y-1">
                                      {rec.key_benefits.map((benefit, index) => (
                                        <li key={index}>{benefit}</li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <p className="text-gray-300 text-center py-4">
                          No personalized recommendations available.
                        </p>
                      )}
                    </div>

                    {/* All Categories Section - Always show this section */}
                    <div className="border-t border-gray-700/50 pt-8">
                      <h3 className="text-xl font-semibold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-purple-300 to-indigo-300">
                        All Available Categories
                      </h3>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
                        {CATEGORIES.map((category) => (
                          <button
                            key={category}
                            onClick={() => {
                              if (selectedCategories.includes(category)) {
                                setSelectedCategories(
                                  selectedCategories.filter((c) => c !== category)
                                );
                              } else if (selectedCategories.length < 3) {
                                setSelectedCategories([
                                  ...selectedCategories,
                                  category,
                                ]);
                              }
                            }}
                            disabled={
                              selectedCategories.length >= 3 &&
                              !selectedCategories.includes(category)
                            }
                            className={`
                              p-4 rounded-lg text-base font-medium transition-all duration-200 transform hover:scale-105
                              ${
                                selectedCategories.includes(category)
                                  ? "bg-gradient-to-r from-purple-600 to-indigo-600 text-white shadow-lg scale-105"
                                  : selectedCategories.length >= 3
                                  ? "bg-gray-800/50 text-gray-400 cursor-not-allowed opacity-50"
                                  : "bg-gray-800/50 text-gray-300 hover:bg-gray-700/70 hover:text-white"
                              }
                              border-2 
                              ${
                                selectedCategories.includes(category)
                                  ? "border-indigo-400"
                                  : "border-transparent"
                              }
                              flex items-center justify-center text-center
                              hover:shadow-lg
                            `}
                          >
                            <span className="capitalize">{category}</span>
                            {selectedCategories.includes(category) && (
                              <svg
                                className="w-5 h-5 ml-2 text-white"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            )}
                          </button>
                        ))}
                      </div>
                    </div>

                    {/* Navigation buttons */}
                    <div className="flex justify-center space-x-4 mt-8">
                      <button
                        onClick={() => setStep(1)}
                        className="px-8 py-4 bg-gray-800 text-gray-300 font-bold rounded-lg hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 transition-all duration-200 border border-gray-700 hover:border-gray-600"
                      >
                        Back
                      </button>
                      <div className="flex flex-col space-y-2">
                        <button
                          onClick={() => handleCategorySelect()}
                          disabled={selectedCategories.length === 0 || isLoading}
                          className="px-8 py-4 bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white font-bold rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-300 hover:scale-[1.02] hover:shadow-lg"
                        >
                          {isLoading ? (
                            <div className="flex items-center">
                              <svg
                                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                              Processing...
                            </div>
                          ) : (
                            <span className="inline-flex items-center">
                              <span className="mr-2">✨</span> Continue with Full Onboarding
                            </span>
                          )}
                        </button>
                        <button
                          onClick={() =>
                            getStartRecommendations({
                              userId: user.id,
                              age: formData.age,
                              dreamjob: formData.dreamjob,
                              biggestPersonalInterest:
                                formData.biggestPersonalInterest,
                              categories: selectedCategories,
                              skipOnboarding: true,
                            })
                          }
                          disabled={selectedCategories.length === 0 || isLoading}
                          className="px-8 py-4 bg-amber-500 text-gray-900 font-bold rounded-lg hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-amber-500 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-300 hover:scale-[1.02] hover:shadow-lg"
                        >
                          {isLoading ? (
                            <div className="flex items-center">
                              <svg
                                className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-900"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                              Generating Content...
                            </div>
                          ) : (
                            <span className="inline-flex items-center">
                              <span className="mr-2">🚀</span> Skip Onboarding (Not Recommended)
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                    <p className="text-center text-gray-400 mt-6 text-sm border-t border-gray-800/50 pt-6">
                      <a
                        href="https://chromewebstore.google.com/detail/hereticschool-chrome-exte/odlfeaecpimnkgmeigjokbhemhbppage"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-indigo-400 hover:text-indigo-300 transition-colors flex items-center justify-center gap-2 group"
                      >
                        <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                          <path d="M12 0C8.21 0 4.831 1.757 2.632 4.501l3.953 6.848A5.454 5.454 0 0 1 12 6.545c0-1.473.584-2.806 1.53-3.783L12 0zm4.472 5.422C15.389 6.484 14.292 7.179 13 7.336v9.327c1.292.158 2.389.852 3.472 1.914L19.967 12 16.472 5.422zm-11.05-.79l-3.957 6.853A9.98 9.98 0 0 0 2 12c0 5.523 4.477 10 10 10 2.188 0 4.22-.701 5.864-1.894L13.92 13.37a5.444 5.444 0 0 1-3.446-5.05L5.422 4.632z" />
                        </svg>
                        Please install the Chrome extension - it is necessary for the app to work
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="w-4 h-4 transition-transform group-hover:translate-x-0.5"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </p>
                  </>
                ) : (
                  step === 3 && renderAssessmentStep()
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
